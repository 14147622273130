import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUEST_CONFIRM = gql`
mutation memberLicenseCardRequestConfirm($id: ID!) {
	memberLicenseCardRequestConfirm (id: $id) {
		id
    bid
  }
}
`;

export default MEMBER_LICENSE_CARD_REQUEST_CONFIRM;