import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import paths from "paths";
import { generatePath } from "react-router-dom";
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { colors } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import MemberClubCard from './TransferCards/MemberClubCard';
import { getValidationSchemaTransfer, getValidationSchemaHosting } from "./validationSchema";
import ApprovedCard from './TransferCards/ApprovedCard';
import PaymentCard from '../Payments/PaymentCards/PaymentCard';
import MemberDataCard from './TransferCards/MemberDataCard';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import TRANSFER_AND_HOSTING_DETAIL from "queries/TransferAndHostingQueries/transferAndHostingDetail";
import CLUB_TRANSFER_REQUEST_APPROVE from "queries/TransferAndHostingQueries/clubTransferRequestApprove";
import CLUB_TRANSFER_REQUEST_REJECT from "queries/TransferAndHostingQueries/clubTransferRequestReject";
import CLUB_TRANSFER_REQUEST_CANCEL from "queries/TransferAndHostingQueries/clubTransferRequestCancel";
import CLUB_HOSTING_REQUEST_APPROVE from "queries/TransferAndHostingQueries/clubHostingRequestApprove";
import CLUB_HOSTING_REQUEST_REJECT from "queries/TransferAndHostingQueries/clubHostingRequestReject";
import CLUB_HOSTING_REQUEST_CANCEL from "queries/TransferAndHostingQueries/clubHostingRequestCancel";
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import { getApolloClient } from "configFiles/apollo";
import { connect } from 'react-redux';
import Modal from "components-lib/Modal/Modal";
import moment from "moment";
import { changePendingTransfersAndHostingNumberAction } from 'redux/actions';
import TransfersAndHostingStatusesEnum from 'common/enums/TransfersAndHostingStatusesEnum';
import RoleEnum from 'common/enums/RoleEnum'
import PROFILE from 'queries/ProfileQueries/profile';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

import { useLocation } from 'react-router-dom';

import '../../assets/css/transfers.css';

const useStyles = makeStyles((theme) => styles(theme))

const TransferDetailPage = (props) => {
    const { intl, history, role } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { data: userProfile } = useQuery(PROFILE);
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const requestId = (props?.match?.params?.requestId) ? (props?.match?.params?.requestId) : undefined
    let type = (window.location.pathname).includes('hosting') ? 'hosting' : 'transfer'
    const location = useLocation();

    const { loading: loadingRequest, data: dataRequest, refetch: refetchRequest } = useQuery(TRANSFER_AND_HOSTING_DETAIL, { variables: { id: requestId } });
    const [clubTransferRequestApprove] = useMutation(CLUB_TRANSFER_REQUEST_APPROVE)
    const [clubTransferRequestReject] = useMutation(CLUB_TRANSFER_REQUEST_REJECT)
    const [clubTransferRequestCancel] = useMutation(CLUB_TRANSFER_REQUEST_CANCEL)
    const [clubHostingRequestApprove] = useMutation(CLUB_HOSTING_REQUEST_APPROVE)
    const [clubHostingRequestReject] = useMutation(CLUB_HOSTING_REQUEST_REJECT)
    const [clubHostingRequestCancel] = useMutation(CLUB_HOSTING_REQUEST_CANCEL)

    const [validateParam, setValidateParam] = useState(false)
    const [requestData, setRequestData] = useState(false)
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [clubMemberFunctionsData, setClubMemberFunctionsData] = useState([]);
    const [clubMemberDisciplinesData, setClubMemberDisciplinesData] = useState([]);
    const [clubMemberSourceClubData, setClubMemberSourceClubData] = useState(null);
    const [clubMemberTargetClubData, setClubMemberTargetClubData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setRequestData(pathOr('', ['request', 'data'], dataRequest));
        let tempMemberFunctions = Object.values(pathOr('', ['request', 'data', 'member', 'functions'], dataRequest))?.map((item) => {
            return {
                value: item?.function?.id,
                label: item?.function?.name,
            }
        });
        setClubMemberFunctionsData(tempMemberFunctions);

        let tempMemberDisciplines = [];
        let disc = Object.values(pathOr('', ['request', 'data', 'member', 'functions'], dataRequest))?.find(item => item?.function?.id === '20' || item?.function?.id === '21')?.disciplines
        tempMemberDisciplines = disc?.map(item => {
            return {
                value: item?.id,
                label: item?.name,
            }
        })
        setClubMemberDisciplinesData(tempMemberDisciplines);

        setClubMemberSourceClubData(pathOr('', ['request', 'data', 'sourceClub'], dataRequest))
        setClubMemberTargetClubData(pathOr('', ['request', 'data', 'targetClub'], dataRequest))

        setRequestStatus(getRequestStatus(
            pathOr('', ['request', 'status'], dataRequest),
            pathOr('', ['request', 'data', 'payment', 'status'], dataRequest),
            pathOr('', ['request', 'data', 'memberResponse', 'result'], dataRequest),
            pathOr('', ['request', 'data', 'sourceClubResponse', 'result'], dataRequest),
            pathOr('', ['request', 'data', 'targetClubResponse', 'result'], dataRequest),
        ))

    }, [dataRequest]);

    const getPendingRequestsNumber = async () => {
        try {
            const result = await client.query({
                query: REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "in": [TransfersAndHostingStatusesEnum.AWAITING, TransfersAndHostingStatusesEnum.PAID]
                            },
                            "type": {
                                "in": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changePendingTransfersAndHostingNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
        } catch (error) {
            throw error;
        }
    }

    const handleRequestApprove = (values) => {
        setDisableButton(true);
        if (type === 'transfer') {
            clubTransferRequestApprove({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                    input: {
                        from: values?.from
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'transfers.snackbar.approve.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        } else {
            clubHostingRequestApprove({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                    input: {
                        from: values?.from,
                        to: values?.to
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'hosting.snackbar.approve.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
    }

    const handleRequestReject = () => {
        setDisableButton(true);
        if (type === 'transfer') {
            clubTransferRequestReject({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'transfers.snackbar.reject.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        } else {
            clubHostingRequestReject({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'hosting.snackbar.reject.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
        setRejectModalOpen(false)
    }

    const handleRequestCancel = () => {
        setDisableButton(true);
        if (type === 'transfer') {
            clubTransferRequestCancel({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'transfers.snackbar.cancel.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        } else {
            clubHostingRequestCancel({
                variables: {
                    id: pathOr('', ['request', 'id'], dataRequest),
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'hosting.snackbar.cancel.success' }), { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
        setCancelModalOpen(false)
    }


    const getRequestStatus = (status, paymentStatus, memberResponse, sourceClubResponse, targetClubResponse) => {
        if (status === TransfersAndHostingStatusesEnum.AWAITING || status === TransfersAndHostingStatusesEnum.PAID) {
            if (paymentStatus !== TransfersAndHostingStatusesEnum.SUCCEEDED)
                return TransfersAndHostingStatusesEnum.UNPAID
            else
                if (memberResponse !== TransfersAndHostingStatusesEnum.APPROVED || sourceClubResponse !== TransfersAndHostingStatusesEnum.APPROVED || targetClubResponse !== TransfersAndHostingStatusesEnum.APPROVED)
                    return TransfersAndHostingStatusesEnum.UNEXPRESSED
                else
                    return TransfersAndHostingStatusesEnum.PAID
        } else
            return status
    }

    const handleGetMe = () => {
        if (role === RoleEnum.ADMIN) return RoleEnum.ADMIN
        else {
            if (pathOr('', ['me', 'member', 'id'], userProfile) === pathOr('', ['request', 'data', 'member', 'id'], dataRequest)) {
                return 'memberResponse'
            } else if (pathOr('', ['me', 'member', 'club', 'id'], userProfile) === pathOr('', ['request', 'data', 'sourceClub', 'id'], dataRequest)) {
                return 'sourceClubResponse'
            } else if (pathOr('', ['me', 'member', 'club', 'id'], userProfile) === pathOr('', ['request', 'data', 'targetClub', 'id'], dataRequest))
                return 'targetClubResponse'
        }
    }

    const renderRejectModalButtons = () => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleRequestReject()}
                    color="danger"
                    round
                    table
                    size="sm">
                    <CheckIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.reject' })}
                </Button>
            </>
        );
    };

    const renderCancelModalButtons = () => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleRequestCancel()}
                    color="danger"
                    round
                    table
                    size="sm">
                    <BlockIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.cancel' })}
                </Button>
            </>
        );
    };

    const getTitle = (type) => {
        if (requestStatus === TransfersAndHostingStatusesEnum.APPROVED) return <Box style={{ color: colors.success }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.approved.title' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.REJECTED) return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.rejected.title' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.CANCELED) return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.canceled.title' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.AWAITING) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.awaiting.title' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.UNPAID) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.unpaid.title' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.UNEXPRESSED) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.unexpressed.title' }, [type])}</Box>
        else return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.title' }, [type])}</Box>
    }

    const getInfoPanelBody = (type) => {
        if (requestStatus === TransfersAndHostingStatusesEnum.AWAITING) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.awaiting.infoPanel' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.UNPAID) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.unpaid.infoPanel' }, [type])}</Box>
        else if (requestStatus === TransfersAndHostingStatusesEnum.UNEXPRESSED) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.unexpressed.infoPanel' }, [type])}</Box>
        else return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.detail.title' }, [type])}</Box>
    }

    const handleBackAction = () => {
        // if (location.state === 'ActivityTable') {
            history.goBack();
        // } else {
            // history.push({
                // pathname: `/admin${generatePath(paths.transfers.list)}`,
                // search: location.state,
            // });
              
        // }
    };

    if (loadingRequest) return <Loading />;
    return (<>
        <PageHeader
            withBackButton={true}
            title={getTitle((type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' }))}
            subTitle={pathOr('', ['request', 'bid'], dataRequest)}
            handleBackAction={handleBackAction}
        />
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={(type === 'transfer') ? getValidationSchemaTransfer(intl) : getValidationSchemaHosting(intl)}
            onSubmit={(values) => { handleRequestApprove(values); }}
            initialValues={requestData}
        >
            {(formikProps) => (<>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <MemberDataCard
                                        clubMemberPersonalData={pathOr('', ['request', 'data', 'member'], dataRequest)}
                                        clubMemberFunctionsData={clubMemberFunctionsData}
                                        clubMemberDisciplinesData={clubMemberDisciplinesData}
                                        readMode={(role === RoleEnum.ADMIN && (pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.AWAITING || pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID)) ? false : true}
                                        type={type}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        role={role}
                                    />
                                    <MemberClubCard
                                        clubMemberSourceClubData={clubMemberSourceClubData}
                                        clubMemberTargetClubData={clubMemberTargetClubData}
                                        detail={true}
                                        newCompany={true}
                                        validateParam={validateParam}
                                        role={role}
                                    />
                                    {(requestStatus !== TransfersAndHostingStatusesEnum.UNPAID) &&
                                        <ApprovedCard
                                            clubMemberApprovedData={pathOr('', ['request', 'data'], dataRequest)}
                                            data={formikProps.values}
                                            formikProps={formikProps}
                                            newCompany={true}
                                            validateParam={validateParam}
                                            readMode={true}
                                            role={role}
                                            status={pathOr('', ['request', 'status'], dataRequest)}
                                            agreement={true}
                                            me={handleGetMe()}
                                            type={type}
                                            requestId={pathOr('', ['request', 'id'], dataRequest)}
                                            refetchRequest={refetchRequest}
                                        />
                                    }
                                </Grid>
                                {(role !== RoleEnum.MEMBER && (pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID || pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.AWAITING)) &&
                                    <Grid item xs={12} align="center">
                                        <br />
                                        {requestStatus === TransfersAndHostingStatusesEnum.UNPAID &&
                                            <Button
                                                align="center"
                                                color="primary"
                                                size="sm"
                                                round
                                                onClick={() => setCancelModalOpen(true)}
                                            >
                                                <BlockIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.cancel' })}
                                            </Button>
                                        }
                                        {(pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID && role === RoleEnum.ADMIN) &&
                                            <Button
                                                align="center"
                                                color="primary"
                                                size="sm"
                                                round
                                                onClick={() => setRejectModalOpen(true)}
                                            >
                                                <BlockIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.reject' })}
                                            </Button>
                                        }
                                        {(role === RoleEnum.ADMIN && pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID && requestStatus !== TransfersAndHostingStatusesEnum.UNPAID) &&
                                            < Button
                                                align="center"
                                                color="primary"
                                                disabled={disableButton}
                                                size="sm"
                                                round
                                                onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                                            >
                                                <CheckIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.approve' })}
                                            </Button>
                                        }
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    {role !== RoleEnum.MEMBER &&
                        <Grid item xs={12} lg={4} className="transferPaymentCard"
                            style={{
                                marginTop: "-30px"
                            }}
                        >
                            {(pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.AWAITING || pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID) &&
                                <IconCard inside>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ color: colors.primary }}>{getInfoPanelBody((type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' }))}</Typography>
                                            <Typography variant="body2" >{intl.formatMessage({ id: 'transfersAndHosting.detail.body.infoPanel1' })} {moment(pathOr('', ['request', 'expiresAt'], dataRequest)).format("DD.MM.YYYY")} {intl.formatMessage({ id: 'transfersAndHosting.detail.body.infoPanel2' }, [pathOr('', ['request', 'expiresIn'], dataRequest)])} </Typography>
                                        </Grid>
                                    </Grid>
                                </IconCard>
                            }
                            <PaymentCard
                                data={pathOr('', ['request', 'data', 'payment'], dataRequest)}
                                detail={(pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.AWAITING || pathOr('', ['request', 'status'], dataRequest) === TransfersAndHostingStatusesEnum.PAID) ? "memberLicenseAwaiting" : "memberLicense"}
                                history={history}
                                refetch={refetchRequest}
                            />
                        </Grid>
                    }
                </Grid>
                <Modal
                    title={`${intl.formatMessage({ id: 'transfersAndHosting.modal.reject.title' })} ${(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}`}
                    open={rejectModalOpen}
                    onClose={() => setRejectModalOpen(false)}
                    actions={renderRejectModalButtons(formikProps)}
                    fullWidth
                >
                    {intl.formatMessage({ id: 'transfersAndHosting.modal.reject.body' })}  {(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}?
                </Modal>
                <Modal
                    title={`${intl.formatMessage({ id: 'transfersAndHosting.modal.cancel.title' })} ${(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}`}
                    open={cancelModalOpen}
                    onClose={() => setCancelModalOpen(false)}
                    actions={renderCancelModalButtons(formikProps)}
                    fullWidth
                >
                    {intl.formatMessage({ id: 'transfersAndHosting.modal.cancel.body' })} {(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}?
                </Modal>
            </>)
            }
        </Formik >
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changePendingTransfersAndHostingNumberAction: (value) => dispatch(changePendingTransfersAndHostingNumberAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TransferDetailPage));

//519