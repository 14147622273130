import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import RemoveIcon from '@material-ui/icons/Remove';
import { colors } from 'common/styles/configLayout';
import Typography from "@material-ui/core/Typography"
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import { useSnackbar } from 'notistack';
import { getApolloClient } from "configFiles/apollo";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useStyles from 'components-lib/FormikField/styles'
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import MEMBER_LICENSE_OPTIONS from "queries/LicensesQueries/memberLicenseOptions";
import MEMBER_LICENSE_REQUEST_SUMMARY from "queries/LicensesQueries/memberLicenseRequestSummary";
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import _ from 'lodash';

const useSelectStyles = makeStyles(selectStyles);
const useStylesIcon = makeStyles((theme) => styles(theme))
const useCheckboxStyles = makeStyles(checkboxStyles);

export const DocumentTable = (props) => {
    const { intl, history, closed, readMode = false, formikProps, detail, 
        callbackRows, rows, requestStatus,
        callbackRemoveDocuments, removeDocuments, clubId, year, 
        clubMembers, setClubMembers, setLicenseSummary, licenseSummary, 
        setLicenseFinalRows, initialFunctions
        //functions, setFunctions
    } = props
    const classes = useStyles();
    const classesIcon = useStylesIcon();
    const selectClasses = useSelectStyles();

    const checkboxClasses = useCheckboxStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [categories, setCategories] = useState([])
    const [disciplines, setDisciplines] = useState([])
    const [functions, setFunctions] = useState([])
    const [selectedClubMembers, setSelectedClubMembers] = useState([...clubMembers])
    const [disabledFunctions, setDisabledFunctions] = useState([])

    const getDisciplines = (disciplines) => {
        let arr = []
        let arrPara = []
        arr = (disciplines).filter(d => d?.id !== '1')?.map(disc => {
            return {
                value: disc.id,
                label: disc?.name,
                categories: (disc?.categories)?.map(cat => {
                    return {
                        value: cat.id,
                        label: cat?.name,
                        disabled: cat?.missingRequirements,
                    }
                })
            }
        })

        arrPara = (disciplines).find(d => d?.id !== '1') && [(disciplines).filter(d => d?.id === '1')?.map(disc => {
            return {
                value: disc.id,
                label: disc?.name,
                categories: (disc?.categories)?.map(cat => {
                    return {
                        value: cat.id,
                        label: cat?.name,
                        disabled: cat?.missingRequirements,
                    }
                })
            }
        })]
        return (disciplines?.length > 0) ? [...arr, ...arrPara?.[0]] : [];
    }


    useEffect(() => {
        setSelectedClubMembers([...clubMembers])
    }, [clubMembers]);

    useEffect(() => {
        setFunctions((initialFunctions) ? initialFunctions : [])
        let tempClubMembers = [...clubMembers]
        let tempIdClubMembers = [];
        let tempDisabledFunctions = [];
        for (let i = 0; i < rows?.length; i++) {
            clubMembers.findIndex(item => (item?.value === rows?.[i]?.clubMember?.value))
            tempClubMembers.splice(tempIdClubMembers, 1)
        }
        setSelectedClubMembers(tempClubMembers)

        for (let i = 0; i < initialFunctions?.length; i++) {
            tempDisabledFunctions.push({ functionIds: [], categoryIds: [] })
        }
        setDisabledFunctions(tempDisabledFunctions)

    }, [initialFunctions]);

    const getFunctions = async (memberId) => {
        try {
            const result = await client.query({
                query: MEMBER_LICENSE_OPTIONS,
                variables: {
                    input: {
                        year: year,
                        clubId: clubId,
                        memberIds: [memberId]
                    }
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let selectCategories = []
            let selectFunctions = (result?.data?.memberLicenseOptions)?.map((member => {
                return {
                    value: member?.member?.id,
                    birth: (member?.member?.person?.profile?.birth) ? (member?.member?.person?.profile?.birth) : undefined,
                    uciId: (member?.member?.uciId) ? (member?.member?.uciId) : undefined,
                    disabled: member?.missingRequirements,
                    functions: (member?.functions)?.map((func) => {
                        return {
                            value: func.id,
                            label: func?.name,
                            disabled: func?.missingRequirements,
                            disciplines: getDisciplines(func?.disciplines)
                        }
                    })
                }
            }))

            const row = [...functions];
            row[functions?.length] = {
                ...selectFunctions?.[0]
            };
            setDisabledFunctions([...disabledFunctions, { functionIds: [], categoryIds: [] }])
            setFunctions((selectFunctions) ? row : []);
        } catch (error) {
            throw error;
        }
    }

    const getLicenses = (idx, value, item, fIdx) => {
        let license = [];
        let finalLicense = [];
        let cyclistDisciplines = [];
        let cyclistWithContractDisciplines = [];
        rows.map(item => {
            (item?.functions?.filter(func => (func?.value === "21"))).map((func, i) => {
                if ((func?.disabled.length === 0) && (func?.category?.disabled)?.length === 0)
                    cyclistWithContractDisciplines.push({
                        disciplineId: func?.discipline?.value,
                        categoryId: func?.category?.value,
                    })
            });

            (item?.functions?.filter(func => (func?.value === "20"))).map((func, i) => {
                if ((func?.disabled.length === 0) && (func?.category?.disabled)?.length === 0)
                    cyclistDisciplines.push({
                        disciplineId: func?.discipline?.value,
                        categoryId: func?.category?.value,
                    })
            });

            for (let i = 0; i < item?.functions?.length; i++) {
                if ((item?.functions?.[i]?.disabled)?.length === 0 && (item?.functions?.[i]?.value !== "20" && item?.functions?.[i]?.value !== "21")) {
                    license.push({
                        memberId: item?.clubMember?.value,
                        functionId: item?.functions?.[i]?.value,
                        disciplines: (item?.functions?.[i]?.discipline) ? [{
                            disciplineId: item?.functions?.[i]?.discipline?.value,
                            categoryId: item?.functions?.[i]?.discipline?.categories?.[0]?.value,
                        }] : undefined
                    })
                }
            }
            if (cyclistDisciplines.length > 0)
                license.push({
                    memberId: item?.clubMember?.value,
                    functionId: "20",
                    disciplines: cyclistDisciplines
                })

            if (cyclistWithContractDisciplines.length > 0)
                license.push({
                    memberId: item?.clubMember?.value,
                    functionId: "21",
                    disciplines: cyclistWithContractDisciplines
                })

            cyclistDisciplines = [];
            cyclistWithContractDisciplines = [];
        })
        setLicenseFinalRows(license)
        
        return license;
    }

    const getLicenseSummary = async () => {

        let licenses = getLicenses()
        if (licenses.length > 0) {
            try {
                const result = await client.query({
                    query: MEMBER_LICENSE_REQUEST_SUMMARY,
                    variables: {
                        input: {
                            year: year,
                            clubId: clubId,
                            licenses: licenses
                        }
                    }
                });
                
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                setLicenseSummary(result?.data?.memberLicenseRequestSummary)
            } catch (error) {
                throw error;
            }
        }
    }

    const getSelectedClubMembers = (value) => {
        let tempClubMembers = [...selectedClubMembers]
        let tempIdClubMembers = selectedClubMembers?.findIndex(item => (item?.value === value?.value))
        tempClubMembers.splice(tempIdClubMembers, 1)
        setSelectedClubMembers(tempClubMembers)
    }

    const handleChangeTo = (idx, value, item, fIdx) => {
        let row = [...rows];

        let disFunctions = [...disabledFunctions];
        if (item === "clubMember") {
            getSelectedClubMembers(value);
            row[idx] = {
                clubMember: value,
                functions: [{ value: "", label: "" }]
            };
            getFunctions(value?.value)
        }
        else if (item === "functions") {
            (row[idx]?.functions).splice(fIdx, 1, { value: value?.value, label: value?.label, disabled: value?.disabled })
            if (value?.value !== "20" && value?.value !== "21") {
                disFunctions[idx] = {
                    functionIds: [...disabledFunctions?.[idx]?.functionIds, value],
                    categoryIds: [...disabledFunctions?.[idx]?.categoryIds]
                }
                setDisabledFunctions(disFunctions)
                getLicenseSummary()
            }


            /*let tempFunctions = [...functions]
            let tempIdClubMembers = tempFunctions?.findIndex(item => (item?.functions?.value === value?.value))
            tempFunctions = functions[idx].functions.splice(tempIdClubMembers, 1)
    
            let final = [
                ...tempFunctions,
            ]*/
            //setFunctions(final);

        } else if (item === "discipline") {
            row[idx].functions[fIdx] = {
                ...rows[idx]?.functions?.[fIdx],
                discipline: value
            }
            disFunctions[idx] = {
                functionIds: [...disabledFunctions?.[idx]?.functionIds],
                categoryIds: [...disabledFunctions?.[idx]?.categoryIds, value],
            }
            //setDisabledFunctions(disFunctions)

        } else {
            row[idx].functions[fIdx] = {
                ...rows[idx]?.functions?.[fIdx],
                category: value
            }
            getLicenseSummary()
        }
        callbackRows(row);
    };

    const handleRemoveSpecificRow = (idx, fIdx, value) => {
        let row = [...rows]
        let tempFindClubMember = (fIdx === 0 && rows[idx].functions?.length === 1) && clubMembers?.find(item => (item?.value === rows?.[idx]?.clubMember?.value))
        if (fIdx === 0 && rows[idx].functions?.length === 1)
            setSelectedClubMembers([...selectedClubMembers, tempFindClubMember])

        row = (fIdx === 0 && rows[idx].functions?.length === 1) ? rows.splice(idx, 1) : rows[idx].functions.splice(fIdx, 1)

        let final = [...rows]

        /*row[idx] = {
            ...rows[idx],
            functions: row[idx].functions.splice(fIdx, 1)
        }*/
        getLicenseSummary()
        callbackRows(final);


        /*let rw = [
            ...rows,
            rows.[idx].functions: {
                ...rows   
            }
        ]
            callbackRows(rw)*/


        //let final = [...rows]
        //(final[idx]?.functions).splice(fIdx, 1, row)
        //callbackRows(row)
    }

    /*
      const handleRemoveSpecificRow = (idx, value) => () => {
        const row = [...rows]
        let tempFunctions = [...functions];
    
        let tempIdFunctions = functions?.findIndex(item => (item?.value === value?.value))
        tempFunctions.splice(tempIdFunctions, 1)
        setFunctions(tempFunctions)
    
        row.splice(idx, 1)
        //setRows(row)
        callbackRows(row)
    }
    */

    const handleAddFunction = (idx) => {
        const row = [...rows];
        row[idx] = {
            clubMember: row[idx]?.clubMember,
            functions: [...rows?.[idx]?.functions, { value: "", label: "" }],
        };
        callbackRows(row);
    };

    const handleAddRow = () => {
        const row = [...rows, { functions: [{ value: "", label: "" }] }];
        callbackRows(row);
    };


    const getMembersOptions = (allMembers, myMembers) => {

        let xorBy = _.xorBy(allMembers, myMembers, (d) => d.value)
        xorBy.splice(xorBy?.findIndex(item => item === ''), 1)
        return xorBy
    }

    const getFunctionsOptions = (allFunctions, myFunctions) => {
        let xorBy = _.xorBy(allFunctions, myFunctions, (d) => d.value)
        if (allFunctions?.find(item => item.value === '20') && !xorBy?.find(item => item.value === '20')) {
            xorBy.push(allFunctions.find(item => item.value === '20'))
        } if (allFunctions?.find(item => item.value === '21') && !xorBy?.find(item => item.value === '21')) {
            xorBy.push(allFunctions.find(item => item.value === '21'))
        }
        xorBy.splice(xorBy?.findIndex(item => item.value === ''), 1)
        return xorBy
    }

    const getDisciplinesOptions = (allDisciplines, myDisciplines) => {

        let myDisc = []
        for (let i = 0; i < myDisciplines?.length; i++) {
            if (myDisciplines[i].discipline)
                myDisc.push(myDisciplines[i].discipline)
        }
        return _.xorBy(allDisciplines, myDisc, (d) => d.value)
    }
    
    return (
        <>
            <Grid container spacing={1}>
                {
                    <Grid item xs={12}  >
                        {Object.values(rows)?.map((item, idx) => (<>
                            <Grid item >
                                {(item?.functions)?.map((fItem, fIdx) => (<Grid container>
                                    <Grid item xs={12} md={11}>
                                        <Grid container spacing={0} style={{ backgroundColor: ((item?.functions?.[fIdx]?.disabled?.length > 0 || item?.clubMember?.disabled || rows[idx]?.functions?.[fIdx]?.category?.disabled?.length > 0) ? colors.errorBackground : ''), borderRadius: '8px', padding: '3px' }} >

                                            <Grid item xs={12} md={2}>
                                                {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) &&
                                                    <>
                                                        {item?.clubMember ? <>
                                                            {(fIdx === 0) &&
                                                                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                    <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Člen klubu</Typography>
                                                                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                                        {(item?.clubMember?.disabled)
                                                                            ? <><ErrorIcon className={classesIcon.specialCircle2} style={{ color: colors.error }} />{(item?.clubMember?.label)?.substr(0, (item?.clubMember?.label).indexOf('('))} </>
                                                                            : <>{(item?.clubMember?.label)?.substr(0, (item?.clubMember?.label).indexOf('('))}</>
                                                                        }
                                                                    </Typography>

                                                                </Box>
                                                            }
                                                        </>
                                                            :
                                                            <Autocomplete
                                                                style={{ borderRadius: '6px' }}
                                                                className={classes.root}
                                                                classes={{
                                                                    paper: classes.selectAutocomplete,
                                                                    clearIndicatorDirty: classes.selectIcon,
                                                                    popupIndicator: classes.selectIcon
                                                                }}
                                                                disabled={false}
                                                                size="small"
                                                                id="combo-box-demo"
                                                                noOptionsText={'Žiadne možnosti'}
                                                                //options={selectedClubMembers}
                                                                options={getMembersOptions(clubMembers, rows?.map(i => (i?.clubMember) ? (i.clubMember) : []))}
                                                                disableClearable
                                                                filterSelectedOptions
                                                                fullWidth
                                                                getOptionLabel={(option) => option.label || ""}
                                                                renderInput={(params) => <TextField {...params} label={"Člen klubu"} variant="outlined" />}
                                                                //onChange={(e) => setFieldValue(name, e.target.value)}
                                                                value={item?.clubMember}
                                                                onChange={(event, value) => { handleChangeTo(idx, value, 'clubMember') }}
                                                                //value={item?.functions}
                                                                variant="outlined"
                                                                defaultValue="Success"
                                                                renderOption={(option) => (<div>
                                                                    {option?.disabled
                                                                        ? <p><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label} (Chýbajucé dokumenty)</p>
                                                                        : <p>{option.label}</p>}
                                                                </div>
                                                                )}

                                                                inputProps={{
                                                                    classes: {
                                                                        root: classes.label,
                                                                    },
                                                                }}
                                                                name={"clubMember"}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                            {(item?.clubMember?.disabled) ?
                                                <></>
                                                : <>
                                                    <Grid item xs={6} md={2}>
                                                        {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                            {item?.clubMember && <>
                                                                {(fIdx === 0) &&
                                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>UCI ID</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{(functions?.find(f => f?.value === item?.clubMember?.value)?.uciId) ? (functions?.find(f => f?.value === item?.clubMember?.value)?.uciId) : "-"}</Typography>
                                                                    </Box>
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                            {item?.clubMember && <>
                                                                {(fIdx === 0) &&
                                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Dátum narodenia</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{(functions?.find(f => f?.value === item?.clubMember?.value)?.birth) ? (moment(functions?.find(f => f?.value === item?.clubMember?.value)?.birth).format("DD.MM.YYYY")) : "Neuvedený"}</Typography>
                                                                    </Box>
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                        }
                                                    </Grid>
                                                </>}
                                            {(item?.clubMember && !item?.clubMember?.disabled) &&
                                                <>
                                                    <Grid item xs={6} md={2}>
                                                        {(functions?.find(f => f?.value === item?.clubMember?.value)?.functions) && <>
                                                            {(item?.functions?.[fIdx]?.value !== "")
                                                                ?
                                                                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                    <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>{fIdx + 1}. Funkcia</Typography>
                                                                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                                        {(item?.functions?.[fIdx]?.disabled?.length > 0)
                                                                            ? <><ErrorIcon className={classesIcon.specialCircle2} style={{ color: colors.error }} />  {item?.functions?.[fIdx]?.label}</>
                                                                            : <>{item?.functions?.[fIdx]?.label}</>
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                                :
                                                                <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
                                                                    <InputLabel htmlFor="outlined-age-native-simple" style={{ color: colors.labelAndBorderInputs }}>Funkcia</InputLabel>
                                                                    <Select
                                                                        renderInput={(params) => <TextField {...params} label={"Funkcia"} variant="outlined" />}
                                                                        MenuProps={{ classes: { paper: selectClasses.selectMenuItem } }}
                                                                        className={classes.selectForm + ' ' + classes.root}
                                                                        style={{ borderRadius: '6px' }}
                                                                        noOptionsText={'Žiadne možnosti'}
                                                                        //value={item?.functions?.[fIdx]}
                                                                        onChange={(event) => { handleChangeTo(idx, event.target.value, 'functions', fIdx) }}
                                                                        inputProps={{
                                                                            id: 'outlined-age-native-simple',
                                                                        }}
                                                                    >
                                                                        {getFunctionsOptions(functions?.find(f => f?.value === item?.clubMember?.value)?.functions, item?.functions).length !== 0 ?
                                                                            getFunctionsOptions(functions?.find(f => f?.value === item?.clubMember?.value)?.functions, item?.functions)?.map((option) => (
                                                                                <MenuItem
                                                                                    key={option.value}
                                                                                    value={option}
                                                                                    classes={{
                                                                                        root: selectClasses.selectMenuItem,
                                                                                        selected: selectClasses.selectMenuItemSelectedMultiple
                                                                                    }}
                                                                                >
                                                                                    {option?.disabled?.length > 0
                                                                                        ? <Typography variant="body2">
                                                                                            <ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label} (Chýbajúce dokumenty)
                                                                                        </Typography>
                                                                                        : <Typography variant="body2">{option.label}</Typography>
                                                                                    }
                                                                                </MenuItem>
                                                                            ))
                                                                            :
                                                                            <MenuItem classes={{ root: selectClasses.selectMenuItem }} disabled={true}>
                                                                                <Typography variant="body2"><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} />Žiadne možnosti</Typography>
                                                                            </MenuItem>
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            }
                                                        </>
                                                        }
                                                    </Grid>
                                                    {!(item?.functions?.[fIdx]?.disabled?.length > 0) ?
                                                        <>
                                                            {(item?.clubMember && (item?.functions?.[fIdx]?.value === "20" || item?.functions?.[fIdx]?.value === "21")) && <>
                                                                {(item?.functions?.[fIdx]?.discipline) ?
                                                                    <Grid item xs={6} md={2}>
                                                                        <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                            <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Odvetvie</Typography>
                                                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.functions?.[fIdx]?.discipline?.label}</Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={6} md={2}>

                                                                        <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
                                                                            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: colors.labelAndBorderInputs }}>Odvetvie</InputLabel>
                                                                            <Select
                                                                                renderInput={(params) => <TextField {...params} label={"Odvetvie"} variant="outlined" />}
                                                                                MenuProps={{ classes: { paper: selectClasses.selectMenuItem } }}
                                                                                className={classes.selectForm + ' ' + classes.root}
                                                                                style={{ borderRadius: '6px' }}
                                                                                label={"Odvetvie"}
                                                                                //value={item?.functions?.[fIdx]}
                                                                                onChange={(event, value, three) => { handleChangeTo(idx, event.target.value, 'discipline', fIdx) }}
                                                                                inputProps={{
                                                                                    id: 'outlined-age-native-simple',
                                                                                }}
                                                                                renderOption={(option) => (<div>
                                                                                    {option?.disabled?.length > 0
                                                                                        ? <p><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label} (Chýbajucé dokumenty)</p>
                                                                                        : <p>{option.label}</p>}
                                                                                </div>
                                                                                )}
                                                                            >
                                                                                {(_.xor((functions?.find(f => f?.value === item?.clubMember?.value)?.functions?.find(i => (i?.value === item?.functions?.[fIdx]?.value))?.disciplines), disabledFunctions?.[idx]?.categoryIds)).length !== 0 ?
                                                                                    getDisciplinesOptions((functions?.find(f => f?.value === item?.clubMember?.value)?.functions?.find(i => (i?.value === item?.functions?.[fIdx]?.value))?.disciplines), item?.functions)?.map((option) => (
                                                                                        <MenuItem
                                                                                            key={option.value}
                                                                                            value={option}
                                                                                            classes={{
                                                                                                root: selectClasses.selectMenuItem,
                                                                                                selected: selectClasses.selectMenuItemSelectedMultiple
                                                                                            }}
                                                                                        >
                                                                                            {option?.disabled?.length > 0
                                                                                                ? <Typography variant="body2"><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label}</Typography>
                                                                                                : <Typography variant="body2">{option.label}</Typography>}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    :
                                                                                    <MenuItem classes={{ root: selectClasses.selectMenuItem }} disabled={true}>
                                                                                        <Typography variant="body2"><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} />Žiadne možnosti</Typography>
                                                                                    </MenuItem>
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                        :
                                                        <>

                                                        </>
                                                    }

                                                    {(item?.clubMember && (item?.functions?.[fIdx]?.value === "20" || item?.functions?.[fIdx]?.value === "21")) && <>
                                                        {(item?.functions?.[fIdx]?.discipline) &&
                                                            <>
                                                                {(item?.functions?.[fIdx]?.category) ?
                                                                    <Grid item xs={12} md={2}>
                                                                        <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                            <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Kategória</Typography>
                                                                            {(rows[idx]?.functions?.[fIdx]?.category?.disabled?.length > 0)
                                                                                ? <Typography variant="body2" style={{ fontSize: "0.9em" }}><ErrorIcon className={classesIcon.specialCircle2} style={{ color: colors.error }} />{item?.functions?.[fIdx]?.category?.label}</Typography>
                                                                                : <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.functions?.[fIdx]?.category?.label}</Typography>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={12} md={2}>
                                                                        <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
                                                                            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: colors.labelAndBorderInputs }}>Kategória</InputLabel>
                                                                            <Select
                                                                                renderInput={(params) => <TextField {...params} label={"Kategória"} variant="outlined" />}
                                                                                MenuProps={{ classes: { paper: selectClasses.selectMenuItem } }}
                                                                                className={classes.selectForm + ' ' + classes.root}
                                                                                style={{ borderRadius: '6px' }}
                                                                                label={"Kategória"}
                                                                                //value={item?.functions?.[fIdx]}
                                                                                onChange={(event, value, three) => { handleChangeTo(idx, event.target.value, 'category', fIdx) }}
                                                                                inputProps={{
                                                                                    id: 'outlined-age-native-simple',
                                                                                }}
                                                                                renderOption={(option) => (<div>
                                                                                    {option?.disabled?.length > 0
                                                                                        ? <p><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label} (Chýbajucé dokumenty)</p>
                                                                                        : <p>{option.label}</p>}
                                                                                </div>
                                                                                )}
                                                                            >
                                                                                {((functions?.find(f => f?.value === item?.clubMember?.value).functions?.find(i => (i?.value === item?.functions?.[fIdx]?.value))?.disciplines)?.find(i => (i?.value === item?.functions?.[fIdx]?.discipline?.value))?.categories).length !== 0 ?
                                                                                    ((functions?.find(f => f?.value === item?.clubMember?.value).functions?.find(i => (i?.value === item?.functions?.[fIdx]?.value))?.disciplines)?.find(i => (i?.value === item?.functions?.[fIdx]?.discipline?.value))?.categories)?.map((option) => (
                                                                                        <MenuItem
                                                                                            key={option.value}
                                                                                            //disabled={(option?.disabled)?.length > 0}
                                                                                            value={option}
                                                                                            classes={{
                                                                                                root: selectClasses.selectMenuItem,
                                                                                                selected: selectClasses.selectMenuItemSelectedMultiple
                                                                                            }}
                                                                                        >
                                                                                            {(option?.disabled)?.length > 0
                                                                                                ? <Typography variant="body2"><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label}</Typography>
                                                                                                : <Typography variant="body2">{option.label}</Typography>}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    :
                                                                                    <MenuItem classes={{ root: selectClasses.selectMenuItem }} disabled={true}>
                                                                                        <Typography variant="body2"><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} />Žiadne možnosti</Typography>
                                                                                    </MenuItem>
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                }

                                                            </>
                                                        }
                                                    </>}
                                                </>

                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        {(item?.clubMember || idx > 0) && <>
                                            <div style={{ whiteSpace: 'nowrap', textAlign: 'right', margin: '8px 0' }}>
                                                {(item?.functions?.[fIdx]?.disabled?.length > 0 || item?.clubMember?.disabled || rows[idx]?.functions?.[fIdx]?.category?.disabled?.length > 0) &&
                                                    <Tooltip title={
                                                        <div style={{ margin: '5px' }}>
                                                            <Typography variant="body2"><b>Chýbajúce dokumeny:</b></Typography>
                                                            {(item?.functions?.[fIdx]?.disabled)?.map(i =>
                                                                <Typography variant="body2">&#x2022; {intl.formatMessage({ id: `documents.profile.${i?.document}` })}</Typography>
                                                            )}
                                                            {(item?.clubMember?.disabled) && <>
                                                                <Typography variant="body2" >&#x2022; {" "}
                                                                    {(item?.clubMember?.disabled === "withoutPhoto")
                                                                        ? `Chýbajúca profilová fotografia člena`
                                                                        : "Osoba má pozastavené členstvo v klube"
                                                                    }
                                                                </Typography>
                                                            </>
                                                            }

                                                            {(rows[idx]?.functions?.[fIdx]?.category?.disabled)?.map(i =>
                                                                <Typography variant="body2">&#x2022; {intl.formatMessage({ id: `documents.profile.${i?.document}` })}</Typography>
                                                            )}


                                                        </div>}>
                                                        <ErrorOutlineIcon className={classesIcon.errorCircle} />

                                                    </Tooltip>
                                                }
                                                {//(item?.functions?.length > 1) &&
                                                    <Tooltip title="Odobrať riadok">
                                                        <RemoveIcon
                                                            className={classesIcon.circle}
                                                            onClick={() => handleRemoveSpecificRow(idx, fIdx, item?.functions?.[fIdx])}
                                                        />
                                                    </Tooltip>
                                                }
                                                {(fIdx === item?.functions?.length - 1 && item?.functions?.[fIdx]?.value !== "" && functions?.find(f => f?.value === item?.clubMember?.value)?.functions?.length > 0) && <>
                                                    < Tooltip title="Pridať funkciu">
                                                        <AddIcon
                                                            className={classesIcon.circle}
                                                            onClick={() => handleAddFunction(idx)}
                                                        />
                                                    </Tooltip>
                                                </>
                                                }
                                            </div>
                                        </>
                                        }
                                    </Grid>


                                </Grid>
                                ))
                                }
                            </Grid>
                        </>
                        ))}
                    </Grid>
                }
            </Grid>
            {
                clubMembers.length > 0 &&
                <Box style={{ marginTop: '5px' }}>
                    <Button
                        disabled={(selectedClubMembers?.length === 0 || ((!rows?.[rows?.length - 1]?.clubMember) && rows?.length > 0)) ? true : false}
                        align="center"
                        color="primary"
                        size="sm"
                        round
                        table
                        onClick={handleAddRow}
                    >
                        <PersonAddIcon /> Pridať osobu
                    </Button>
                </Box>
            }
        </>
    )
}

export default injectIntl(DocumentTable);