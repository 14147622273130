import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors } from 'common/styles/configLayout';
import Loading from "components-lib/Loading/Loading";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStylesAutocomplete from 'components-lib/FormikField/styles'
import CLUB from 'queries/ClubsQueries/club';
import { getApolloClient } from "configFiles/apollo";
import { pathOr } from 'rambda';
import { useSnackbar } from 'notistack';
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme));

const MemberClubCard = (props) => {
  const { intl, history, loading, clubs, role, setNewClubId, setClubMemberTargetClubData, clubMemberSourceClubData, clubMemberTargetClubData, detail } = props;
  const classes = useStyles();
  const classesAutocomplete = useStylesAutocomplete();
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const handleSetClub = async (value) => {
    try {
      const result = await client.query({
        query: CLUB,
        variables: {
          id: value?.value
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      setClubMemberTargetClubData(result?.data?.club)
    } catch (error) {
      throw error;
    }
  }

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item 
          // xs={12} md={6}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}> {intl.formatMessage({ id: 'transfersAndHosting.clubCard.sourceClub' })}</Typography></Grid>
            <Grid item xs={12}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.name' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {clubMemberSourceClubData?.profile?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.address' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberSourceClubData?.profile?.address1} {clubMemberSourceClubData?.profile?.address2}</Typography>
                <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberSourceClubData?.profile?.postal} {clubMemberSourceClubData?.profile?.city}</Typography>
                <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberSourceClubData?.profile?.country}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              {clubMemberSourceClubData?.chairman ?
                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                  <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.president' })}</Typography>
                  <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberSourceClubData?.chairman?.person?.profile?.firstName} {clubMemberSourceClubData?.chairman?.person?.profile?.lastName}</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberSourceClubData?.chairman.person?.profile?.email}</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberSourceClubData?.chairman.person?.profile?.phone}</Typography>
                </Box>
                :
                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                  <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>Typ klubu</Typography>
                  <Typography variant="body2" style={{ fontSize: "0.9em" }}>Zahraničný klub</Typography>
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.targetClub' })}</Typography></Grid>
            {(role === RoleEnum.ADMIN && !detail) ?
              <Grid item xs={12}>
                <Autocomplete
                  style={{ borderRadius: '6px' }}
                  className={classesAutocomplete.root}
                  classes={{
                    paper: classesAutocomplete.selectAutocomplete,
                    clearIndicatorDirty: classesAutocomplete.selectIcon,
                    popupIndicator: classesAutocomplete.selectIcon
                  }}
                  disabled={false}
                  size="small"
                  id="combo-box-demo"
                  noOptionsText={'Žiadne možnosti'}
                  options={clubs}
                  disableClearable
                  filterSelectedOptions
                  fullWidth
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: 'transfersAndHosting.clubCard.targetClub' })} variant="outlined" />}
                  onChange={(event, value) => { handleSetClub(value); setNewClubId(value?.value) }}
                  variant="outlined"
                  defaultValue="Success"
                  renderOption={(option) => (<div><p>{option.label}</p></div>)}
                  inputProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  name={"clubMember"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              :
              <>
                <Grid item xs={12}>
                  <Box className={classes.readOnlyGrid}>
                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.name' })}</Typography>
                    <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                      {clubMemberTargetClubData?.profile?.name}
                    </Typography>
                  </Box>
                </Grid>
              </>
            }
            {clubMemberTargetClubData && <>
              <Grid item xs={12} lg={6}>
                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                  <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.address' })}</Typography>
                  <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberTargetClubData?.profile?.address1} {clubMemberTargetClubData?.profile?.address2}</Typography>
                  <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberTargetClubData?.profile?.postal} {clubMemberTargetClubData?.profile?.city}</Typography>
                  <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberTargetClubData?.profile?.country}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                {clubMemberTargetClubData?.chairman ?
                  <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.clubCard.president' })}</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>{clubMemberTargetClubData?.chairman?.person?.profile?.firstName} {clubMemberTargetClubData?.chairman?.person?.profile?.lastName}</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberTargetClubData?.chairman.person?.profile?.email}</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em", marginTop: "-2px" }}>{clubMemberTargetClubData?.chairman.person?.profile?.phone}</Typography>
                  </Box>
                  :
                  <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>Typ klubu</Typography>
                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>Zahraničný klub</Typography>
                  </Box>
                }
              </Grid>
            </>
            }
          </Grid>
        </Grid >
      </Grid>
    )
  }

  if (loading) return <Loading />
  return (
    <IconCard 
      margin= "16px 0 0 0" 
    >
      {renderContent()}
    </IconCard>
  );
};

export default withRouter(injectIntl(MemberClubCard));

//193