import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../paths';
import { processLogout } from '../../helpers/helpers';
import { connect } from 'react-redux';
import { changeLastUrlAction, changeUserRoleAction } from 'redux/actions';

const LogoutPage = (props) => {
    const { changeLastUrlAction, } = props;
    let url = "/aaaa/aaa"

    useEffect(() => {
        changeLastUrlAction('');
    }, [changeLastUrlAction]);
    processLogout();
    return (
        <Redirect to={`/auth${paths.auth.login}`} />
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
    lastUrl: state.lastUrl,
    pendingRequestsNumber: state.pendingRequestsNumber
});

const mapDispatchToProps = (dispatch) => ({
    changeLastUrlAction: (value) => dispatch(changeLastUrlAction(value)),
    changeUserRoleAction: (value) => dispatch(changeUserRoleAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
