import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import Loading from "components-lib/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { colors, } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import LicenseDetailTable from './LicenseCards/LicenseDetailTable';
import MEMBER_LICENSE_CARD_REQUEST from 'queries/LicensesQueries/memberLicenseCardRequest';
import moment from "moment";
import PaymentCard from '../Payments/PaymentCards/PaymentCard';
import MEMBER_LICENSE_CARD_REQUEST_CONFIRM from 'queries/LicensesQueries/memberLicenseCardRequestConfirm'
import MEMBER_LICENSE_CARD_REQUEST_CANCEL from 'queries/LicensesQueries/memberLicenseCardRequestCancel'
import { getApolloClient } from "configFiles/apollo";
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_CARD_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseCardRequestsTable';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from 'react-redux';
import LicensePaymentTable from './LicenseCards/LicensePaymentTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';

// css
import '../../assets/css/licenseDetailPage.css';

const useStyles = makeStyles((theme) => styles(theme))

const LicenseCardDetailPage = (props) => {
    const { intl, history, role, changeLicensesNumberAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [licensesCount, setLicensesCount] = useState(null)
    const [disableButton, setDisableButton] = useState(false);

    const licenseCardId = (props?.match?.params?.licenseCardId) ? props?.match?.params?.licenseCardId : undefined
    const { loading, data: memberLicenseCardRequest, refetch: memberLicenseRefetch } = useQuery(MEMBER_LICENSE_CARD_REQUEST, { variables: { id: licenseCardId } });
    const [memberLicenseCardRequestConfirm] = useMutation(MEMBER_LICENSE_CARD_REQUEST_CONFIRM)
    const [memberLicenseCardRequestCancel] = useMutation(MEMBER_LICENSE_CARD_REQUEST_CANCEL)

    const numberOfCard = memberLicenseCardRequest?.memberLicenseCardRequest?.licenses?.length;

    // const getLicensesNumber = async () => {
    //     try {
    //         const result = await client.query({
    //             query: MEMBER_LICENSE_CARD_REQUESTS_TABLE,
    //             variables: {
    //                 "filter": [
    //                     {
    //                         "status": {
    //                             "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
    //                         }
    //                     }
    //                 ]
    //             }
    //         });

    //         console.log(result)

    //         if (result.error) {
    //             throw result.error;
    //         }
    //         if (result.errors) {
    //             throw result.errors[0];
    //         }
    //         changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    useEffect(() => {
        setLicensesCount(Object.values(pathOr('', ['memberLicenseCardRequest', 'licenses'], memberLicenseCardRequest))?.map((x, index) => { return index })?.length);
    }, [memberLicenseCardRequest]);

    function downloadURI(url) {
        return new Promise((resolve, reject) => {
            try {
                window.open(url, '_blank'); // Open the URL in a new tab
                resolve(); // Resolve the Promise if successful
            } catch (error) {
                reject(error); // Reject the Promise if an error occurs
            }
        });
    }

    const handleConfirmLicense = () => {
        setDisableButton(true);
        memberLicenseCardRequestConfirm({
            variables: {
                id: licenseCardId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o kartu bola potvrdená", { variant: 'success' });
            // getLicensesNumber();
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleDownloadLicense = (url) => {
        setDisableButton(true);
        downloadURI(url)
            .then(() => {
                memberLicenseRefetch();
                setDisableButton(false);
            })
            .catch((error) => {
                console.error('Download failed:', error);
                setDisableButton(false);
            });
    };

    const handleDownloadPersonLicense = (url) => {
        downloadURI(url);
    }

    const handleCancelLicense = () => {
        setDisableButton(true);
        memberLicenseCardRequestCancel({
            variables: {
                id: licenseCardId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola zrušená", { variant: 'success' });
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }



    const getActions = () => {
        let action = [];
        if (role === RoleEnum.ADMIN) {
            if ((pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest) === "Downloaded"))
                action.push({
                    title: "Označiť ako vytlačené",
                    icon: <CheckIcon />,
                    disabled: disableButton,
                    onClick: () => handleConfirmLicense(),
                });
            if ((pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest) === "Pending"))
                action.push({
                    title: "Zrušiť žiadosť",
                    icon: <BlockIcon />,
                    color: "warning",
                    disabled: disableButton,
                    onClick: () => handleCancelLicense(),
                })
        }
        return action;
    };

    const getStatus = (status) => {
        if (status === "Pending") return <Box style={{ color: colors.warning }} >Žiadosť o karty (čaká na platbu)</Box>
        else if (status === "Paid") return <Box>Žiadosť o karty (zaplatená)</Box>
        else if (status === "Ready") return <Box style={{ color: colors.warning }}>Žiadosť o karty (čaká na výrobu)</Box>
        else if (status === "PartialReady") return <Box style={{ color: colors.warning }}>Žiadosť o karty (čiastočne spracovaná)</Box>
        else if (status === "Downloaded") return <Box style={{ color: colors.secondary }}>Žiadosť o karty (stiahnuté PDF)</Box>
        else if (status === "Completed") return <Box style={{ color: colors.success }}>Žiadosť o karty (dokončená)</Box>
        else if (status === "Canceled") return <Box style={{ color: colors.error }}>Žiadosť o karty (zrušená)</Box>
        else return <Box>{status}</Box>
    }

    const getColor = (status) => {
        if (status === "Pending") return colors.warning;
        else if (status === "Ready") return colors.warning;
        else if (status === "PartialReady") return colors.warning;
        else if (status === "Downloaded") return colors.secondary;
        else if (status === "Completed") return colors.success;
        else if (status === "Canceled") return colors.error;
    }

    const handleBackAction = () => {
        history.goBack();
    };

    if (loading) return <Loading />;
    return (
        <Box className={'containerLicenseDetailPageHeader'} >

            <PageHeader
                title={getStatus(pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest))}
                subTitle={getStatus(pathOr('', ['memberLicenseCardRequest', 'bid'], memberLicenseCardRequest))}
                actions={getActions()}
                withBackButton={true}
                handleBackAction={handleBackAction}
            />
            <Box className={'containerLicenseDetailPage'} >
                <Grid 
                    className={'containerLicenseDetail'}
                >
                    <IconCard title={""} 
                        inside margin='0 0 0 0'    
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box className={classes.readOnlyGrid}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Klub:</span>
                                                <Box className={classes.floatRight}>
                                                    <span style={{ fontSize: "1em", color: getColor(pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest)) }} >{pathOr('', ['memberLicenseCardRequest', 'club', 'profile', 'name'], memberLicenseCardRequest)}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Dátum podania:</span>
                                                <Box className={classes.floatRight}>
                                                    <span>{moment(pathOr('', ['memberLicenseCardRequest', 'createdAt'], memberLicenseCardRequest)).format("DD.MM.YYYY")}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Počet licencií: </span>
                                                <Box className={classes.floatRight}>
                                                    <span>{licensesCount}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                {((role === RoleEnum.ADMIN) && (pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest) === "Ready" || pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest) === "Downloaded" || pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest) === "Completed")) &&
                                    <Box className={classes.floatRight}>
                                        <Button round table disabled={disableButton} color="primary" onClick={() => handleDownloadLicense(memberLicenseCardRequest.memberLicenseCardRequest.cardsFileUrl)}><GetAppIcon /> Stiahnuť všetko</Button>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LicenseDetailTable
                                    cardDetail={true}
                                    rows={pathOr('', ['memberLicenseCardRequest', 'licenses'], memberLicenseCardRequest)}
                                    role={role}
                                    status={pathOr('', ['memberLicenseCardRequest', 'status'], memberLicenseCardRequest)}
                                    defaultFilter={null}
                                    refetch={memberLicenseRefetch}
                                    history={history}
                                    onDownloadPersonLicense={handleDownloadPersonLicense}
                                />
                            </Grid>
                        </Grid>
                    </IconCard>
                </Grid>

                <Grid 
                    className={'containerLicensePayment'}
                    style={{ display: 'flex'}} 
                >
                    <Box
                        style={{flex: 1}}
                        className={'licenseFees'}
                    >
                        <IconCard title={"Licenčné poplatky"} 
                            inside
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <LicensePaymentTable
                                        licenseSummary={pathOr('', ['memberLicenseCardRequest', 'summary'], memberLicenseCardRequest)}
                                        numberOfCard={numberOfCard}
                                    />
                                </Grid>
                            </Grid>
                        </IconCard>
                    </Box>

                    <Grid
                        className='licensePaymentCard'
                        style={{ marginTop: '-13px', flex: 1}}
                    >
                        <PaymentCard
                            data={pathOr('', ['memberLicenseCardRequest', 'payment'], memberLicenseCardRequest)}
                            detail="memberLicenseAwaiting"
                            history={history}
                            // getLicensesNumber={getLicensesNumber()}
                            refetch={memberLicenseRefetch}
                        />
                    </Grid>

                </Grid>

            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseCardDetailPage));


