import gql from 'graphql-tag';

const CLUB = gql`
query club($id: ID!){
  club(id: $id)  {
    id
    bid
    type
    issSyncedAt
    hasAttendedConference
    licenses {
      current {
      	year
        isLicensed
        isPurchasable
        request{
        	year
          id
          status
        	payment{
          	id
          	bid
          	status
          	purpose
         	 	method
          	amount
          	bankIBAN
          	variableSymbol
          	invoiceNumber
          	payBySquare
          	paidAt
          	dueDate
          	createdAt
        	}
      	}
    	}   
      next {
      	year
        isLicensed
        isPurchasable
        request{
        	year
          id
          status
        	payment{
          	id
          	bid
          	status
          	purpose
         	 	method
          	amount
          	bankIBAN
          	variableSymbol
          	invoiceNumber
          	payBySquare
          	paidAt
          	dueDate
          	createdAt
        	}
      	}
    	}  
    }
    profile{
      name
      logoUrl
      email
      site
      social
      businessName
      legalForm
      ico
      dic
      icDPH
      registrationNumber
      registrationDate
      address1
      address2
      postal
      city
      country
      district
      correspondenceAddress1
      correspondenceAddress2
      correspondencePostal
      correspondencePostal
      correspondenceCity
      correspondenceCountry
      correspondenceDistrict
      bankName
      bankLocation
      bankIBAN
    	bankSWIFT
      useCorrespondenceAddress
    }
      chairman{
        id
        bid
        person{
          profile{
            firstName
            lastName
            phone
            email
          }
        }
      }
      statutory{
        id
        bid
        person{
          profile{
            firstName
            lastName
            phone
            email
          }
        }
      }

    documents{
      id
      bid
      type
      status
      name
      validFrom
      validTo
      files{
        size
        format
        downloadUrl
      }
    }
  }  
}
`;

export default CLUB;