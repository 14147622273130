import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import { colors } from "common/styles/configLayout.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MEMBER_LICENSE_REGENERATE from 'queries/MembersQueries/memberLicenseRegenerate';
import MEMBER_LICENSE_SEND_WALLET_PASS from 'queries/MembersQueries/memberLicenseSendWalletPass';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import RoleEnum from 'common/enums/RoleEnum';

import appleBtn from 'assets/img/appleButton.svg';
import googleBtn from 'assets/img/googleButton.svg';


const useStyles = makeStyles((theme) => styles(theme))

const LicenseCard = (props) => {
    const { intl, refetch, licenses, role, currentYear, history, memberId } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [memberLicenseRegenerate] = useMutation(MEMBER_LICENSE_REGENERATE);
    const [memberLicenseSendWalletPass] = useMutation(MEMBER_LICENSE_SEND_WALLET_PASS);
    const [disableButton, setDisableButton] = useState(false);

    const handleMemberLicenseRegenerate = (licenseId) => {
        setDisableButton(true)
        memberLicenseRegenerate({
            variables: {
                ids: (licenseId) ? [licenseId] : undefined,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'member.licenseCard.snackbar.successRegenerate' }), { variant: 'success' });
            setDisableButton(false)
            refetch();
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false)
        });
    }

    const handleMemberLicenseDownload = (url) => {
        window.open(url, "_blank").focus()
    }

    
    const handleLicenseMail = async (licenseId) => {
        setDisableButton(true); // Disable button during loading
    
        try {
            const response = await memberLicenseSendWalletPass({
                variables: {
                    id: licenseId,
                }
            });
            
            enqueueSnackbar(intl.formatMessage({ id: "member.licenseCard.snackbar.successSendCard" }), { variant: 'success' });
        } catch (err) {
            console.log('[Error]: ', err);
            enqueueSnackbar(intl.formatMessage({ id: "member.licenseCard.snackbar.errorSendCard" }), { variant: 'error' });
        } finally {
            setDisableButton(false); // Re-enable button after completion
        }
    };

    const getLicenseHistory = () => {
        return (
            <Grid container>
                {Object.values(licenses).filter(i => i.year !== currentYear)?.length > 0 ?
                    <Grid item xs={12}>
                        {Object.values(licenses).filter(i => i.year !== currentYear)?.reverse().map((item) =>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.warning }}>{intl.formatMessage({ id: 'table.year' })}</Typography>
                                                <Typography variant="body2" style={{ fontSize: "1.2em" }}>{item?.year}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.warning }}>{intl.formatMessage({ id: 'functions.function.title' })}</Typography>
                                                <Typography variant="body2" style={{ fontSize: "1.2em" }}>{item?.function?.name}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Typography variant="body2" style={{ fontSize: "1em", color: colors.textBody }}>{intl.formatMessage({ id: 'member.licenseCard.history.without' })}</Typography>
                    </Grid>
                }
            </Grid>
        )
    }

    const handleDetailLicense = (id) => {
        // history.push(`/admin/licenses/detail/${id}`)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {Object.values(licenses).filter(i => i.year === currentYear)?.map((item) =>
                    <IconCard background={!(item?.revokedAt) ? colors.successBackground : colors.disabledBackground}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" style={{ color: !(item?.revokedAt) ? colors.success : colors.disabled }}>{item.year} {!(item?.revokedAt) ? '' : intl.formatMessage({ id: 'member.licenseCard.notActive' })}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: !(item?.revokedAt) ? colors.success : colors.disabled }}>{intl.formatMessage({ id: 'functions.function.title' })}</Typography>
                                                    <Typography variant="body2" style={{ fontSize: "1.2em", color: !(item?.revokedAt) ? '' : colors.disabled }}>{item?.function?.name}</Typography>
                                                </Box>
                                            </Grid>
                                            {(item?.disciplines)?.map((disc, idx) =>
                                                <Grid item xs={12}>
                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <Typography variant="body2" style={{ fontSize: "0.8em", color: !(item?.revokedAt) ? colors.success : colors.disabled }}>{idx + 1}. {intl.formatMessage({ id: 'functions.discipline' })}</Typography>
                                                                <Typography variant="body2" style={{ fontSize: "1.2em", color: !(item?.revokedAt) ? '' : colors.disabled }}>{disc?.discipline?.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="body2" style={{ fontSize: "0.8em", color: !(item?.revokedAt) ? colors.success : colors.disabled }}>{idx + 1}. {intl.formatMessage({ id: 'functions.category' })}</Typography>
                                                                <Typography variant="body2" style={{ fontSize: "1.2em", color: !(item?.revokedAt) ? '' : colors.disabled }}>{disc?.category?.name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    {/* <Button
                                                        size="lg" 
                                                        color="info" 
                                                        round
                                                        onClick={() => {
                                                            window.location.href = item.wallet.googleUrl;
                                                        }}
                                                        width= '9rem'
                                                    >
                                                         <googleButton/>
                                                        {intl.formatMessage({ id: 'pageHeader.back' })}
                                                    </Button> */}
                                                    
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} align="center">
                                        <div className={"thumbnail"} style={{ opacity: !(item?.revokedAt) ? 1 : 0.5 }}>
                                            <img style={{ filter: !(item?.revokedAt) ? '' : 'grayscale(100%)', borderRadius: "5px" }} src={item?.frontImageUrl} width="100%" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} align="center">
                                        <div className={"thumbnail"} style={{ opacity: !(item?.revokedAt) ? 1 : 0.5 }}>
                                            <img style={{ filter: !(item?.revokedAt) ? '' : 'grayscale(100%)', borderRadius: "5px" }} src={item?.backImageUrl} width="100%" />
                                        </div>
                                    </Grid>

                                    { (role === RoleEnum.USER || role === RoleEnum.MEMBER) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-end',
                                                padding: '1rem 0 0 0',
                                                gap: '0.5rem',
                                                cursor: 'pointer',
                                                width: '100%',
                                            }}
                                        >           
                                            <div
                                                style={{
                                                    display: 'flex', flexDirection: 'row',
                                                    gap: '0.5rem'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '54%',
                                                        // minWidth: '120px',
                                                        maxWidth: '12rem'
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = item.wallet.googleUrl;
                                                    }}
                                                >
                                                    <img src={googleBtn} width="100%" />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '46%',
                                                        // minWidth: '7rem',
                                                        maxWidth: '10rem'
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = item.wallet.appleUrl;
                                                    }}
                                                >
                                                    <img src={appleBtn} width="100%" />
                                                </div>
                                            </div>                                 
                                            <Button
                                                color="primary"
                                                size="sm"
                                                round
                                                table
                                                onClick={() => { handleMemberLicenseDownload(item.downloadUrl) }}
                                            >
                                                <GetAppIcon /> {intl.formatMessage({ id: 'member.licenseCard.button.download' })}
                                            </Button>
                                        </div>
                                    )}
                                    {(role === RoleEnum.ADMIN && !item?.revokedAt) &&
                                        <Grid item xs={12} align="right" 
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-end',
                                                // padding: '1rem 0 0 0',
                                                gap: '0.5rem',
                                                cursor: 'pointer',
                                                width: '100%',
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                size="sm"
                                                disabled={disableButton}
                                                round
                                                table
                                                onClick={() => {handleLicenseMail(item?.id)}}
                                            >
                                                <MailOutlineIcon /> {intl.formatMessage({ id: 'member.licenseCard.button.sendMail' })}
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                disabled={disableButton}
                                                round
                                                table
                                                onClick={() => { handleMemberLicenseRegenerate(item?.id) }}
                                            >
                                                <SettingsBackupRestoreIcon /> {intl.formatMessage({ id: 'member.licenseCard.button.regenerate' })}
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                round
                                                table
                                                onClick={() => { handleMemberLicenseDownload(item?.downloadUrl) }}
                                            >
                                                <GetAppIcon /> {intl.formatMessage({ id: 'member.licenseCard.button.download' })}
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </IconCard>
                )}
            </Grid>

            <Grid item xs={12}>
                <Accordion style={{ backgroundColor: colors.warningBackground, marginTop: "20px", borderRadius: "16px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ color: '#FDA92D' }}>{intl.formatMessage({ id: 'member.licenseCard.history.title' })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {getLicenseHistory()}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid >
    )
}

export default injectIntl(LicenseCard);