import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST = gql`
query memberLicenseRequest($id: ID!) {
	memberLicenseRequest (id: $id) {
  	id
    bid
    status
    year
    price
    createdAt
    updatedAt
    summary {
      products {
        name
        unitPrice
        quantity
        price
      }
      total
    }
    club {
      id
      profile {
        name
      }
    }
    payment {
      id
      bid
      status
      purpose
      method
      amount
      description
      bankIBAN
      variableSymbol
      invoiceNumber
      payBySquare
      createdAt
      dueDate
      paidAt
    }
    licenses {
      processedAt
      hasCardRequest
      cardFileUrl
      member {
        id
        uciId
        licenses {
          downloadUrl
        }
        person {
          profile {
            firstName
            lastName
            birth
            personalId
          }
        }
      }
      function {
        id
        name
      }
      disciplines {
        discipline {
          id
          name
        }
        category {
          id
          name
          UCICategory
        }
      }
    }
  }
}
`;

export default MEMBER_LICENSE_REQUEST;