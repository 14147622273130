import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import USER_INVITE from 'queries/AuthQueries/userInvite' //TODO: Zatial nieje query
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";

const RegisterEmailPage = (props) => {
  const { history, intl } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [invite] = useMutation(USER_INVITE);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleRegister = (values) => {
    setDisableButton(true);
    invite({
      variables: {
        input: {
          password: values?.password,
          email: values?.email,
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      },
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'register.snackbar.success' }), { variant: 'success' })
      history.push({
        pathname: `/auth${generatePath(paths.auth.confirm)}`,
        state: {
          type: "register",
          email: values?.email,
          password: values?.password
        }
      })
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);

    });

  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => { handleRegister(values); }}
      initialValues={{}}
    >
      {(formikProps) => (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" style={{ color: colors.primary}}>Pozvánka - pre účely testovania</Typography>
              <br />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikField
                name="firstName"
                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikField
                name="lastName"
                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                highlightChange={false}
                name="email"
                labelText={intl.formatMessage({ id: 'register.email' })}
                formikProps={formikProps}
                validateParam={validateParam}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                highlightChange={false}
                name="birthDate"
                type="datePicker"
                labelText={intl.formatMessage({ id: 'register.birthDate' })}
                formikProps={formikProps}
                validateParam={validateParam}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                highlightChange={false}
                name="password"
                labelText={intl.formatMessage({ id: 'register.password' })}
                formikProps={formikProps}
                validateParam={validateParam}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                highlightChange={false}
                name="repeatPassword"
                labelText={intl.formatMessage({ id: 'register.repeatPassword' })}
                formikProps={formikProps}
                validateParam={validateParam}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                highlightChange={false}
                labelText={intl.formatMessage({ id: 'register.gdpr' })}
                type="checkbox"
                name="gdpr"
                formikProps={formikProps}
                validateParam={validateParam}
              />
            </Grid>
          </Grid>
          <Button
            color="primary"
            disabled={disableButton}
            size="sm"
            round
            onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
          >
            Pozvat
          </Button>
          <br />
        </>
      )}
    </Formik>
  );
};

export default injectIntl(withRouter(RegisterEmailPage));
