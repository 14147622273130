import gql from 'graphql-tag';

const PAYMENT = gql`
query payment($id: ID!) {
	payment (id: $id) {
    id
    bid
    status
    purpose
    method
    amount
    description
    bankIBAN
    variableSymbol
    invoiceNumber
    invoiceUrl
    payBySquare
    createdAt
    dueDate
    paidAt
    club {
      id
      profile {
        name
      }
    }
	}
}
`;

export default PAYMENT;