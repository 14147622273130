import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

// material-ui icons
import Menu from "@material-ui/icons/Menu";


// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  /*const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });*/
  return (
    <AppBar className={classes.appBar + appBarClasses} style={{
      width: '25px',
      // width: '100%',
    }}>
      <Toolbar className={classes.container}>
        {/*<Hidden smDown implementation="css">*/}
        {/*  <div className={sidebarMinimize}>*/}
        {/*    {props.miniActive ? (*/}
        {/*      <Button*/}
        {/*        justIcon*/}
        {/*        round*/}
        {/*        color="white"*/}
        {/*        onClick={props.sidebarMinimize}*/}
        {/*      >*/}
        {/*        <ViewList className={classes.sidebarMiniIcon} />*/}
        {/*      </Button>*/}
        {/*    ) : (*/}
        {/*      <Button*/}
        {/*        justIcon*/}
        {/*        round*/}
        {/*        color="white"*/}
        {/*        onClick={props.sidebarMinimize}*/}
        {/*      >*/}
        {/*        <MoreVert className={classes.sidebarMiniIcon} />*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</Hidden>*/}
        {/*<div className={classes.flex}>*/}
        {/*  /!* Here we create navbar brand, based on route name *!/*/}
        {/*  <Button href="#" className={classes.title} color="transparent">*/}
        {/*    {brandText}*/}
        {/*  </Button>*/}
        {/*</div>*/}
        {/*<Hidden smDown implementation="css">*/}
        {/*  <AdminNavbarLinks rtlActive={rtlActive} />*/}
        {/*</Hidden>*/}
        <div
          style={{
            width: '100%',
            display: 'flex', flexDirection: 'row',
            justifyContent: 'flex-end',
            // justifyContent: "space-between",
            // alignItems: "center",
            // // padding: theme.spacing(1, 2),
            // backgroundColor: "#000",
            // boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",  
          }}
        >
            {/* <Box
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {brandText}
            </Box> */}
          <Hidden mdUp implementation="css">
            <Button
                className={classes.appResponsive}
                paddingTop= '0px'
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
            >
              <Menu 
                style={{ 
                  color: "#A3B4C6", scale: "1.2"
                }}
              />
            </Button>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
