

export const getAPIUrl = (path) => {
    const { host, protocol } = window.location;

    if (process.env.NODE_ENV === 'development') {
        //return `http://192.168.0.15:4000/${path}`    
        // return `http://10.252.0.47:8080/${path}`    
        return `https://api.dev.szc.cultify.studio/${path}`
    } else
        return `${protocol}//api.${host}/${path}`
        // return `${protocol}//${host}/${path}`
}

export const getEchoDsAPIUrl = () => {
    const { host, protocol } = window.location;

    if (process.env.NODE_ENV === 'development')
        return 'https://api.dev.szc.cultify.studio/api/admin'
    else
        return `${protocol}//api.${host}/api/admin`
}

