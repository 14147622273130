import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { colors } from 'common/styles/configLayout';
import { connect } from 'react-redux';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import { getApolloClient } from "configFiles/apollo";
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import RoleEnum from 'common/enums/RoleEnum';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customTableCheckboxRadioSwitch.js";
import { useSnackbar } from 'notistack';

import CLUBS_CONFERENCE_ATTENDANCE_SET from 'queries/ClubsQueries/ClubsConferenceAttendanceSet';

import '../../assets/css/others.css'

const useStyles = makeStyles((theme) => styles(theme))
const useCheckboxStyles = makeStyles(checkboxStyles);

export const ClubTable = (props) => {
    const { 
        history, 
        setEdit, 
        role, 
        queryVariables, 
        query, 
        intl, 
        defaultFilter = null, 
        queryDataPath, 
        edit,
        setDisableButton } = props
    const classes = useStyles();
    const checkboxClasses = useCheckboxStyles();
    const [clubsConferenceAttendanceSet] = useMutation(CLUBS_CONFERENCE_ATTENDANCE_SET)

    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);
    const [selectedIds, setSelectedIds] = useState([])
    const [checkedClub, setCheckedClub] = useState(undefined);
    const [checkBoxFilters, setCheckBoxFilters] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const [loadData, { data }] = useLazyQuery(query);

    useEffect(() => {
        loadData();
    }, []);
    
    useEffect(() => {
        if (data) {            
            const selected = data.clubsTable.items
            .filter(item => item.hasAttendedConference)
            .map(item => item.id);

            setSelectedIds(selected);
        }
    }, [data]);

    const setChecked = (id, clubId) => {
        let currentIndex = selectedIds.indexOf((id));
        let newChecked = [...selectedIds]

        if (currentIndex === -1) {
            newChecked.push((id));
            if (selectedIds.length === 0) setCheckedClub(clubId);
        } else {
            newChecked.splice(currentIndex, 1);
            if (selectedIds.length === 1) setCheckedClub(undefined);
        }
        setSelectedIds(newChecked);
    }

    const isChecked = (id,) => {
        let help = null
        selectedIds.filter(buttonId => (buttonId) === (id)).map(item => (
            help = 1
        ))
        if (help === 1) return true
        else return false
    }

    const getClubMembersIds = async () => {
        try {
            let filter = {
                id: {
                    eq: checkedClub
                }
            };

            if (checkBoxFilters) {
                filter = {
                    ...filter,
                    ...checkBoxFilters
                };
            }

            const result = await client.query({
                query: query,
                variables: {
                    filter: filter,
                }
            });

            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let ids = [];
            (result?.data?.clubsTable?.items).map(item =>
                ids.push(item?.id))
            setSelectedIds(ids);

        } catch (error) {
            throw error;
        }
    }

    const handleSetAllSelectedIds = () => {
        getClubMembersIds();
    }

    const onClubDetail = (id) => {
        history.push(`/admin${generatePath(paths.clubs.detail, { clubId: id })}`);
    }

    const handleCheckBoxFilters = (filters) => {
        setCheckBoxFilters(filters)
    }

    const handleAttendance = async () => {

        if (selectedIds.length > 0) {
            // setDisableButton(true);
            clubsConferenceAttendanceSet({
              variables: {
                input: {
                    presentIds: selectedIds
                }
              }
            }).then((response) => {
              enqueueSnackbar("Dochádzka bola upravená", { variant: 'success' });
              setRefreshKey((prevKey) => prevKey + 1);
              setEdit(false)
              setDisableButton(false)
              // getLicensesNumber();
            //   history.push(`/admin${generatePath(paths.licenses.list)}`,);
            //   setDisableButton(false);
            }).catch((err) => {
              console.log('[Error]: ', err);
            //   setDisableButton(false);
            });
        } 
    }
    
    return (<>
        <QueryTable
            key={refreshKey}
            nameTable={paths.clubs.list}
            query={query}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            onCheckBoxFilters={handleCheckBoxFilters}
            columnsWidth={['50px', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '40px']}
            columns={[
                (role === RoleEnum.ADMIN) ? {
                    Header: '.',
                    accessor: (rowData) => {

                        return (
                        <div style={{ display: "inline" }}>
                        {( edit ) &&
                            <>
                                {(rowData) &&
                                    <Checkbox
                                        key={rowData.id}
                                        checked={isChecked(rowData.id)}
                                        tabIndex={-1}
                                        onClick={() => { setChecked(rowData.id, rowData.clubId) }}
                                        checkedIcon={<Check className={checkboxClasses.checkedIcon} />}
                                        icon={<Check className={checkboxClasses.uncheckedIcon} />}
                                        classes={{
                                            checked: checkboxClasses.checked,
                                            root: checkboxClasses.checkRoot
                                        }}
                                    />
                                }
                            </>
                        }
                    </div>)},
                    filterKey: "actions",
                    minWidth: '30px',
                    maxWidth: '100%',
                    checkbox: true,
                } : { Header: "." },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.businessName' }),
                    accessor: "businessName",
                    sortKey: "businessName",
                    filterKey: 'businessName',
                    filterOperator: 'contains',
                    minWidth: '157px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.team' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.ico' }),
                    accessor: "ico",
                    sortKey: "ico",
                    filterKey: 'ico',
                    filterOperator: 'contains',
                    minWidth: '75px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.statutory' }),
                    accessor: "statutory",
                    sortKey: "statutory",
                    filterKey: 'statutory',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.president' }),
                    accessor: "president",
                    sortKey: "president",
                    filterKey: 'president',
                    filterOperator: 'contains',
                    minWidth: '107px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.license' }),
                    accessor: (rowData) => (rowData.license)
                        ? <Box style={{ color: (rowData.license >= licensingInfoData?.licensingInfo?.periods?.current?.year) ? colors.success : '' }} > {rowData.license} </Box>
                        : "-",
                    sortKey: "license",
                    filterKey: 'license',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '100px',
                    maxWidth: '120px'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.hasAttendedConference' }),
                    accessor: (rowData) => {
                        return rowData.hasAttendedConference !== undefined
                            ? (
                                <Box style={{ color: rowData.hasAttendedConference ? colors.success : colors.error }}>
                                    {rowData.hasAttendedConference ? "✓" : "✗"}
                                </Box>
                            )
                            : "-";
                    },
                    sortKey: 'hasAttendedConference',
                    filterKey: 'hasAttendedConference',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: true,
                            label: "Zúčastnený"
                        },
                        {
                            value: false,
                            label: "Nezúčastnený"
                        },
                    ],
                    minWidth: '110px',
                    maxWidth: '100%'
                },
                {
                    Header: ' ',
                    accessor: (rowData) => <Box className={classes.floatRight}><Button round table size="sm" color="primary"
                        onClick={() => onClubDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}</Button></Box>
                }
            ]}
        />
        {( edit ) &&
            <Box
                className='checkBoxBtnContainer'
                style={{ 
                    zIndex: 999, padding: "5px", 
                    position: "fixed", bottom: "5px", 
                }}
                display="flex"
                alignItems="center"
            >
                <Button
                    color="primary"
                    round
                    table
                    onClick={() => handleAttendance()}
                    size="sm"
                >
                    {intl.formatMessage({ id: 'club.editAttendance.button' })}
                    {/* {<>
                        ({selectedIds.length} {
                            (selectedIds.length > 4)
                                ? intl.formatMessage({ id: "member.store3" })
                                : (selectedIds.length === 1)
                                    ? intl.formatMessage({ id: "member.store1" })
                                    : intl.formatMessage({ id: "member.store2" })

                        })</>
                    } */}
                </Button>

                <Button
                    color="primary"
                    round
                    table
                    size="sm"
                    onClick={() => handleSetAllSelectedIds()}
                >
                    {intl.formatMessage({ id: "clubs.button.checkAll" })}
                </Button>

                <Button
                    color="danger"
                    size="sm"
                    round
                    table
                    onClick={() => { setSelectedIds([]); setCheckedClub(undefined) }}
                >
                    {intl.formatMessage({ id: "clubs.button.uncheckAll" })} ({selectedIds.length})
                </Button>

                <Button
                    color="primary"
                    round
                    table
                    size="sm"
                    onClick={() => {
                        setEdit(false);
                        setDisableButton(false)
                    }}
                >
                    {intl.formatMessage({ id: "clubs.button.cancel" })}
                </Button>

            </Box>
        }
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(ClubTable));
