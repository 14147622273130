import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DocumentsCard from '../Clubs/ClubCards/DocumentsCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box";
import { variables } from 'common/styles/configLayout';
import ClubCard from '../Clubs/ClubCards/ClubCard';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LegalEntityCard from '../Clubs/ClubCards/LegalEntityCard';
import PresidentCard from '../Clubs/ClubCards/PresidentCard';
import FormikField from "components-lib/FormikField/FormikField";
import { getValidationSchemaRequestUpdate, } from "./validationSchema";
import Modal from "components-lib/Modal/Modal";
import ProfileDocumentsEnum from 'common/enums/ProfileDocumentsEnum';
import CLUB_REGISTER_REQUEST_CREATE from 'queries/RequestsQueries/clubRegisterRequestCreate';
import CLUB_UPDATE_REQUEST_CREATE from 'queries/RequestsQueries/clubUpdateRequestCreate';
import { generatePath } from "react-router-dom";
import paths from "paths";
import { useLocation } from 'react-router-dom';
import ControlRequestDataCard from './RequestCards/ControlRequestDataCard';
import { changePendingRequestsNumberAction } from 'redux/actions';
import { connect } from 'react-redux';
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import { getApolloClient } from "configFiles/apollo";
import ACTION_INVITATIONS from 'queries/AuthQueries/actionInvitations';
import CheckIcon from '@material-ui/icons/Check';
import RequestStatusesEnum from 'common/enums/RequestStatusesEnum';
import PROFILE from 'queries/ProfileQueries/profile';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const RequestNewPage = (props) => {
    const { intl, history, role, changePendingRequestsNumberAction } = props;
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { data: userProfile } = useQuery(PROFILE);
    const [view, setView] = useState(0);
    const [validateParam, setValidateParam] = useState(false)
    const [initialValues, setInitialValues] = useState(location.state ? location.state : {});
    const [formOptions, setFormOptions] = useState({})
    const [rows, setRows] = useState([])
    const [removeDocuments, setRemoveDocuments] = useState([])
    const [approveDocuments, setApproveDocuments] = useState([])
    const [rejectDocuments, setRejectDocuments] = useState([])
    const [photo, setPhoto] = useState(null)
    const [approveSubmit, setApproveSubmit] = useState(null)
    const [createModal, setCreateModal] = useState(false)
    const [isYouthWorker, setIsYouthWorker] = useState(null)
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [disableButton, setDisableButton] = useState(false);

    const [clubRegisterRequestCreate] = useMutation(CLUB_REGISTER_REQUEST_CREATE)
    const [clubUpdateRequestCreate] = useMutation(CLUB_UPDATE_REQUEST_CREATE)
    const { loading: actionInvitationsLoading, data: actionInvitationsData } = useQuery(ACTION_INVITATIONS, { skip: role !== RoleEnum.USER });

    let cantDocumentsUpload = Object.values(rows).find(item => item.status === "Empty" || item.status === "Removed")

    useEffect(() => {
        setIsYouthWorker(Object.values((pathOr('', ['actionInvitations', 'items'], actionInvitationsData))).find(item => item.type === RequestTypesEnum.CLUB_REGISTRATION && item.usedAt === null)?.data?.isYouthWorker)
    }, [actionInvitationsData]);

    useEffect(() => {
        setView(0)
        let options = [];
        options = Object.values(ProfileDocumentsEnum).map((item) => (
            {
                value: item,
                label: item
            }
        ));
        setFormOptions(options);
    }, []);


    useEffect(() => {
        let profileData = location?.state?.club?.profile;
        let stateData = location?.state
        if (location?.state) {
            //let findStatutory =  location?.state?.club?.members?.[0]?.functions;
            let president = Object.values(pathOr('', ['club', 'members'], stateData)).map((item, idx) => {
                if (Object.values(pathOr('', ['club', 'members', [idx], 'functions'], stateData)).find(i => i.function.id === "1")) {
                    let profile = pathOr('', ['club', 'members', [idx], 'person', 'profile'], stateData)
                    return ({
                        statutoryId: pathOr('', ['club', 'members', [idx], 'person', 'id'], stateData),
                        statutoryEmail: profile?.email,
                        statutoryFirstName: profile?.firstName,
                        statutoryLastName: profile?.lastName,
                        statutoryPhone: profile?.phone,
                        statutoryAddress1: profile?.address1,
                        statutoryAddress2: profile?.address2,
                        statutoryCity: profile?.city,
                        statutoryPostal: profile?.postal,
                        statutoryCountry: profile?.country
                    })
                }
            });




            setInitialValues({
                bid: location?.state?.club?.bid,
                ...profileData,
                ...president?.[0]
            })
        }
        //DOCUMENTS START
        let documents = [];
        let allDocuments = location.state?.club?.documents;
        let allTypes = ["CompanyRegistrationStatement", "ICOAssignmentConfirmation", "DICRegistrationStatement", "MinuteOfTheInauguralMeeting"]
        let allTypesUpdate = ["CompanyRegistrationStatement"]

        if (!location?.state)
            for (var i = 0; i < 4; i++) {
                documents.push({ status: "Empty", type: allTypes[i], validFrom: null, validTo: null, files: [], checked: true })
            }
        setRows(documents)
        // DOCUMENTS END

        let statutory = {
            useCorrespondenceAddress: true,
            statutoryEmail: pathOr('', ['me', 'person', 'profile', 'email'], userProfile),
            statutoryFirstName: pathOr('', ['me', 'person', 'profile', 'firstName'], userProfile),
            statutoryLastName: pathOr('', ['me', 'person', 'profile', 'lastName'], userProfile),
            statutoryPhone: pathOr('', ['me', 'person', 'profile', 'phone'], userProfile),
            statutoryAddress1: pathOr('', ['me', 'person', 'profile', 'address1'], userProfile),
            statutoryAddress2: pathOr('', ['me', 'person', 'profile', 'address2'], userProfile),
            statutoryCity: pathOr('', ['me', 'person', 'profile', 'city'], userProfile),
            statutoryDistrict: pathOr('', ['me', 'person', 'profile', 'district'], userProfile),
            statutoryPostal: pathOr('', ['me', 'person', 'profile', 'postal'], userProfile),
            statutoryCountry: pathOr('', ['me', 'person', 'profile', 'country'], userProfile),
        }

        if (!location?.state) {
            if (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT || role === RoleEnum.USER)
                setInitialValues({
                    ...statutory,
                    country: variables.defaultCountry,
                    isYouthWorker: isYouthWorker
                })
            else setInitialValues({});
        }
    }, [userProfile]);

    const getPendingRequestsNumber = async () => {
        try {
            const result = await client.query({
                query: REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": (role === RoleEnum.ADMIN) ? RequestStatusesEnum.AWAITING : RequestStatusesEnum.RETURNED
                            },
                            "type": {
                                "notIn": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changePendingRequestsNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
        } catch (error) {
            throw error;
        }
    }

    const getResult = (values) => {
        const result = { ...values };
        delete result.__typename;
        delete result.gdpr;
        delete result.logoUrl
        delete result.statutoryAddress1;
        delete result.statutoryAddress2;
        delete result.statutoryCity;
        delete result.statutoryCountry;
        delete result.statutoryEmail;
        delete result.statutoryFirstName;
        delete result.statutoryLastName;
        delete result.statutoryPhone;
        delete result.statutoryPostal;
        delete result.statutoryDistrict;
        delete result.correspondenceAddress;
        delete result.description;
        delete result.statutoryId;
        delete result.type;
        delete result.nationality;
        delete result.isYouthWorker;
        delete result.bid;
        return result;
    };

    const getDocuments = () => {
        let documents
        documents = rows?.filter(i => i.status === "Uploaded").map((item) => (
            {
                validFrom: (item?.validFrom) ? (item?.validFrom) : undefined,
                validTo: (item?.validTo) ? (item?.validTo) : undefined,
                type: (item?.type),
                files: (item?.files)?.map(item => item.file)
            }
        ));
        return documents;
    }


    const handleClubUpdateRequestCreate = (values) => {
        const result = getResult(values)
        let documents = getDocuments();
        setDisableButton(true);
        clubUpdateRequestCreate({
            variables: {
                clubId: location.state?.club?.id,
                input: {
                    profile: {
                        ...result,
                        logo: (photo) ? photo : undefined,
                    },

                    documents: {
                        upload: documents,
                        removeIds: (removeDocuments.length > 0) ? removeDocuments : undefined,
                        approveIds: (approveDocuments.length > 0) ? approveDocuments : undefined,
                        rejectIds: (rejectDocuments.length > 0) ? rejectDocuments : undefined,
                    },
                }
            }
        }).then((response) => {
            history.push(`/admin${generatePath(paths.requests.detail, { requestId: response?.data?.clubUpdateRequestCreate?.id })}`);
            enqueueSnackbar("Vaša žiadosť o zmenu klubových údajov bola odoslaná na schválenie", { variant: 'success' });
            getPendingRequestsNumber();
            setDisableButton(false)
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false)
        });
        setCreateModal(false)
    }

    const handleClubRegisterRequestCreate = (values) => {
        const result = getResult(values)
        let documents = getDocuments()
        if (cantDocumentsUpload)
            enqueueSnackbar("Nemáte nahraté všetky povinné dokumenty", { variant: 'warning' });
        else {
            setDisableButton(true);
            clubRegisterRequestCreate({
                variables: {
                    input: {
                        profile: {
                            ...result,
                            logo: (photo) ? photo : undefined,
                        },
                        documents: {
                            upload: documents,
                            removeIds: (removeDocuments.length > 0) ? removeDocuments : undefined,
                            approveIds: (approveDocuments.length > 0) ? approveDocuments : undefined,
                            rejectIds: (rejectDocuments.length > 0) ? rejectDocuments : undefined,
                        },
                        functionary: {
                            isYouthWorker: (values?.isYouthWorker) ? true : false
                        }
                    }
                }
            }).then((response) => {
                history.push(`/admin${generatePath(paths.requests.detail, { requestId: response?.data?.clubRegisterRequestCreate?.id })}`);
                enqueueSnackbar("Vaša žiadosť o registrovanie klubu bola odoslaná na schválenie", { variant: 'success' });
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
        setCreateModal(false)
    }


    const renderCreateModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => {
                        (location?.state)
                            ? handleClubUpdateRequestCreate(formikProps?.values)
                            : handleClubRegisterRequestCreate(formikProps?.values)
                    }}
                    color="success"
                    round
                    size="sm"
                >
                    <CheckIcon /> Odoslať žiadosť
                </Button>
            </>
        );
    };


    const getTitle = () => {
        return (location?.state) ? <Box>Žiadosť o zmenu údajov klubu</Box> : <Box>{intl.formatMessage({ id: 'club.request.title.new' })}</Box>
    }

    if (actionInvitationsLoading) return <Loading />;
    return (<>
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={getValidationSchemaRequestUpdate(intl)}
            onSubmit={(values) => {
                (location?.state)
                    ? handleClubUpdateRequestCreate(values)
                    : handleClubRegisterRequestCreate(values)
            }}
            initialValues={{ ...initialValues, isYouthWorker: isYouthWorker }}
        >
            {(formikProps) => (<>
                <PageHeader
                    title={getTitle()}
                //subTitle={pathOr('', ['request', 'bid'], dataRequest)}
                />
                <Grid container spacing={1} >
                    <Grid item xs={12} lg={8}>
                        {/*<IconCard
                            title={getTitle()}
                            background={getBackgroundColor()}
                            inside
            >*/}

                        <Grid item xs={12}>
                            <ClubCard
                                inside
                                callbackPhoto={(newValue) => setPhoto(newValue)}
                                photo={location.state?.club?.profile?.logoUrl}
                                setPhoto={(newValue) => setPhoto(newValue)}
                                refetch={false}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                request={true}
                                clubRequest={true}
                                readMode={view === 1 ? true : false}
                                highlightChange={(!location?.state) ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LegalEntityCard
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                validateParam={validateParam}
                                request={true}
                                readMode={view === 1 ? true : false}
                                options={formOptions}
                                highlightChange={(!location?.state) ? false : true}
                            />
                        </Grid>
                        {(!location?.state) &&
                            < Grid item xs={12}>
                                <PresidentCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    isYouthWorker={(role === RoleEnum.ADMIN) ? false : (!location?.state) ? false : true}
                                    newCompany={true}
                                    highlightChange={false}
                                    validateParam={validateParam}
                                    readMode={(role === RoleEnum.ADMIN) ? false : true}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <DocumentsCard
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                validateParam={validateParam}
                                detail={false}
                                closed={false}
                                callbackRows={(newValue) => setRows(newValue)}
                                rows={rows}
                                removeDocuments={removeDocuments}
                                approveDocuments={approveDocuments}
                                rejectDocuments={rejectDocuments}
                                callbackRemoveDocuments={(newValue) => setRemoveDocuments(newValue)}
                                callbackApproveDocuments={(newValue) => setApproveDocuments(newValue)}
                                callbackRejectDocuments={(newValue) => setRejectDocuments(newValue)}
                                role={role}
                                status={"New"}
                                request={true}
                                updateClub={(location?.state)}
                            />
                        </Grid>
                        <Grid container spacing={1} justify="center">
                            {view === 3 ?
                                <Grid item xs={12}>
                                    <FormikField
                                        labelText={intl.formatMessage({ id: 'club.form.gdpr' })}
                                        type="checkbox"
                                        name="gdpr"
                                        fullWidth={false}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        closed={view === 1 ? true : false}
                                        highlightChange={false}
                                    />
                                </Grid>
                                : <Grid item xs={12}></Grid>

                            }
                            <Grid item sm={12}></Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        disabled={disableButton}
                                        round
                                        onClick={() => { setValidateParam(true); (formikProps?.isValid) && (role === RoleEnum.ADMIN) && setApproveSubmit(true); formikProps.handleSubmit() }}
                                    >
                                        {(role === RoleEnum.ADMIN)
                                            ? <><DirectionsBikeIcon /> Vytvoriť klub</>
                                            : <><CheckIcon /> {intl.formatMessage({ id: 'club.button.sendRequest' })}</>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>

                            <Modal
                                title={"Pred odoslaním žiadosti na schválenie si prosím skontrolujte povinné údaje!."}
                                open={createModal}
                                maxWidth={"md"}
                                onClose={() => setCreateModal(false)}
                                actions={renderCreateModalButtons(formikProps)}
                                fullWidth
                            >
                                <ControlRequestDataCard
                                    formikProps={formikProps}
                                />
                            </Modal>
                        </Grid>
                        {/*</IconCard>*/}
                    </Grid>
                </Grid>
            </>)
            }
        </Formik >
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});
const mapDispatchToProps = (dispatch) => ({
    changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestNewPage));