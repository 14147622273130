import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import RemoveIcon from '@material-ui/icons/Remove';
import { colors } from 'common/styles/configLayout';
import Typography from "@material-ui/core/Typography"
import Grid from '@material-ui/core/Grid';
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import MEMBER_LICENSE_CARD_REQUEST_SUMMARY from "queries/LicensesQueries/memberLicenseCardRequestSummary";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useStyles from 'components-lib/FormikField/styles'
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import _ from 'lodash';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';

const useStylesIcon = makeStyles((theme) => styles(theme));

export const LicenseNewCardTable = (props) => {
    const { intl, history, setCardSummary, callbackRows, rows, clubId, clubMembers, initialFunctions } = props

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const classesIcon = useStylesIcon();
    const [selectedClubMembers, setSelectedClubMembers] = useState([...clubMembers])
    const [disabledFunctions, setDisabledFunctions] = useState([])
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const getLicenseCardSummary = async (rows) => {

        const licenseIds = rows.map((row) => row.clubMember?.value);

        if (rows.length > 0) {
            try {
                const result = await client.query({
                    query: MEMBER_LICENSE_CARD_REQUEST_SUMMARY,
                    variables: {
                        input: {
                            clubId: clubId,
                            licenseIds: licenseIds
                        }
                    }
                });
                
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                setCardSummary(result?.data?.memberLicenseCardRequestSummary)
            } catch (error) {
                throw error;
            }
        }
    }

    useEffect(() => {
        setSelectedClubMembers([...clubMembers])
    }, [clubMembers]);

    useEffect(() => {
        let tempClubMembers = [...clubMembers]
        let tempIdClubMembers = [];
        let tempDisabledFunctions = [];
        for (let i = 0; i < rows?.length; i++) {
            clubMembers.findIndex(item => (item?.value === rows?.[i]?.clubMember?.value))
            tempClubMembers.splice(tempIdClubMembers, 1)
        }
        setSelectedClubMembers(tempClubMembers)

        for (let i = 0; i < initialFunctions?.length; i++) {
            tempDisabledFunctions.push({ functionIds: [], categoryIds: [] })
        }
        setDisabledFunctions(tempDisabledFunctions)

    }, [initialFunctions]);

    const handleChangeTo = (idx, value, item, fIdx) => {
        let row = [...rows];

        let disFunctions = [...disabledFunctions];
        if (item === "clubMember") {
            row[idx] = {
                clubMember: value,
            };
        }
        let licenseId = value?.value
        callbackRows(row);
        getLicenseCardSummary(row)
    };

    const handleRemoveSpecificRow = (idx, value) => {
        let row = [...rows]
        let tempFindClubMember = clubMembers?.find(item => (item?.value === rows?.[idx]?.clubMember?.value))

        setSelectedClubMembers([...selectedClubMembers, tempFindClubMember])

        row = rows.splice(idx, 1);

        let final = [...rows]
        getLicenseCardSummary(row)

        callbackRows(final);
    }

    const handleAddRow = () => {
        const row = [...rows, {}];

        callbackRows(row);
    };

    const getMembersOptions = (allMembers, myMembers) => {

        let xorBy = _.xorBy(allMembers, myMembers, (d) => d.value)
        xorBy.splice(xorBy?.findIndex(item => item === ''), 1)
        return xorBy
    }

    const getRemainingOptions = (allMembers, selectedMembers) => {
        return allMembers.filter(member =>
            !selectedMembers.some(selected => selected?.value === member.value)
        );
    };
    
    const remainingOptions = getRemainingOptions(clubMembers, rows.map(i => i?.clubMember || {}));
    
    return (
        <>
            <Grid container spacing={1}>
                {
                    <Grid item xs={12}  >
                        {Object.values(rows)?.map((item, idx) => (
                            <>
                            <Grid item >
                                <>
                                    <Grid container>
                                        <Grid item xs={12} md={11}>
                                            <Grid container spacing={0} style={{ backgroundColor: ((item?.clubMember?.disabled) ? colors.errorBackground : ''), borderRadius: '8px', padding: '3px' }} >

                                                <Grid item xs={12} md={2}>
                                                    {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) &&
                                                        <>
                                                            {item?.clubMember ? <>
                                                                <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                    <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Člen klubu</Typography>
                                                                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                                        {(item?.clubMember?.disabled)
                                                                            ? <><ErrorIcon className={classesIcon.specialCircle2} style={{ color: colors.error }} />{(item?.clubMember?.label)?.substr(0, (item?.clubMember?.label).indexOf('('))} </>
                                                                            : <>{(item?.clubMember?.label)?.substr(0, (item?.clubMember?.label).indexOf(':'))}</>
                                                                        }
                                                                    </Typography>

                                                                </Box>
                                                            </>
                                                                :
                                                                <Autocomplete
                                                                    style={{ borderRadius: '6px', width: '125px' }}
                                                                    className={classes.root}
                                                                    classes={{
                                                                        paper: classes.selectAutocomplete,
                                                                        clearIndicatorDirty: classes.selectIcon,
                                                                        popupIndicator: classes.selectIcon
                                                                    }}
                                                                    disabled={false}
                                                                    size="small"
                                                                    id="combo-box-demo"
                                                                    noOptionsText={'Žiadne možnosti'}
                                                                    options={getMembersOptions(clubMembers, rows?.map(i => (i?.clubMember) ? (i.clubMember) : []))}
                                                                    disableClearable
                                                                    filterSelectedOptions
                                                                    fullWidth
                                                                    getOptionLabel={(option) => option.label || ""}
                                                                    renderInput={(params) => (
                                                                        <TextField 
                                                                            {...params} 
                                                                            label={"Člen klubu"} 
                                                                            variant="outlined"                                                                             
                                                                        />
                                                                    )}
                                                                    value={item?.clubMember}
                                                                    onChange={(event, value) => { handleChangeTo(idx, value, 'clubMember') }}
                                                                    variant="outlined"
                                                                    defaultValue="Success"
                                                                    renderOption={(option) => (<div>
                                                                        {option?.disabled
                                                                            ? <p><ErrorIcon className={classesIcon.listCircle} style={{ color: colors.error }} /> {option.label} (Chýbajucé dokumenty)</p>
                                                                            : <p>{option.label}</p>}
                                                                    </div>
                                                                    )}

                                                                    inputProps={{
                                                                        classes: {
                                                                            root: classes.label,
                                                                        },
                                                                    }}
                                                                    name={"clubMember"}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                                {(item?.clubMember?.disabled) ?
                                                    <></>
                                                    : <>
                                                        <Grid item xs={6} md={2} style={{ minWidth: 'max-content', width: 'fit-content', wordWrap: 'initial' }}>
                                                            {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                                {item?.clubMember && <>
                                                                    <Box style={{ margin: "1px", }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>UCI ID</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.clubMember?.uciId}</Typography>
                                                                    </Box>
                                                                </>
                                                                }
                                                            </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                                {item?.clubMember && <>
                                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Dátum narodenia</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.clubMember?.birth}</Typography>
                                                                    </Box>
                                                                </>
                                                                }
                                                            </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                                {item?.clubMember && <>
                                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Funkcia</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.clubMember?.function}</Typography>
                                                                    </Box>
                                                                </>
                                                                }
                                                            </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            {(idx === 0 || item?.clubMember !== rows[idx - 1]?.clubMember) && <>
                                                                {item?.clubMember && <>
                                                                    <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                                                                        <Typography variant="body2" style={{ fontSize: "0.6em", color: colors.labelAndBorderInputs }}>Disciplína</Typography>
                                                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>{item?.clubMember?.discipline}</Typography>
                                                                    </Box>
                                                                </>
                                                                }
                                                            </>
                                                            }
                                                        </Grid>
                                                    </>}
                                            </Grid>
                                        </Grid>
                                    <Grid item xs={12} md={1}>
                                        {(item?.clubMember || idx > 0) && <>
                                            <div style={{ whiteSpace: 'nowrap', textAlign: 'right', margin: '8px 0' }}>
                                                <Tooltip title="Odobrať riadok">
                                                    <RemoveIcon
                                                        className={classesIcon.circle}
                                                        onClick={() => handleRemoveSpecificRow(idx)}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </>
                                        }
                                    </Grid>
                                </Grid>
                                    </>
                            </Grid>
                        </>
                        ))}
                    </Grid>
                }
            </Grid>
            {
                (clubMembers.length > 0 ) &&
                <Box style={{ marginTop: '5px' }}>
                    <Button
                        // disabled={(remainingOptions.length < 1) ? true : false}
                        disabled={ Object.values(rows)?.some((item) => !item?.clubMember) || (remainingOptions.length < 1) }
                        align="center"
                        color="primary"
                        size="sm"
                        round
                        table
                        onClick={handleAddRow}
                    >
                        <PersonAddIcon /> Pridať osobu
                    </Button>
                </Box>
            }
        </>
    )
}

export default injectIntl(LicenseNewCardTable);