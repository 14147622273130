import gql from 'graphql-tag';

const CLUBS = gql`
query clubs($offset: Int, $limit: Int, $sort: [ClubSortInput!], $filter: [ClubFilterInput]) {
  clubs (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
       	id
        bid
        members {
          licenses {
            year
          }
        }
        chairman{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        statutory{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        profile{
          name
        }
        licenses {
          current {
            isLicensed
          }
          next {
            isLicensed
          }
        }
      }
  }
}
`;

export default CLUBS;