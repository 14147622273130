import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import getProjectRoutes from "routes.js";
import { withRouter } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { injectIntl } from "react-intl";
import { isInRoles } from '../helpers/helpers';
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { connect } from 'react-redux';
import {
  changeUserRoleAction,
  changeAllUserRolesAction,
  changeUserProfileAction,
  changeLastUrlAction,
  changePendingRequestsNumberAction,
  changePendingTransfersAndHostingNumberAction,
  changeLicensesNumberAction,
  changeDocumentsNumberAction,
  changeLocation
} from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'rambda';
import Loading from "components-lib/Loading/Loading";
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import { DOCUMENTS_COUNT,MEMBER_LICENSE_REQUESTS_COUNT } from 'queries/GenerallyQueries/counts';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import RoleEnum from "common/enums/RoleEnum";
import RequestTypesEnum from 'common/enums/RequestTypesEnum';
import COUNTRIES from "queries/GenerallyQueries/countries";
import DISTRICTS from "queries/GenerallyQueries/districts";
import CITIES from "queries/GenerallyQueries/cities";

import { Divider, Box } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";


var ps;

const useStyles = makeStyles(styles);

const Admin = (props) => {
  const { intl,
    history,
    changeUserRoleAction,
    changeAllUserRolesAction,
    changeUserProfileAction,
    changePendingRequestsNumberAction,
    changePendingTransfersAndHostingNumberAction,
    changeLastUrlAction,
    changeLicensesNumberAction,
    changeDocumentsNumberAction,
    role,
    districts,
    lastUrl,
    licensesNumber,
    documentsNumber,
    pendingRequestsNumber,
    pendingTransfersAndHostingNumber,
    changeLocation,
    ...rest } = props;
  // states and functions
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const { loading: profileLoading, data: profileData } = useQuery(PROFILE);
  const { data: countriesData } = useQuery(COUNTRIES, {
    variables: {
      sort: [{
        field: "order",
        order: "ASC"
      }]
    }
  });
  const { data: districtsData } = useQuery(DISTRICTS);
  const { data: citiesData } = useQuery(CITIES);

  //const { loading: requestsTableLoading, data: requestsTableData } = useQuery(REQUESTS);
  //const { loading: licensesTableLoading, data: licensesTableData } = useQuery(MEMBER_LICENSE_REQUESTS_TABLE, { skip: !role && role !== RoleEnum.ADMIN });
  //const { loading: documentsTableLoading, data: documentsTableData } = useQuery(DOCUMENTS_TABLE, { skip: role !== RoleEnum.ADMIN });

  //let image = require("assets/img/sidebar-2.jpg");
  let bgColor = "black";
  //let logo = require("assets/img/logo-color.svg")
  let color = "blue";

  useEffect(() => {
    let countries = pathOr([], "countries.items", countriesData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });

    let districts = pathOr([], "districts.items", districtsData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });
    let cities = pathOr([], "cities.items", citiesData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });
    changeLocation(countries, districts, cities);
  }, [countriesData, districtsData, citiesData]);


  const getPendingRequestsNumber = async () => {
    try {
      const result = await client.query({
        query: REQUESTS_TABLE,
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      let pendingRequests;
      if (role === RoleEnum.ADMIN)
        pendingRequests = (Object.values(result?.data?.requestsTable?.items).filter(item => (item.status === "Awaiting" && (item.type !== RequestTypesEnum.CLUB_TRANSFER && item.type !== RequestTypesEnum.CLUB_HOSTING)))?.map((x, index) => { return index })?.length);
      else
        pendingRequests = (Object.values(result?.data?.requestsTable?.items).filter(item => (item.status === "Returned" && (item.type !== RequestTypesEnum.CLUB_TRANSFER && item.type !== RequestTypesEnum.CLUB_HOSTING)))?.map((x, index) => { return index })?.length);
      changePendingRequestsNumberAction(pendingRequests)
      let pendingTransfersAndHosting = (Object.values(result?.data?.requestsTable?.items).filter(item => ((item.status === "Awaiting" || item.status === "Paid") && (item.type === RequestTypesEnum.CLUB_TRANSFER || item.type === RequestTypesEnum.CLUB_HOSTING)))?.map((x, index) => { return index })?.length);
      changePendingTransfersAndHostingNumberAction(pendingTransfersAndHosting)

    } catch (error) {
      throw error;
    }
  };

  const getLicensesNumber = async () => {
    try {
      const result = await client.query({
        query: MEMBER_LICENSE_REQUESTS_COUNT,
        variables: {
          "filter": [
            {
              "status": {
                "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
              }
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }

  const getDocumentsNumber = async () => {
    try {
      const result = await client.query({
        query: DOCUMENTS_COUNT,
        variables: {
          "filter": [
            {
              "status": {
                "eq": "Awaiting"
              },
              "relation": {
                "ne": "Request"
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }


  useEffect(() => {
    if (!profileLoading) {
      let tempAllRoles = [];
      let actualRole = null;
      if ((pathOr('', ['me', 'isAdmin'], profileData)))
        tempAllRoles.push(RoleEnum.ADMIN)
      else {
        if (((pathOr('', ['me', 'member', 'functions'], profileData))?.length !== 0)) {
          tempAllRoles = Object.values(pathOr('', ['me', 'member', 'functions'], profileData))?.map(item => {
            if (item?.function?.id === "1") return RoleEnum.STATUTORY
            else if (item?.function?.id === "2") return RoleEnum.PRESIDENT
            else return RoleEnum.MEMBER
          });
        } else {
          if (!pathOr('', ['me', 'isAdmin'], profileData))
            tempAllRoles = [RoleEnum.USER]
        }
      }

      let uniqueAllRoles = tempAllRoles.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      })



      if (uniqueAllRoles?.find(item => item === RoleEnum.ADMIN)) {
        actualRole = RoleEnum.ADMIN
      } else {
        if (uniqueAllRoles?.find(item => item === RoleEnum.STATUTORY)) {
          actualRole = RoleEnum.STATUTORY
        } else {
          if (uniqueAllRoles?.find(item => item === RoleEnum.PRESIDENT)) {
            actualRole = RoleEnum.PRESIDENT
          } else {
            if (uniqueAllRoles?.find(item => item === RoleEnum.MEMBER)) {
              actualRole = RoleEnum.MEMBER
            } else {
              if (uniqueAllRoles?.find(item => item === RoleEnum.USER)) {
                actualRole = RoleEnum.USER
              }
            }
          }
        }
      }

      let includes = uniqueAllRoles.includes(RoleEnum.STATUTORY && RoleEnum.PRESIDENT)
      let presidentId;
      if (includes)
        presidentId = uniqueAllRoles?.findIndex(item => (item === RoleEnum.PRESIDENT))

      uniqueAllRoles.map((item, idx) => {
        if (includes)
          uniqueAllRoles.splice(presidentId, 1)
        if (item === actualRole)
          uniqueAllRoles.splice(idx, 1)
      });

      changeUserRoleAction(actualRole);
      changeAllUserRolesAction(uniqueAllRoles);
      changeUserProfileAction(profileData)
    }
  }, [profileData]);


  useEffect(() => {
    if (role === RoleEnum.ADMIN || role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) {
      getPendingRequestsNumber();
      getLicensesNumber();
    }

    if (role === RoleEnum.ADMIN)
      getDocumentsNumber();

    /*if (!requestsTableLoading) {
      let pendingRequests;
      if (role === RoleEnum.ADMIN)
        pendingRequests = (Object.values(pathOr('', ['requests', 'items'], requestsTableData)).filter(item => item.status === "Awaiting")?.map((x, index) => { return index })?.length);
      else
        pendingRequests = (Object.values(pathOr('', ['requests', 'items'], requestsTableData)).filter(item => item.status === "Returned")?.map((x, index) => { return index })?.length);
 
      changePendingRequestsNumberAction(pendingRequests);
    }*/
  }, [role]);


  /*useEffect(() => {
    if (!licensesTableLoading) {
      let pendingLicenses;
      if (role === RoleEnum.ADMIN) {
        pendingLicenses = (Object.values(pathOr('', ['memberLicenseRequestsTable', 'items'], licensesTableData)).filter(item => (item.status === "Ready" || item.status === "Downloaded"))?.map((x, index) => { return index })?.length);
      }
      else {
        pendingLicenses = (Object.values(pathOr('', ['memberLicenseRequestsTable', 'items'], licensesTableData)).filter(item => (item.status === "Pending"))?.map((x, index) => { return index })?.length);
      }
      changeLicensesNumberAction(pendingLicenses);
 
    }
  }, [role]);
 
  useEffect(() => {
    if (!documentsTableLoading) {
      let pendingDocuments = (Object.values(pathOr('', ['documentsTable', 'items'], documentsTableData)).filter(item => (item.status === "Awaiting"))?.map((x, index) => { return index })?.length);
      changeDocumentsNumberAction(pendingDocuments);
    }
  }, [role]);*/



  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const routes = getProjectRoutes(intl, role, pendingRequestsNumber, licensesNumber, documentsNumber, pendingTransfersAndHostingNumber);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = routes => {
    let activeRoute = "Cyklopas";
    for (let i = 0; i < routes.length; i++) {
      if ( window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1 ) {
        return routes[i].name;
      } 
      else if (window.location.pathname.startsWith(routes[i].layout + routes[i].path.split("/:")[0])) {
        return routes[i].name;
      }      
    }
    return activeRoute;
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.forRoles && !isInRoles(prop.forRoles, role)) {
        return null;
      }
      if (prop.exceptRoles && isInRoles(prop.exceptRoles, role)) {
        return null;
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  // if (profileLoading || requestsTableLoading || licensesTableLoading) return <Loading />;
  return (
    <div className={classes.wrapper}>
      {//role ?
        <Sidebar
          routes={routes}
          logoText={"Creative Tim"}
          //logo={logo}
          //image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          // handleDrawerClose={handleDrawerClose}
          {...rest}
        />
        // : <Loading />
      }
      <div className={mainPanelClasses} ref={mainPanel}>
        {//role ?
        <div style={{
          width: '100%',
          display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center',
          paddingTop: '6px',
        }}
        >
          <Hidden mdUp implementation="css">
           <Box
            style={{
              minHeight: '50px', display: 'flex', flexDirection: 'column',
              justifyContent: 'center', alignItems: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontSize: "1rem",
              fontWeight: "500",
              color: "#A3B4C6",
            }}
          >
            {getActiveRoute(routes)}
          </Box>

          <Divider
            orientation="horizontal"
            style={{ 
              // backgroundColor: '#364759', 
              backgroundImage: "linear-gradient(to right, rgba(54, 71, 89, 0), #364759, rgba(54, 71, 89, 0))",
              // marginTop: '1rem',
              height: '2px', width: '100vw',
            }}
          />
        </Hidden>

        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        </div>
          // : <Loading />
        }
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {//(role && !profileLoading && !licensesTableLoading) &&
                <Switch>
                  {getRoutes(routes)}
                  {(lastUrl)
                    ? <Redirect from="/admin" to={lastUrl} />
                    : <>
                      {(role === RoleEnum.USER)
                        ? <Redirect from="/admin" to="/admin/profile" />
                        : <Redirect from="/admin" to="/admin/profile" />
                      }</>
                  }
                </Switch>
              }
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            {//(!profileLoading && !licensesTableLoading) &&
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/profile" />
              </Switch>
            }
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.role,
  lastUrl: state.lastUrl,
  pendingRequestsNumber: state.pendingRequestsNumber,
  licensesNumber: state.licensesNumber,
  documentsNumber: state.documentsNumber,
  pendingTransfersAndHostingNumber: state.pendingTransfersAndHostingNumber,
});

const mapDispatchToProps = (dispatch) => ({
  changeUserRoleAction: (value) => dispatch(changeUserRoleAction(value)),
  changeAllUserRolesAction: (value) => dispatch(changeAllUserRolesAction(value)),
  changeUserProfileAction: (value) => dispatch(changeUserProfileAction(value)),
  changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
  changePendingTransfersAndHostingNumberAction: (value) => dispatch(changePendingTransfersAndHostingNumberAction(value)),
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
  changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
  changeLocation: (countries, districts, cities) => dispatch(changeLocation(countries, districts, cities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Admin)));