import React from 'react';
import { Grid, Paper, Typography, Divider } from '@material-ui/core'; // Updated to @material-ui/core
import { withStyles } from '@material-ui/core/styles'; // Updated import path for styles
import { differenceInCalendarMonths, format } from 'date-fns';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'; // Updated to @material-ui/icons
import Month from './Month';
import { MARKERS } from '../utils';
import DefinedRanges from './DefinedRanges';

const styles = (theme) => ({
  header: {
    padding: '20px 35px',
  },
  headerItem: {
    flex: 1,
    textAlign: 'center',
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.action.hover}`,
    marginBottom: 20,
  },
  '@media (max-width: 800px)': {
    definedRanges: {
      display: 'none',
    },
  },
  '@media (max-width: 702px)': {
    firstMonth: {
      display: 'none',
    },
  },
});

const Menu = (props) => {
  const {
    classes,
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    isSmallScreen,
  } = props;

  const { startDate, endDate } = dateRange;
  const canNavigateCloser = isSmallScreen ? isSmallScreen : differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };

  return (
    <Paper elevation={5} square>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <Grid container className={classes.header} alignItems="center">
            <Grid item className={classes.headerItem}>
              <Typography variant="subtitle1">
                {startDate ? format(startDate, 'MMMM dd, yyyy') : 'Od'}
              </Typography>
            </Grid>
            <Grid item className={classes.headerItem}>
              <ArrowRightAlt color="action" />
            </Grid>
            <Grid item className={classes.headerItem}>
              <Typography variant="subtitle1">
                {endDate ? format(endDate, 'MMMM dd, yyyy') : 'Do'}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid 
            container 
            direction="row" 
            wrap="nowrap" justifyContent='center'
          >
            <div className={classes.firstMonth} >
              <Month
                {...commonProps}
                value={firstMonth}
                setValue={setFirstMonth}
                navState={[true, canNavigateCloser]}
                marker={MARKERS.FIRST_MONTH}
                className={'classesDivider'}
              />
            </div>
            <div className={classes.divider} />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
            />
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Grid className={classes.definedRanges} >
          <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(Menu);

