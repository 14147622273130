import { Grid } from "@material-ui/core";
import { format } from 'date-fns';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors } from 'common/styles/configLayout';
import moment from "moment";
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme));

const MemberDataCard = (props) => {
  const { intl, role, clubMemberPersonalData, clubMemberFunctionsData, clubMemberDisciplinesData, formikProps, type, readMode, validateParam } = props;
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.personalInfo' })}</Typography></Grid>
            {role === RoleEnum.ADMIN &&
              <Grid item xs={12}>
                <Box className={classes.readOnlyGrid}>
                  <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.IdMember' })}</Typography>
                  <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                    {(clubMemberPersonalData?.bid) ? clubMemberPersonalData?.bid : '-'}
                  </Typography>
                </Box>
              </Grid>
            }
            <Grid item xs={12} lg={6}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.firstName' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(clubMemberPersonalData?.person?.profile?.firstName) ? clubMemberPersonalData?.person?.profile?.firstName : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.lastName' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(clubMemberPersonalData?.person?.profile?.lastName) ? clubMemberPersonalData?.person?.profile?.lastName : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.birth' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(clubMemberPersonalData?.person?.profile?.birth) ? moment(clubMemberPersonalData?.person?.profile?.birth).format("DD.MM.YYYY") : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.personalNumber' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(clubMemberPersonalData?.federationId) ? clubMemberPersonalData?.federationId : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.dataCard.uciId' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(clubMemberPersonalData?.uciId) ? clubMemberPersonalData?.uciId : '-'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >{intl.formatMessage({ id: 'transfersAndHosting.dataCard.dateInfo' }, [(type === 'hosting') ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.hosting' }) : intl.formatMessage({ id: 'transfersAndHosting.dataCard.transfer' })])}</Typography></Grid>
            <Grid item xs={12} lg={(type === 'hosting') ? 6 : 12}>
              <FormikField
                name="from"
                labelText={(type === 'transfer')
                  ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.dateInfo' }, [(type === 'hosting') ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.hosting' }) : intl.formatMessage({ id: 'transfersAndHosting.dataCard.transfer' })])
                  : intl.formatMessage({ id: 'transfersAndHosting.dataCard.startDate' })
                }
                type={readMode === true ? 'dateTimePicker' : 'datePicker'}
                formikProps={formikProps}
                readMode={readMode}
                validateParam={validateParam}
                highlightChange={false}
              />
            </Grid>
            {(type === 'hosting') &&
              <Grid item xs={12} lg={6}>
                <FormikField
                  name="to"
                  labelText={intl.formatMessage({ id: 'transfersAndHosting.dataCard.endDate' })}
                  type={readMode === true ? 'dateTimePicker' : 'datePicker'}
                  formikProps={formikProps}
                  validateParam={validateParam}
                  readMode={readMode}
                  highlightChange={false}
                />
              </Grid>
            }

            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.clubFunctions' })}</Typography></Grid>
            {clubMemberFunctionsData?.map((item, idx) => (
              <Grid item xs={12}>
                <Box className={classes.readOnlyGrid}>
                  <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{idx + 1}. {intl.formatMessage({ id: 'transfersAndHosting.clubFunction' })}</Typography>
                  <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                    {item?.label} {((item?.value === "20" || item.value === "21") && clubMemberDisciplinesData?.length > 0) && "("}
                    {(item?.value === "20" || item.value === "21") &&
                      clubMemberDisciplinesData?.map((disc, idx) => { return <>{(disc?.label)}{(idx !== clubMemberDisciplinesData?.length - 1) && ", "}</> })}
                    {((item?.value === "20" || item.value === "21") && clubMemberDisciplinesData?.length > 0) && ")"}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <IconCard 
        inside
        margin= "0 0 0 0"  
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(MemberDataCard));
