import React from "react";

import { pathOr } from 'rambda';

// components
import { Divider, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import FormikField from "components-lib/FormikField/FormikField";

// css
import { colors } from "common/styles/configLayout.js";


const PriceListClubs = ({formikProps, bigScreen, readMode, width, editData}) => {

    const clubs = editData ? pathOr([], ['pricing', 'clubs'], formikProps.values.clubs.dataEdit) : pathOr([], ['pricing', 'clubs'], formikProps.values.clubs.dataOld);

    return (
        <Box 
            className="PriceListClubsContainer"
            display="flex" flexDirection="column" 
            width={width ? width :"100%"} 
            mt= "1rem"
            style={{}}
        >
            {/* Poplatky za licencie klubu */}
            <Box p="0rem 0 1rem 1rem" style={{ visibility: bigScreen ? 'hidden' : 'visible' }}>
                <Typography 
                variant="body1" 
                style={{ color: colors.primary }}
                >
                Poplatky za licencie klubu
                </Typography>
            </Box>

            {/* Obnova poplatok a Registrácia poplatok */}
            <Box display="flex" justifyContent="space-between" p="1rem 0 1rem 0">
                {(!bigScreen) && (
                <Box style={{ width: '100%' }}>
                    <Typography 
                    variant="body2" 
                    style={{ color: colors.primary, visibility: 'hidden' }}
                    >
                    hidden
                    </Typography>
                </Box>
                )}

                <Box 
                    style={{ 
                        width: '100%',
                        paddingRight: "1rem",
                        paddingLeft: bigScreen ? "0.5rem" : "0rem",
                    }}>
                    <Typography 
                        variant="body2" 
                        style={{ 
                            color: colors.primary,
                        }}
                    >
                        Obnova poplatok
                    </Typography>
                </Box>

                <Box 
                    style={{ 
                        width: '100%',
                        paddingRight: bigScreen ? "0rem" : "0.5rem",
                        marginLeft: bigScreen ? "0.4rem" : "0rem",
                    }}>
                    <Typography 
                        variant="body2"
                        style={{ color: colors.primary, }}
                    >
                        Registrácia poplatok
                    </Typography>
                </Box>
            </Box>

        {/* Club Types Pricing */}
        {clubs.map(({ type, license }, index) => {
            const name = ['Štandardný domáci', 'Štandardný zahraničný', 'UCI domáci','UCI zahraničný'];
            const renewFeeName = editData ? `clubs.dataEdit.pricing.clubs[${index}].license.renewFee` : `clubs.dataOld.pricing.clubs[${index}].license.renewFee`;
            const feeName = editData ? `clubs.dataEdit.pricing.clubs[${index}].license.fee` : `clubs.dataOld.pricing.clubs[${index}].license.fee`;

        return (

            <Box 
                key={index}
                display="flex" flexDirection='column'
                style={{ height: 'auto', }}
            >
                <Box display="flex" justifyContent="space-between" p="0.6rem 0 0.5rem 0rem" 
                    style={{ height: 'auto', }}
                >
                    {(!bigScreen) && (
                    <Box flex={1} style={{ width: bigScreen ? '33.33%' : '100%' }}>
                        <Typography 
                        variant="body1" 
                        style={{ color: colors.primary }}
                        >
                        {name[index]}
                        </Typography>
                    </Box>
                    )}

                    <Box flex={1} 
                        style={{ 
                            width: bigScreen ? '33.33%' : '100%',
                            paddingRight: "1rem",
                            paddingLeft: bigScreen ? "0.5rem" : "0rem",
                        }}
                    >
                        <FormikField
                            name={renewFeeName}
                            labelText={''}
                            formikProps={formikProps}
                            readMode={readMode ? readMode : 'textFieldInput'}
                            inputHeight="1rem"
                        />
                    </Box>

                    <Box flex={1} 
                        style={{ 
                            width: bigScreen ? '33.33%' : '100%',
                            paddingRight: bigScreen ? "0rem" : "0.5rem",
                        }}
                    >
                        <FormikField
                            name={feeName}
                            labelText={''}
                            formikProps={formikProps}
                            readMode={readMode ? readMode : 'textFieldInput'}
                            inputHeight="1rem"
                        />
                    </Box>

                </Box>

                <Divider
                    orientation="horizontal"
                    style={{ 
                        // marginTop: '0.3rem', 
                        backgroundColor: '#364759', 
                        height: '2px', width: '100%' }}
                />
            </Box>
             );
        })}

            {/* <Divider
                orientation="horizontal"
                style={{ margin: '0', backgroundColor: colors.primary, height: '1px', width: '100%' }}
            /> */}

        </Box>

    )
};

export default PriceListClubs;