import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import Box from "@material-ui/core/Box";
import { colors } from 'common/styles/configLayout';
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { Grid } from "@material-ui/core";
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import FormikField from "../../components-lib/FormikField/FormikField";
import { Formik } from "formik";
import Modal from "components-lib/Modal/Modal";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customTableCheckboxRadioSwitch.js";
import { connect } from 'react-redux';
import RoleEnum from 'common/enums/RoleEnum';

import { getValidationSchemaNotification } from "./validationSchema";
import { useMutation } from '@apollo/client';
import NOTIFICATION_SEND from 'queries/NotificationsQueries/notificationSend'

import '../../assets/css/others.css'

const useCheckboxStyles = makeStyles(checkboxStyles);

export const NotificationsTable = (props) => {
    const { 
        history, 
        role, 
        queryVariables, 
        query,
        intl, 
        defaultFilter = null, 
        numberOfRows, 
        queryDataPath, 
        currentYear, 
        edit, 
        setEdit, 
        variables,
        totalCount,
        setDisableButton,
    } = props
    const checkboxClasses = useCheckboxStyles();

    const [sendNotification, { loading, error }] = useMutation(NOTIFICATION_SEND);

    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [validateParam, setValidateParam] = useState(false)
    const [selectedIds, setSelectedIds] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [checkBoxFilters, setCheckBoxFilters] = useState();
    const [checkedAllUsers, setCheckedAllUsers] = useState(false);
    const addBtnDisabled = selectedIds.length === 0;

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => {
                        setDisableButton(true);
                        handleValidation(formikProps)
                    }}
                    color="primary"
                    round
                    table
                    size="sm">
                    Poslať oznámenie
                </Button>
            </>
        );
    };

    const setChecked = (id, clubId) => {
        let currentIndex = selectedIds.indexOf(id);
        let newChecked = [...selectedIds]
        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedIds(newChecked);
    }

    const isChecked = (id) => {
        let help = null
        selectedIds.filter(buttonId => Number(buttonId) === Number(id)).map(item => (
            help = 1
        ))
        if (help === 1) return true
        else return false
    }
   

    const handleSetAllSelectedIds = async () => {
        try {
            let filter = {};

            if (checkBoxFilters) {
                filter = {
                    ...filter,
                    ...checkBoxFilters
                };
            }

            const result = await client.query({
                query: query,
                variables: {
                    filter: filter,
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let ids = [];
            (result?.data?.clubMembersTable?.items).map(item =>
                ids.push(item?.id))
            setSelectedIds(ids);

        } catch (error) {
            throw error;
        }
    }

    const handleCheckBoxFilters = (filters) => {
        setCheckBoxFilters(filters);
        // setSelectedIds([]);
    }

    const handleCheckBox = () => {
        const keys = Object.keys(checkBoxFilters);

        if ( keys.length === 1 && keys[0] === "license" && totalCount === selectedIds.length ) {
            setCheckedAllUsers(true);
        }
        return;
    }

    const handleValidation = (formikProps) => {
        formikProps.validateForm()
        if (formikProps.isValid) {
            setValidateParam(true)
            formikProps.handleSubmit()
        } else if (!formikProps.isValid) {
            setValidateParam(true)
            formikProps.handleSubmit()
        } else
            console.log("ERROR")
    }

    const handleNotification = async (header, body) => {

        const input = checkedAllUsers ? {
            message: {
                header: header,
                body: body
            },
        } : {
            memberIds: selectedIds,
            message: {
                header: header,
                body: body
            },
        }

        try {
            const response = await sendNotification({
                variables: {
                    input,
                },
            });
        
            setModalOpen(false)
            setEdit(false);
            setSelectedIds([]);
            setDisableButton(false);
            enqueueSnackbar(intl.formatMessage({ id: "notification.snackbar.success" }), { variant: 'success' });
            // refetchData();
        } catch (err) {
            console.error('[Error]:', err);
            enqueueSnackbar(intl.formatMessage({ id: "notification.snackbar.error" }), { variant: 'error' });
            setModalOpen(false)
            setEdit(false);
        }        
    }

    
    const onUserDetail = (id) => {
        history.push(`/admin${generatePath(paths.members.detail, { memberId: id })}`);
    }
    
    return (<>
        <QueryTable
            nameTable={paths.members.list}
            variables={variables}
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            onCheckBoxFilters={handleCheckBoxFilters}
            columnsWidth={['auto', 'auto', 'auto', undefined, undefined, 'auto', 'auto', 'auto', 'auto']}
            columns={[
                (role === RoleEnum.ADMIN) ? {
                    Header: '.',
                    accessor: (rowData) => {
                        return (

                        <div style={{ display: "inline" }}>
                        {( edit ) &&
                            <>
                                {(rowData) &&
                                    <Checkbox
                                        key="key"
                                        checked={isChecked(rowData.id)}
                                        tabIndex={-1}
                                        onClick={() => { setChecked(rowData.id, rowData.clubId) }}
                                        checkedIcon={<Check className={checkboxClasses.checkedIcon} />}
                                        icon={<Check className={checkboxClasses.uncheckedIcon} />}
                                        classes={{
                                            checked: checkboxClasses.checked,
                                            root: checkboxClasses.checkRoot
                                        }}
                                    />
                                }
                            </>
                        }
                    </div>)},
                    filterKey: "actions",
                    minWidth: '30px',
                    maxWidth: '100%',
                    checkbox: true,
                } : { Header: "." },
                {
                    Header: intl.formatMessage({ id: 'member.table.firstName' }),
                    accessor: 'firstName',
                    sortKey: "firstName",
                    filterKey: 'firstName',
                    filterOperator: 'contains',
                    minWidth: '89px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.lastName' }),
                    accessor: "lastName",
                    sortKey: "lastName",
                    filterKey: 'lastName',
                    filterOperator: 'contains',
                    minWidth: '113px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.club' }),
                    accessor: "club",
                    sortKey: "club",
                    filterKey: 'club',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.functions' }),
                    accessor: "functions",
                    sortKey: "functions",
                    filterKey: "functions",
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.birth' }),
                    accessor: (rowData) => rowData.birth !== null ? moment(rowData.birth).format("DD.MM.YYYY") : "-",
                    sortKey: "birth",
                    filterKey: "birth",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.license' }),
                    accessor: (rowData) => (rowData.license)
                        ? <Box style={{ color: (rowData.license >= currentYear) ? colors.success : '' }} > {rowData.license} </Box>
                        : "-",
                    sortKey: "license",
                    filterKey: "license",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    filterComponent: 'title',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.disciplines' }),
                    accessor: (rowData) => rowData.disciplines ? rowData.disciplines : "-",
                    sortKey: "disciplines",
                    filterKey: "disciplines",
                    filterOperator: 'contains',
                    minWidth: '106px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.registeredAt' }),
                    accessor: (rowData) => rowData.registeredAt !== null ? moment(rowData.registeredAt).format("DD.MM.YYYY") : "-",
                    sortKey: "registeredAt",
                    filterKey: "registeredAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                },
                (role !== RoleEnum.MEMBER) ?
                    {
                        Header: ' ',
                        filterKey: "actions",
                        accessor: (rowData) => 
                        <>
                            <Box>
                                <Button table round size="sm" color="info"
                                    onClick={() => onUserDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}
                                </Button>
                            </Box>
                        </>,
                        minWidth: '103px',
                        maxWidth: '100%'
                    }
                    : { Header: " " }
            ]}
        />
        {( edit ) &&
            <Box
                className='checkBoxBtnContainer'
                style={{ 
                    zIndex: 999, padding: "5px", 
                    position: "fixed", bottom: "5px",
                }}
                display="flex"
                alignItems="center"
            >
                <Button
                    disabled={addBtnDisabled}
                    color="primary"
                    round
                    table
                    onClick={() => {
                        setModalOpen(true)
                        handleCheckBox()
                    }}
                    size="sm"
                >
                    {intl.formatMessage({ id: 'notification.add.button' })}
                </Button>

                <Button
                    color="primary"
                    round
                    table
                    size="sm"
                    onClick={() => handleSetAllSelectedIds()}
                >
                    {intl.formatMessage({ id: "clubs.button.checkAll" })}
                </Button>

                <Button
                    color="danger"
                    size="sm"
                    round
                    table
                    onClick={() => { setSelectedIds([]); }}
                >
                    {intl.formatMessage({ id: "clubs.button.uncheckAll" })} ({selectedIds.length})
                </Button>

                <Button
                    color="primary"
                    round
                    table
                    size="sm"
                    onClick={() => { 
                        setEdit(false); 
                        setSelectedIds([]);
                        setDisableButton(false);
                    }}
                >
                    {intl.formatMessage({ id: "clubs.button.cancel" })}
                </Button>

            </Box>
        }
        <Formik
            onSubmit={(values) => handleNotification(values.header, values.body)}
            initialValues={{}}
            validationSchema={getValidationSchemaNotification(intl)}
            enableReinitialize
        >
            {(formikProps) => (
                <>
                    <Modal
                        title={intl.formatMessage({ id: "notification.table.modalTitle" })}
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); formikProps.resetForm() }}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{
                                color: 'white', display: 'flex',
                                flexDirection: 'column', gap: '0.5rem',
                            }}>
                                <FormikField
                                    name="header"
                                    labelText={intl.formatMessage({ id: "notification.table.modalHeader" })}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={true}
                                    multiline={true}
                                    rows={2}
                                    InputProps={{
                                        style: {
                                            color: 'white',
                                        },
                                    }}
                                />
                                <FormikField
                                    name="body"
                                    labelText={intl.formatMessage({ id: "notification.table.modalBody" })}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={true}
                                    multiline={true}
                                    rows={4}
                                    InputProps={{
                                        style: {
                                            color: 'white',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                </>
            )}
        </Formik >
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(NotificationsTable));
