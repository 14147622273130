import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { colors } from 'common/styles/configLayout';
import Tooltip from '@material-ui/core/Tooltip';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import TransfersAndHostingTableStatusesEnum from 'common/enums/TransfersAndHostingTableStatusesEnum';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => styles(theme))

export const TransferTable = (props) => {
    const { 
        history, 
        queryVariables, 
        query, 
        intl, 
        defaultFilter = null, 
        numberOfRows, 
        queryDataPath,
        changeTab,
        setChangeTab,
    } = props
    const classes = useStyles();
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);
    const [ newFilters, setNewFilters ] = useState('')

    const onTransferDetail = (id) => {
        localStorage.setItem('activeTabTransfer', 'transferTable');
        history.push({
            pathname: `/admin${generatePath(paths.transfers.detail, { requestId: id })}`,
            state: newFilters,
        })
    }

    const getCircles = (memberResponse, sourceClubResponse, targetClubResponse) => {
        let memberResponseColor = (memberResponse === TransfersAndHostingTableStatusesEnum.APPROVED) ? colors.success : (memberResponse === TransfersAndHostingTableStatusesEnum.REJECTED) ? colors.error : colors.disabled
        let sourceClubResponseColor = (sourceClubResponse === TransfersAndHostingTableStatusesEnum.APPROVED) ? colors.success : (sourceClubResponse === TransfersAndHostingTableStatusesEnum.REJECTED) ? colors.error : colors.disabled
        let targetClubResponseColor = (targetClubResponse === TransfersAndHostingTableStatusesEnum.APPROVED) ? colors.success : (targetClubResponse === TransfersAndHostingTableStatusesEnum.REJECTED) ? colors.error : colors.disabled
        return (
            <div className={classes.root}>
                <Tooltip title={<>{intl.formatMessage({ id: 'transfersAndHosting.responses.member' })}  {intl.formatMessage({ id: 'transfersAndHosting.responses.request' })} {intl.formatMessage({ id: `transfersAndHosting.status.${memberResponse}` })}</>}>
                    <FiberManualRecordIcon style={{ fontSize: '0.9em', color: memberResponseColor }} />
                </Tooltip>
                <Tooltip title={<>{intl.formatMessage({ id: 'transfersAndHosting.responses.sourceClub' })}  {intl.formatMessage({ id: 'transfersAndHosting.responses.request' })} {intl.formatMessage({ id: `transfersAndHosting.status.${sourceClubResponse}` })}</>}>
                    <FiberManualRecordIcon style={{ fontSize: '0.9em', color: sourceClubResponseColor }} />
                </Tooltip>
                <Tooltip title={<>{intl.formatMessage({ id: 'transfersAndHosting.responses.targetClub' })}  {intl.formatMessage({ id: 'transfersAndHosting.responses.request' })} {intl.formatMessage({ id: `transfersAndHosting.status.${targetClubResponse}` })}</>}>
                    <FiberManualRecordIcon style={{ fontSize: '0.9em', color: targetClubResponseColor }} />
                </Tooltip>
            </div>
        )
    }

    return (
        < QueryTable
            nameTable={paths.transfers.list}
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            setNewFilters={setNewFilters}
            setChangeTab={setChangeTab}
            changeTab={changeTab}
            columnsWidth={['140px', undefined, undefined, undefined, '120px', '120px', '180px', undefined, undefined]}
            columns={
                [
                    {
                        Header: intl.formatMessage({ id: 'table.bid' }),
                        accessor: 'bid',
                        sortKey: 'bid',
                        filterKey: 'bid',
                        filterOperator: 'contains',
                        minWidth: '100px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'transfersAndHosting.table.member' }),
                        accessor: "member",
                        sortKey: "member",
                        filterKey: 'member',
                        filterOperator: 'contains',
                        minWidth: '100px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'transfersAndHosting.table.sourceClub' }),
                        accessor: "sourceClub",
                        sortKey: "sourceClub",
                        filterKey: "sourceClub",
                        filterOperator: 'contains',
                        minWidth: '136px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'transfersAndHosting.table.targetClub' }),
                        accessor: "targetClub",
                        sortKey: "targetClub",
                        filterKey: "targetClub",
                        filterOperator: 'contains',
                        minWidth: '125px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'table.year' }),
                        accessor: (rowData) => (rowData.year)
                            ? <Box style={{ color: (rowData.year >= licensingInfoData?.licensingInfo?.periods?.current?.year) ? colors.success : '' }} > {rowData.year} </Box>
                            : "-",
                        sortKey: "year",
                        filterKey: "year",
                        filterOperator: 'eq',
                        filterDataType: 'number',
                        minWidth: '76px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'transfersAndHosting.table.responses' }),
                        accessor: (rowData) => getCircles(rowData.memberResponse, rowData.sourceClubResponse, rowData.targetClubResponse),
                        sortKey: "stores",
                        filterKey: "stores",
                        filterOperator: 'between',
                        filterDataType: "dateBetween",
                        filterComponent: 'title',
                        minWidth: '50px',
                        maxWidth: '100%'
                    },
                    {
                        Header: intl.formatMessage({ id: 'table.status' }),
                        accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} type={"transfersAndHosting"}/>,
                        sortKey: 'status',
                        filterKey: 'status',
                        filterComponent: 'select',
                        filterOperator: 'eq',
                        filterOptions: Object.values(TransfersAndHostingTableStatusesEnum).map((item) => {
                            return ({
                                value: item,
                                label: intl.formatMessage({ id: `transfersAndHosting.status.${item}` })
                            })
                        }),
                        minWidth: '100px',
                        maxWidth: '100%'
                    },

                    {
                        Header: intl.formatMessage({ id: 'transfersAndHosting.table.expiresIn' }),
                        accessor: (rowData) => (rowData.expiresIn && (rowData.status === 'Awaiting' || rowData.status === 'Paid')) ? `${rowData.expiresIn} dní` : '-',
                        sortKey: "expiresIn",
                        filterKey: "expiresIn",
                        filterOperator: 'eq',
                        filterDataType: 'number',
                        minWidth: '130px',
                        maxWidth: '100%'
                    },
                    (clubDetail) ?
                        {
                            Header: ' ',
                            accessor: (rowData) => <Box className={classes.floatRight}><Button table round size="sm" color="info"
                                onClick={() => onTransferDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}</Button></Box>
                        }
                        : { Header: " " }
                ]}
        />
    )
}

export default injectIntl(TransferTable);