import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUEST_OPTIONS = gql`
query memberLicenseCardRequestOptions($input: MemberLicenseCardRequestOptionsInput!) {
  memberLicenseCardRequestOptions(input: $input) {
    id
    year
    function 
    disciplines {
      name
      category
    }
    member {
      id
      uciId
      licenses {
        id
        year
        function {
          id
          name
          requiredDocuments
        }
      }
      person {
        profile {
          firstName
          lastName
          birth
        }
      }
    }  
  }  
}
`;

export default MEMBER_LICENSE_CARD_REQUEST_OPTIONS;


