import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUEST_SUMMARY= gql`
query memberLicenseCardRequestSummary($input:MemberLicenseCardRequestInput!){
  memberLicenseCardRequestSummary(input:$input) {
    products {
      name
      unitPrice
      quantity
      price
    }
    total
  }
}
`;

export default MEMBER_LICENSE_CARD_REQUEST_SUMMARY;