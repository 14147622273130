import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/client';
import { processLogin } from 'helpers/helpers';
import { withRouter } from "react-router-dom";
import paths from "paths";
import { getValidationSchemaLogin } from "./validationSchema";
import { Formik } from "formik";
import FormikField from "components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import LOGIN from 'queries/AuthQueries/login'
import Typography from '@material-ui/core/Typography';
import { colors } from "common/styles/configLayout.js";
import { getApolloClient } from "configFiles/apollo";
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import logoVertical from 'assets/img/cyklopas-logo-text-cinv-vertical.svg';

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const { history, intl } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const emailRef = useRef(null);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [login] = useMutation(LOGIN);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const getRequest = async () => {
    try {
      const result = await client.query({
        query: REQUESTS_TABLE,
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      window.localStorage.setItem('request', result?.data?.requestsTable?.items?.[0]?.id);
    } catch (error) {
      throw error;
    }
  }

  const handleLogin = (values) => {
    setValidateParam(true); 
    setDisableButton(true);
    login({
      variables: {
        ...values
      }
    }).then(response => {
      enqueueSnackbar(intl.formatMessage({ id: 'login.snackbar.success' }), { variant: 'success' })
      processLogin(response);
      getRequest();
      //changeLanguage(getSelectedLanguage());  //TODO: With language version: changeLanguage(getSelectedLanguage())
      history.push(`/admin`);
      setDisableButton(false);
    }).catch(err => {
      console.log('[err]', err);
      setDisableButton(false);
    })
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8} md={6} lg={3} xl={3}>
        <form>
          <Grid login className={classes[cardAnimaton]} style={{ backgroundColor: colors.backgroundWidgets, borderRadius: "16px" }}>
            <Formik
              enableReinitialize
              validationSchema={getValidationSchemaLogin(intl)}
              onSubmit={(values) => { handleLogin(values); }}
              initialValues={{
                email: '',
                password: '',
                keepLoggedIn: false
              }}
            >
              {(formikProps) => (
                <form
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      formikProps.handleSubmit();
                    }
                  }}
                >
                  <CardBody>
                    <div style={{ textAlign: 'center' }}>
                      <img src={logoVertical} alt={logoVertical} style={{ textAlign: 'center', marginTop: '-70px' }} width="100px" />
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <br />
                        <Typography variant="h5" align="center" style={{ color: colors.primary }}> {intl.formatMessage({ id: 'login.loginCard.title' })}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="email"
                          labelText={intl.formatMessage({ id: 'login.loginCard.email' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          inputRef={emailRef}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="password"
                          labelText={intl.formatMessage({ id: 'login.loginCard.password' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          labelText={intl.formatMessage({ id: 'login.loginCard.keepLoggedIn' })}
                          type="checkbox"
                          name="keepLoggedIn"
                          formikProps={formikProps}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                  <CardFooter
                    className={classes.justifyContentCenter}
                    style={{
                      width: '100%',
                      margin: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      size="sm"
                      type="submit"
                      round
                      disabled={disableButton}
                      onClick={(e) => { 
                        e.preventDefault();
                        formikProps.handleSubmit() }}
                    >
                      {intl.formatMessage({ id: 'login.loginCard.button.submit' })}
                    </Button>
                    <Button
                      simple
                      color="primary"
                      size="sm"
                      round
                      onClick={() => {
                        history.push(`/auth${paths.auth.forgottenPassword}`)
                      }}
                    >
                      {intl.formatMessage({ id: 'login.loginCard.button.forgottenPassword' })}
                    </Button>
                    <Grid item xs={12}>
                      <br />
                      <Typography variant="body2" align="center" style={{ color: colors.labelAndBorderInputs, paddingBottom: '10px' }}>
                        <span>{intl.formatMessage({ id: 'login.loginCard.termsOfUse' })}</span>&nbsp;&nbsp;&nbsp;
                        <span>{intl.formatMessage({ id: 'login.loginCard.dataProtection' })}</span>
                      </Typography>
                    </Grid>
                  </CardFooter>
                </form>
              )}
            </Formik>
          </Grid>
        </form>
        {/*
        //HIDEN LANGUAGE VERSION IN THIS PHASE
        <form>
          <Card login className={classes[cardAnimaton]}>
            <CardBody>
              <Typography variant="h5" align="center" style={{ color: colors.primaryBackground}}> {intl.formatMessage({ id: 'login.registrationCard.title' })}</Typography>
            </CardBody>
            <CardFooter
              className={classes.justifyContentCenter}
              style={{
                width: '100%',
                margin: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                color="primary"
                size="sm"
                round
                onClick={() => history.push(`/auth${generatePath(paths.auth.register)}`)}
              >
                {intl.formatMessage({ id: 'login.registrationCard.button.submit' })}
              </Button>
              <br />
            </CardFooter>
          </Card>
            </form> */}
      </Grid>
    </Grid>
  );
};

export default injectIntl(withRouter(LoginPage));
