import gql from 'graphql-tag';

const PAYMENT_INVOICE_DOWNLOAD = gql`
query paymentInvoiceDownload($id: ID!) {
  paymentInvoiceDownload(id: $id){
    type
    url
    expiresAt
  }
}
`;

export default PAYMENT_INVOICE_DOWNLOAD;

