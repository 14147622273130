import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUESTS_TABLE = gql`
query memberLicenseCardRequestsTable($offset: Int, $limit: Int, $sort: [MemberLicenseCardRequestsTableSortInput!], $filter: [MemberLicenseCardRequestsTableFilterInput]) {
  memberLicenseCardRequestsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      status
      price
      createdAt
      updatedAt
      deletedAt
      clubId
      club
      paymentId
      createdById
      createdBy
      licenses
    }
  }
}
`;

export default MEMBER_LICENSE_CARD_REQUESTS_TABLE;