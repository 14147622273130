import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUEST_CREATE = gql`
mutation memberLicenseCardRequestCreate($input: MemberLicenseCardRequestInput!) {
	memberLicenseCardRequestCreate (input: $input) {
  id
  licenses {
    id
    }
  }
}
`;

export default MEMBER_LICENSE_CARD_REQUEST_CREATE;
