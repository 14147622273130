import gql from 'graphql-tag';

const CLUB_MEMBER_LICENSES_DOWNLOAD = gql`
mutation clubMemberLicensesDownload($id: ID!) {
  clubMemberLicensesDownload(id: $id){
    type
    url
    expiresAt
  }
}
`;

export default CLUB_MEMBER_LICENSES_DOWNLOAD;

