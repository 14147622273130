import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DocumentsCard from './ClubCards/DocumentsCard';
import { useSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import { variables } from 'common/styles/configLayout';
import ClubCard from './ClubCards/ClubCard';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LegalEntityCard from './ClubCards/LegalEntityCard';
import PresidentCard from './ClubCards/PresidentCard';
import { getValidationSchemaClubCreate, getValidationSchemaClubCreateForeign } from "./validationSchema";
import CLUB_CREATE from 'queries/ClubsQueries/clubCreate';
import { generatePath } from "react-router-dom";
import paths from "paths";
import { connect } from 'react-redux';
import ClubDocumentsEnum from 'common/enums/ClubDocumentsEnum';
import ClubTypesEnum from 'common/enums/ClubTypesEnum';
import DocumentStatusesEnum from 'common/enums/DocumentStatusesEnum';
import PageHeader from 'components-lib/PageHeader/PageHeader';

const ClubNewPage = (props) => {
    const { intl, history, role } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [validateParam, setValidateParam] = useState(false)
    const [rows, setRows] = useState([])
    const [removeDocuments, setRemoveDocuments] = useState([])
    const [approveDocuments, setApproveDocuments] = useState([])
    const [rejectDocuments, setRejectDocuments] = useState([])
    const [photo, setPhoto] = useState(null)
    const [disableButton, setDisableButton] = useState(false);
    const [type, setType] = useState(ClubTypesEnum.STANDARD_DOMESTIC)
    const [clubCreate] = useMutation(CLUB_CREATE)

    useEffect(() => {
        let documents = [];
        let allTypes = [
            ClubDocumentsEnum.COMPANY_REGISTRATION_STATEMENT,
            ClubDocumentsEnum.ICO_ASSIGNMENT_CONFIRMATION,
            ClubDocumentsEnum.DIC_REGISTRATION_STATEMENT,
            ClubDocumentsEnum.MINUTE_OF_THE_INAUGURAL_MEETING
        ];
        for (var i = 0; i < 4; i++) {
            documents.push({
                status: DocumentStatusesEnum.EMPTY,
                type: allTypes[i],
                validFrom: null,
                validTo: null,
                files: [],
                checked: true
            })
        }
        setRows(documents)
    }, []);

    const getResult = (values) => {
        const result = { ...values };
        delete result.__typename;
        delete result.gdpr;
        delete result.logoUrl
        delete result.statutoryAddress1;
        delete result.statutoryAddress2;
        delete result.statutoryCity;
        delete result.statutoryCountry;
        delete result.statutoryEmail;
        delete result.statutoryFirstName;
        delete result.statutoryLastName;
        delete result.statutoryPhone;
        delete result.statutoryPostal;
        delete result.statutoryDistrict;
        delete result.correspondenceAddress;
        delete result.description;
        delete result.statutoryId;
        delete result.type;
        delete result.nationality;
        delete result.isYouthWorker;
        return result;
    };

    const getDocuments = () => {
        let documents
        documents = rows?.filter(i => i.status === DocumentStatusesEnum.UPLOADED || i.status === "AdminUploaded").map((item) => ({
            validFrom: (item?.validFrom) ? (item?.validFrom) : undefined,
            validTo: (item?.validTo) ? (item?.validTo) : undefined,
            type: (item?.type),
            files: (item?.files)?.map(item => item.file)
        }
        ));
        return documents;
    }

    const handleClubCreate = (values) => {
        const result = getResult(values)
        setDisableButton(true);
        clubCreate({
            variables: {
                input: {
                    type: (values?.type) ? values?.type : ClubTypesEnum.STANDARD_DOMESTIC,
                    profile: {
                        ...result,
                        logo: (photo) ? photo : undefined,
                    },
                    documents: (type === ClubTypesEnum.STANDARD_FOREIGN || type === ClubTypesEnum.UCI_FOREIGN) ? undefined : getDocuments(),
                    functionary: (type === ClubTypesEnum.STANDARD_FOREIGN || type === ClubTypesEnum.UCI_FOREIGN) ? undefined : {
                        isYouthWorker: (values?.isYouthWorker) ? true : false,
                        person: {
                            email: values?.statutoryEmail,
                            firstName: values?.statutoryFirstName,
                            lastName: values?.statutoryLastName,
                            phone: values?.statutoryPhone,
                            address1: values?.statutoryAddress1,
                            address2: values?.statutoryAddress2,
                            district: values?.statutoryDistrict,
                            city: values?.statutoryCity,
                            postal: values?.statutoryPostal,
                            country: values?.statutoryCountry
                        }
                    },
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'clubs.snackbar.create.success' }), { variant: 'success' });
            history.push(`/admin${generatePath(paths.clubs.detail, { clubId: response?.data?.clubCreate?.id })}`);
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleBackAction = () => {
        history.goBack();
    };

    return (<>
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={((type === ClubTypesEnum.STANDARD_DOMESTIC) || (type === ClubTypesEnum.UCI_DOMESTIC)) ? getValidationSchemaClubCreate(intl) : getValidationSchemaClubCreateForeign(intl)}
            onSubmit={(values) => { handleClubCreate(values) }}
            initialValues={{
                type: ClubTypesEnum.STANDARD_DOMESTIC,
                country: variables.defaultCountry,
                useCorrespondenceAddress: true,
                statutoryCountry: variables.defaultCountry
            }}
        >
            {(formikProps) => (<>
                <PageHeader
                    title={intl.formatMessage({ id: 'clubs.create.title' })}
                    withBackButton={true}
                    handleBackAction={handleBackAction}
                //subTitle={pathOr('', ['request', 'bid'], dataRequest)}
                />
                <Grid container spacing={1} >
                    <Grid item xs={12} lg={8}>
                        <Grid container>
                            {setType(formikProps?.values?.type)}
                            {/*<IconCard
                            title={intl.formatMessage({ id: 'clubs.create.title' })}
                            background={colors.disabledBackground}
                            inside
                         >*/}
                            <Grid item xs={12}>
                                <ClubCard
                                    inside
                                    setPhoto={(newValue) => setPhoto(newValue)}
                                    refetch={false}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    request={true}
                                    newClub={true}
                                    highlightChange={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LegalEntityCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    newCompany={true}
                                    validateParam={validateParam}
                                    request={true}
                                    highlightChange={false}
                                />
                            </Grid>
                            {((formikProps?.values?.type === ClubTypesEnum.STANDARD_DOMESTIC) || (formikProps?.values?.type === ClubTypesEnum.UCI_DOMESTIC)) &&
                                <Grid item xs={12}>
                                    <PresidentCard
                                        data={formikProps.values}
                                        formikProps={formikProps}
                                        newCompany={true}
                                        highlightChange={false}
                                        validateParam={validateParam}
                                        readMode={false}
                                    />
                                </Grid>
                            }
                            {((formikProps?.values?.type === ClubTypesEnum.STANDARD_DOMESTIC) || (formikProps?.values?.type === ClubTypesEnum.UCI_DOMESTIC)) &&
                                <Grid item xs={12}>
                                    <DocumentsCard
                                        data={formikProps.values}
                                        formikProps={formikProps}
                                        newCompany={true}
                                        validateParam={validateParam}
                                        detail={false}
                                        closed={false}
                                        callbackRows={(newValue) => setRows(newValue)}
                                        rows={rows}
                                        removeDocuments={removeDocuments}
                                        approveDocuments={approveDocuments}
                                        rejectDocuments={rejectDocuments}
                                        callbackRemoveDocuments={(newValue) => setRemoveDocuments(newValue)}
                                        callbackApproveDocuments={(newValue) => setApproveDocuments(newValue)}
                                        callbackRejectDocuments={(newValue) => setRejectDocuments(newValue)}
                                        role={role}
                                        status={"createClub"}
                                        request={true}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} align="center">
                                <br />
                                <Button
                                    color="primary"
                                    disabled={disableButton}
                                    size="sm"
                                    round
                                    onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                                >
                                    <DirectionsBikeIcon /> {intl.formatMessage({ id: 'clubs.create.button' })}
                                </Button>
                            </Grid>
                            {/*</IconCard>*/}
                        </Grid>
                    </Grid>
                </Grid>
            </>)
            }
        </Formik >
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(ClubNewPage));
//343