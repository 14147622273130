import gql from 'graphql-tag';

const MEMBER_LICENSE_CARD_REQUEST = gql`
query memberLicenseCardRequest($id: ID!) {
	memberLicenseCardRequest (id: $id) {
  	id
    bid
    status
    price
    createdAt
    updatedAt
    club {
      id
      profile {
        name
      }
    }
    payment {
      id
      bid
      status
      purpose
      method
      amount
      description
      bankIBAN
      variableSymbol
      invoiceNumber
      payBySquare
      createdAt
      dueDate
      paidAt
    }
    createdBy {
      profile {
        firstName
        lastName
        }
    }
    licenses {
      downloadUrl
      createdAt
      member {
        id
        uciId
        licenses {
          downloadUrl
        }
        person {
          profile {
            firstName
            lastName
            birth
            personalId
          }
        }
      }
      disciplines {
        discipline {
          id
          name
        }
        category {
          id
          name
          UCICategory
        }
      }
      function {
        id
        name
      }
    }
    cardsFileUrl
    summary {
      products {
        name
        unitPrice
        quantity
        price
      }
      total
    }
  }
}
`;

export default MEMBER_LICENSE_CARD_REQUEST;