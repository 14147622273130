/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

import React, {useEffect} from 'react';
import { addMonths, isSameDay, isWithinInterval, isAfter, isBefore, isSameMonth, addYears, max, min } from 'date-fns';
import { MARKERS, getValidatedMonths, parseOptionalDate } from '../utils';
import { getDefaultRanges } from '../defaults';
import Menu from './Menu';

const DateRangePicker = (props) => {
  const today = new Date();

  const now = new Date();
  const dateAtMidnight = new Date(now.setHours(0, 0, 0, 0));
  const dateEndDay = new Date(now.setHours(23, 59, 59, 999));

  const { 
    open, 
    onChange, 
    initialDateRange, 
    minDate, 
    maxDate, 
    // definedRanges = defaultRanges 
    definedRanges = getDefaultRanges(dateAtMidnight, dateEndDay)
  } = props;
  const minDateValid = parseOptionalDate(minDate, addYears(today, -90));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {}, 
    minDateValid, 
    maxDateValid
  );
  
  const [dateRange, setDateRange] = React.useState({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState();
  // const [firstMonth, setFirstMonth] = React.useState(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState(intialFirstMonth || today);
  const [firstMonth, setFirstMonth] = React.useState(initialSecondMonth || addMonths(secondMonth, -1));
  // const [secondMonth, setSecondMonth] = React.useState(initialSecondMonth || addMonths(firstMonth, -1));
  
  const { startDate, endDate } = dateRange;
  
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      if ( window.innerWidth <= 702 ) {
        setIsSmallScreen(true);
      } else if ( window.innerWidth > 702 ) {
        setIsSmallScreen(false);
      }
    };
  
    window.addEventListener('resize', handleResize);

    handleResize();

    // Cleanup listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  // handlers
  const setFirstMonthValidated = (date) => {
    if (isBefore(date, secondMonth)) {
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date) => {
    if (isSmallScreen) {
      setSecondMonth(date);
    } else {
      if (isAfter(date, firstMonth)) {
        setSecondMonth(date);
      }
    }
  };

  const setDateRangeValidated = (range) => {
    let { startDate: newStart, endDate: newEnd } = range;

    if (newStart && newEnd) {
      range.startDate = newStart = max([newStart, minDateValid]);
      range.endDate = newEnd = min([newEnd, maxDateValid]);

      setDateRange(range);
      onChange(range);

      setFirstMonth(newStart);
      setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
    } else {
      const emptyRange = {};

      setDateRange(emptyRange);
      onChange(emptyRange);

      setFirstMonth(today);
      setSecondMonth(addMonths(firstMonth, 1));
    }
  };

  const onDayClick = (day) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      // const endDay = new Date(day);
      // endDay.setHours(23, 59, 59, 999);
      const newRange = { startDate, endDate: day };
      // const newRange = { startDate, endDate: endDay };

      // onChange(newDaysRange);
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (marker, action) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if ( isSmallScreen === false ) {
        if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
      }
    } else {
      const secondNew = addMonths(secondMonth, action);
      if ( isSmallScreen === false ) {
        if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
      }
      setSecondMonth(secondNew)
    }
  };

  const onDayHover = (date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day) =>
    startDate &&
    !endDate &&
    hoverDay &&
    isAfter(hoverDay, startDate) &&
    isWithinInterval(day, {
      start: startDate,
      end: hoverDay,
    });

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return open ? (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}} >
      <Menu
        dateRange={dateRange}
        minDate={minDateValid}
        maxDate={maxDateValid}
        ranges={definedRanges}
        firstMonth={firstMonth}
        secondMonth={secondMonth}
        setFirstMonth={setFirstMonthValidated}
        setSecondMonth={setSecondMonthValidated}
        setDateRange={setDateRangeValidated}
        helpers={helpers}
        handlers={handlers}
        isSmallScreen={isSmallScreen}
      />
    </div>
  ) : null;
};

export default DateRangePicker;
