import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import MemberTable from "./MemberTable";
import CLUB_MEMBERS_TABLE from 'queries/MembersQueries/clubMembersTable';
import { useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { getAPIUrl } from "configFiles/config";
import { getUserToken } from "helpers/helpers";
import { Grid } from '@material-ui/core';
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { getValidationSchemaMonths } from "./validationSchema";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { colors } from "common/styles/configLayout.js";
import RoleEnum from 'common/enums/RoleEnum';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import CLUB_MEMBERS_SYNC_FAILED from 'queries/MembersQueries/clubMembersSyncFailed';
import { useSnackbar } from "notistack";
import useStylesTable from '../../components/ReactTable/styles';

const MemberListPage = (props) => {
  const { intl, history, role } = props;
  let location = useLocation();
  const classesTable = useStylesTable();
  const { enqueueSnackbar } = useSnackbar();
  let defaultFilter = location.state ? location.state : null
  const { data: licensingInfoData } = useQuery(LICENSING_INFO);
  const [clubMembersSyncFailedMutation] = useMutation(CLUB_MEMBERS_SYNC_FAILED);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSZC, setModalOpenSZC] = useState(false);

  const currentYear = new Date().getFullYear();
  const [ newYear, setNewYear ] = useState(currentYear);

  const newYearOptions = Array.from({ length: 3 }, (_, index) => {
    const year = currentYear - index;
    return { label: String(year), value: String(year) };
  });

  const handleNewYearChange = (newYear) => {
    setNewYear(newYear);
  };

  const handleGetExportInsuranceCompany = async (month, year, resetForm) => {
    let url = `${getAPIUrl('rest')}/export/insuranceCompany?month=${month}&year=${year}`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.insuranceExport.file' })}${month}-${year}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setModalOpen(false)
    resetForm();
  }

  const handleGetExportReferee = async () => {
    let url = `${getAPIUrl('rest')}/export/referee`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.refereeExport.file' })}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  
  const handleGetExportISS = async () => {
    let url = `${getAPIUrl('rest')}/export/iss`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.ISSExport.file' })}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
 
  const handleGetExportSZC = async () => {
    let url = `${getAPIUrl('rest')}/export/szc?year=${newYear}`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.SZCExport.file' })}${newYear}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setModalOpenSZC(false)
  }

  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => formikProps.handleSubmit()}
          color="primary"
          round
          table
          size="sm">
          <SystemUpdateAltIcon /> {intl.formatMessage({ id: 'member.insuranceExport.button' })}
        </Button>
      </>
    );
  };

  const renderModalButtonsSZC = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => handleGetExportSZC()}
          color="primary"
          round
          table
          size="sm">
          Exportovať SZC
        </Button>
      </>
    );
  };

  const handleSyncMembers = async () => {
    try {
      const { data } = await clubMembersSyncFailedMutation();
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'club.clubsExport.snackbar.success' },
          { count: data.clubMembersSyncFailed },
          { variant: 'success' }
        )
      );
    } catch (error) {
      console.error('Error syncing failed members:', error);
    }
  }

  const getActions = () => {
    let array = [];

    if (role === RoleEnum.ADMIN) {
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.syncISS' }),
        // icon: <AddIcon />,
        onClick: () => handleSyncMembers(),
      })
    }

    return array;
  };

  const getDropDownBtn = () => {
    let array = [];

    if (role !== RoleEnum.MEMBER) {
      let subButtonsInvite = [];
  
      if (role !== RoleEnum.MEMBER) {
        subButtonsInvite.push({
          title: intl.formatMessage({ id: 'member.action.button.memberInvite' }),
          color: 'success',
          onClick: () => history.push(`/admin${generatePath(paths.clubs.invite)}`),
        });
      }
  
      if (role === RoleEnum.ADMIN) {
        subButtonsInvite.push({
          title: intl.formatMessage({ id: 'member.action.button.statutoryInvite' }),
          color: 'success',
          onClick: () => history.push(`/admin${generatePath(paths.members.invite)}`),
        });
      }

      array.push({
        title: intl.formatMessage({ id: 'member.action.button.invite' }),
        icon: <AddIcon />,
        subButtons: subButtonsInvite,
      });
    }

    if (role === RoleEnum.ADMIN) {
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.export' }),
        icon: <SystemUpdateAltIcon />,
        subButtons: [
          {
            title: intl.formatMessage({ id: 'member.action.button.insuranceExport' }),
            onClick: () => setModalOpen(true),
            color: 'success',
          },
          {
            title: intl.formatMessage({ id: 'member.action.button.refereeExport' }),
            onClick: () => handleGetExportReferee(),
            color: 'success',
          },
          {
            title: intl.formatMessage({ id: 'member.action.button.ISSExport' }),
            onClick: () => handleGetExportISS(),
            color: 'success',
          },
          {
            title: intl.formatMessage({ id: 'member.action.button.SZCExport' }),
            onClick: () => setModalOpenSZC(true),
            color: 'success',
          },
        ],
      })
    }
    
    return array;
  };

  const getMonths = () => {
    return [
      { value: 1, label: intl.formatMessage({ id: 'month.january' }) },
      { value: 2, label: intl.formatMessage({ id: 'month.february' }) },
      { value: 3, label: intl.formatMessage({ id: 'month.march' }) },
      { value: 4, label: intl.formatMessage({ id: 'month.april' }) },
      { value: 5, label: intl.formatMessage({ id: 'month.may' }) },
      { value: 6, label: intl.formatMessage({ id: 'month.june' }) },
      { value: 7, label: intl.formatMessage({ id: 'month.july' }) },
      { value: 8, label: intl.formatMessage({ id: 'month.august' }) },
      { value: 9, label: intl.formatMessage({ id: 'month.september' }) },
      { value: 10, label: intl.formatMessage({ id: 'month.october' }) },
      { value: 11, label: intl.formatMessage({ id: 'month.november' }) },
      { value: 12, label: intl.formatMessage({ id: 'month.december' }) },
    ]
  }

  const renderTables = () => {
    return (
      <div>
        <MemberTable
          query={CLUB_MEMBERS_TABLE}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['clubMembersTable', 'items']}
          currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
          nextYear={licensingInfoData?.licensingInfo?.periods?.next?.year}
        />
      </div>
    );
  };
  
  return (
    <div
    className= 'clubsTableContainer'
      style={{
        width: 'auto',
        maxWidth: '145rem',
      }}
    >
      <PageHeader
        title={null}
        actions={getActions()}
        withBackButton={false}
        dropDownBtn={getDropDownBtn()}
      />

      <IconCard>
        {renderTables()}
      </IconCard>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, { resetForm }) => handleGetExportInsuranceCompany(values?.month, values?.year, resetForm)}
        validationSchema={getValidationSchemaMonths(intl)}
        initialValues={{}}
        enableReinitialize
      >
        {(formikProps) => (
          <>
            <Modal
              title={intl.formatMessage({ id: 'member.insuranceExport.modal.title' })}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              actions={renderModalButtons(formikProps)}
              fullWidth
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ color: colors.textBody }}>{intl.formatMessage({ id: 'member.insuranceExport.modal.body' })}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormikField
                    name="year"
                    labelText={intl.formatMessage({ id: 'table.year' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="select"
                    options={[
                      { value: licensingInfoData?.licensingInfo?.periods?.current?.year, label:  licensingInfoData?.licensingInfo?.periods?.current?.year},
                      { value: licensingInfoData?.licensingInfo?.periods?.next?.year, label:  licensingInfoData?.licensingInfo?.periods?.next?.year},
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormikField
                    name="month"
                    labelText={intl.formatMessage({ id: 'table.month' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="select"
                    options={getMonths()}
                  />
                </Grid>
              </Grid>
            </Modal>
          </>
        )}
      </Formik >
      <Modal
        title={"Exportovať dáta"}
        open={modalOpenSZC}
        onClose={() => {setModalOpenSZC(false); setNewYear(null)}}
        actions={renderModalButtonsSZC()}
        fullWidth
      >
        {/* <Grid container spacing={1}> */}
          <Grid className="psdiv"item xs={12}>
            <Typography
              component="span"
              variant="body1" 
              style={{ 
                color: colors.primary, paddingBottom: "1rem",
                display:"flex", flexDirection:"column",
              }}
            >Vyberte rok, podľa ktorého sa exportujú dáta</Typography>
            <FormControl size="small" className={classesTable.root} style={{ width: "11rem" }} fullWidth variant="outlined">
              <Select 
                MenuProps={{ classes: { paper: classesTable.select } }}
                style={{
                  // backgroundColor: '#364759',
                  borderRadius: '10px',
                  borderColor: 'rgba(60, 72, 88, 0.4)',
                  // color: '#F5F6F6',
                  fontSize: '0.8rem',
                }}
                value={newYear ? newYear : currentYear}
                onChange={(e) => {
                handleNewYearChange(e.target.value);
                }}
                inputProps={{
                    classes: { icon: classesTable.selectIcon },
                }}
              >
                  {newYearOptions.map((option, index) => (
                  <MenuItem
                      key={`filter.${index}`}
                      value={option.value}
                      className="inputSelect"
                      classes={{
                      root: classesTable.selectMenuItem,
                      selected: classesTable.selectMenuItemSelected,
                      }}
                  >
                      {option.label}
                  </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* {options.map((option, index) => {

              return (
                <MenuItem
                    key={`filter.${index}`}
                    value={option.value}
                    className="inputSelect"
                    classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                    }}
                >
                    {option.label}
                </MenuItem>

              )
            })} */}
          </Grid>
        {/* </Grid> */}

      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps)(injectIntl(MemberListPage));