const paths = {
    profile: '/profile',
    dashboard: '/dashboard',

    activities: {
        list: '/activities',
    },
    auth: {
        login: '/login',
        register: '/register',
        confirm: '/confirm',
        activate: '/activate',
        passwordReset: '/password-reset',
        logout: '/logout',
        forgottenPassword: '/forgotten-password',
        members: '/members',
        clubs: '/clubs',

    },
    public: {
        members: '/members',
        clubs: '/clubs',
    },
    settings: '/settings',
    clubs: {
        list: '/clubs',
        detail: '/clubs/detail/:clubId',
        myDetail: '/clubs/detail',
        new: '/clubs/new',
        invite: '/clubs/invite',
    },
    invite: '/invite',
    requests: {
        list: '/requests',
        detail: '/requests/detail/:requestId',
        new: '/requests/new'
    },
    members: {
        list: '/members',
        new: '/members/new',
        detail: '/members/detail/:memberId',
        invite: '/members/invite'
    },
    licenses: {
        list: '/licenses',
        new: '/licenses/new',
        newCard: '/licenses/new-card',
        detail: '/licenses/detail/:licenseId',
        detailCard: '/licenses/cards/detail/:licenseCardId'
    },
    transfers: {
        list: '/transfers',
        new: '/transfers/new',
        detail: '/transfers/detail/:requestId'
    },
    hosting: {
        new: '/hosting/new',
        detail: '/hosting/detail/:requestId'
    },
    documents: {
        list: '/documents'
    },
    payments: {
        list: '/payments',
        detail: '/payments/detail/:paymentId'
    },

    users: {
        list: '/users',
        new: '/users/new',
        detail: '/users/detail/userId'
    },
    groups: {
        list: '/groups'
    },
    prices: {
        list: '/prices',
    },
    notifications: {
        list: '/notifications',
    },
}

export default paths;