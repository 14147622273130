import { makeStyles } from '@material-ui/core/styles';
import React, { } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { colors, } from 'common/styles/configLayout';
import Typography from "@material-ui/core/Typography"
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell } from 'common/styles/muiDesign'

const useStyles = makeStyles((theme) => styles(theme))

export const LicensePaymentTable = (props) => {
    const { intl, history, licenseSummary, numberOfCard } = props
    const classes = useStyles();

    return (<>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Druh</StyledTableCell>
                        <StyledTableCell>Cena</StyledTableCell>
                        <StyledTableCell>Počet</StyledTableCell>
                        <StyledTableCell>Spolu</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { (numberOfCard > 0 && licenseSummary?.products?.length > 0) ? (
                    (licenseSummary?.products)?.map((item, idx) => (
                        <TableRow key={item.name} >
                            <StyledTableCell style={{ color: "white" }} component="th" scope="row">{item?.name}</StyledTableCell>
                            <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}>{item?.unitPrice} €</StyledTableCell>
                            <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}>{item?.quantity}</StyledTableCell>
                            <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}>{item?.price} €</StyledTableCell>
                        </TableRow>
                    ))
                    ) : null}
                </TableBody>
            </Table>
        <Grid item xs={12} >
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                <span>Poplatky celkom: </span>
                                <Box className={classes.floatRight}>
                                    <span>{(numberOfCard > 0 ) ? licenseSummary?.total : '0'} €</span>
                                </Box>
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}

export default injectIntl(LicensePaymentTable);