import React, { useState, } from 'react';

// components
import PriceListClubs from "components/PricelistTable/PriceListClubs";
import PriceListClubsMembers from "components/PricelistTable/PriceListClubsMembers";
import PriceListCategory from "components/PricelistTable/PriceListCategory";
import PriceListOthers from "components/PricelistTable/PriceListOthers";
import Typography from "@material-ui/core/Typography";
import { Divider, Box, useMediaQuery } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import { Form } from 'formik';

import { colors } from "common/styles/configLayout.js";
import useStyles from '../../components/ReactTable/styles';

// css
import '../../assets/css/priceList.css';

const PriceList = ({
    formikProps,
    editPrice,
    currentYear,
    tab,
    year, 
    yearEdit,
    handleYearChange, 
    handleYearEditChange,
    expandedGroup,
    expandedGroupEdit,
    setExpandedGroupEdit,
    setExpandedGroup,
    optionsEdit,
    refresh,
    setRefresh,
}) => {

    const classes = useStyles();

    const bigScreen = useMediaQuery('(min-width:1261px)');

    const options = Array.from({ length: 3 }, (_, index) => {
        const year = currentYear - index;
        return { label: String(year), value: String(year) };
    });

    const [animationClass, setAnimationClass] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);

    const toggleGroup = (group, table) => {
        if (isAnimating) return;

        if (bigScreen === false) {
            // Check if we're toggling for the "Old" or "Edit" table
            if (table === "old") {
                if (expandedGroup === group) {
                    setAnimationClass('slide-up');
                        setExpandedGroup(null);
                } else {
                    setExpandedGroup(group);
                    setAnimationClass('slide-down');
                }
            } else if (table === "edit") {
                if (expandedGroupEdit === group) {
                    setAnimationClass('slide-up');
                        setExpandedGroupEdit && setExpandedGroupEdit(null);
                } else {
                    setExpandedGroupEdit && setExpandedGroupEdit(group);
                    setAnimationClass('slide-down');
                }
            }
        } else {
            if (expandedGroup === group || expandedGroupEdit === group) {
                setAnimationClass('slide-up');
                setIsAnimating(true);
            } else {
                setExpandedGroup(group);
                setExpandedGroupEdit(group);
                setAnimationClass('slide-down');
                setIsAnimating(true);
            }
        }
    };

    const handleAnimationEnd = () => {
        setIsAnimating(false);
    
        if (animationClass === 'slide-up') {
            setExpandedGroup(null);
            setExpandedGroupEdit(null);
        }
        setAnimationClass('');
    };    

    return (
        <>
            <Box 
                className='priceListContainer'
                style={{ 
                    overflowX: 'auto',
                    display: 'flex',
                    flexDirection: bigScreen ? 'row' : 'column',
                    // width: '100%',
                }}
            >
                <Form
                    className='OldPriceContainer'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: !bigScreen && (tab === 0 || tab === 1) ? '25rem' : !bigScreen && (tab === 2 ) ? '30rem' : !bigScreen && (tab === 2 ) ? '20rem' : '',
                    }}
                >
                    <Box 
                        style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body1" 
                                style={{ color: colors.primary }}
                            >Starší cenník</Typography>
                        </Box>
                        <div className={classes.inputContainer} style={{ width: "auto" }} >
                            <span className={classes.input} style={{ width: "100%" }}>
                                <FormControl size="small" className={classes.root} style={{ width: "8rem" }} fullWidth variant="outlined">
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        style={{ fontSize: "0.8rem" }}
                                        value={year}
                                        onChange={(e) => {
                                        handleYearChange(e.target.value);
                                        }}
                                        inputProps={{
                                            classes: { icon: classes.selectIcon },
                                        }}
                                    >
                                        {options.map((option, index) => (
                                        <MenuItem
                                            key={`filter.${index}`}
                                            value={option.value}
                                            className="inputSelect"
                                            classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </span>
                            
                        </div>
                    </Box>

                    <Divider
                        orientation="horizontal"
                        style={{ 
                            margin: ' 0', backgroundColor: colors.primary, 
                            height: '1px', width: '100%', 
                            marginTop: "1rem",
                        }}
                    />

                    {tab === 0 && (
                        <PriceListClubs 
                            formikProps={formikProps} 
                            readMode={ 'readOnlyInput' }
                        />                        
                    )}

                    {tab === 1 && (
                        <PriceListClubsMembers 
                            formikProps={formikProps}
                            readMode={ 'readOnlyInput' }
                        />
                    )}
                    
                    {tab === 2 && (
                        <PriceListCategory 
                            formikProps={formikProps}
                            readMode={ 'readOnlyInput' }
                            table={'old'}
                            bigScreen={bigScreen}
                            expandedGroup={expandedGroup}
                            setExpandedGroupEdit={setExpandedGroupEdit}
                            animationClass={animationClass}
                            toggleGroup={toggleGroup}
                            handleAnimationEnd={handleAnimationEnd}
                        />
                    )}
                   
                    {tab === 3 && (
                        <PriceListOthers 
                            formikProps={formikProps}
                            readMode={ 'readOnlyInput' }
                        />
                    )}

                
                    <Divider
                        orientation="vertical"
                        style={{ 
                            // marginLeft: '0.5rem', 
                            backgroundColor: colors.primary, 
                            width: '1px', 
                            height: 'auto',
                            alignSelf: 'stretch'
                        }}
                    />

                    { ( !bigScreen ) && (
                        <Divider
                            orientation="horizontal"
                            style={{ 
                                margin: '0', backgroundColor: colors.primary, 
                                height: '1px', width: '100%',
                                marginBottom: '36px' 
                            }}
                        />
                    )}
                </Form>

                <Divider
                    orientation="vertical"
                    style={{ 
                        margin: ' 0', backgroundColor: colors.primary, 
                        height: 'auto', width: '1px', 
                        marginTop: "1rem",
                    }}
                />
                <Form
                    className='EditPriceListContainer'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: !bigScreen && (tab === 0 || tab === 1) ? '25rem' : !bigScreen && (tab === 2 ) ? '32rem' : !bigScreen && (tab === 3 ) ? '20rem' : '',
                    }}
                >
                    <Box 
                        className=''
                        style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '0.5rem',
                        }}
                    >

                        <Box>
                            <Typography variant="body1" style={{ color: colors.primary }}>Editovaný cenník</Typography>
                        </Box>
                        <div className={classes.inputContainer} style={{ width: "auto" }}>
                            <span className={classes.input} style={{ width: "100%" }}>
                                <FormControl size="small" className={classes.root} style={{ width: "8rem" }} fullWidth variant="outlined">
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        style={{ fontSize: "0.8rem" }}
                                        value={yearEdit}
                                        onChange={(e) => {
                                            handleYearEditChange(e.target.value);
                                        }}
                                        inputProps={{ classes: { icon: classes.selectIcon } }}
                                    >
                                    {optionsEdit.map((option, index) => (
                                        <MenuItem
                                            key={`filter.${index}`}
                                            value={option.value}
                                            className="inputSelect"
                                            classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                        >
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </span>
                        </div>
                    </Box>
                    <Divider orientation="horizontal" style={{ margin: ' 0', backgroundColor: colors.primary, height: '1px', width: '100%', marginTop: "1rem" }} />
                    
                    {tab === 0 && (
                        <PriceListClubs 
                            formikProps={formikProps} 
                            bigScreen={bigScreen} 
                            readMode={editPrice ? false : 'readOnlyInput'} 
                            editData={true}
                        />                        
                    )}
                    {tab === 1 && (
                        <PriceListClubsMembers 
                            formikProps={formikProps} 
                            bigScreen={bigScreen} 
                            readMode={editPrice ? false : 'readOnlyInput'} 
                            editData={true}
                        />                        
                    )}
                    {tab === 2 && (
                        <PriceListCategory 
                            formikProps={formikProps} 
                            bigScreen={bigScreen} 
                            table={'edit'}
                            readMode={editPrice ? 'textFieldInput' : 'readOnlyInput'} 
                            editData={true}
                            expandedGroupEdit={expandedGroupEdit}
                            setExpandedGroupEdit={setExpandedGroupEdit}
                            animationClass={animationClass}
                            toggleGroup={toggleGroup}
                            handleAnimationEnd={handleAnimationEnd}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    )}
                    {tab === 3 && (
                        <PriceListOthers 
                            formikProps={formikProps} 
                            bigScreen={bigScreen} 
                            readMode={editPrice ? 'textFieldInput' : 'readOnlyInput'} 
                            editData={true}
                        />
                    )}

                </Form>
            </Box>
        </>
        
    );
};

export default PriceList;
