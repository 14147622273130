import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import LANGUAGE_CHANGE from 'queries/ProfileQueries/languageChange';
import CHANGE_PASSWORD from 'queries/ProfileQueries/changePassword';
import Grid from '@material-ui/core/Grid';
import PersonalDataCard from '../Profile/DetailCards/PersonalDataCard';
import DocumentsCard from '../Clubs/ClubCards/DocumentsCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import LanguageIcon from '@material-ui/icons/Language';
import CustomSelect from 'components-lib/CustomSelect/CustomSelect';
import { IntlContext } from "index";
import { setPreferedLanguage } from 'helpers/helpers';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography"
import { colors } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import LicenseNewTable from './LicenseCards/LicenseNewTable';
import LicenseNewCardTable from './LicenseCards/LicenseNewCardTable';
import LicensePaymentCardTable from './LicenseCards/LicensePaymentCardTable';
import CLUB_MEMBERS_BY_CLUB from "queries/MembersQueries/clubMembersByClub";
import CLUB_NAME from "queries/LicensesQueries/clubName";
import MEMBER_LICENSE_OPTIONS from "queries/LicensesQueries/memberLicenseOptions";
// import MEMBER_LICENSE_REQUEST_CREATE from "queries/LicensesQueries/memberLicenseRequestCreate";
import MEMBER_LICENSE_CARD_REQUEST_CREATE from "queries/LicensesQueries/memberLicenseCardRequestCreate";
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import { generatePath } from "react-router-dom";
import paths from "paths";
import { connect } from 'react-redux';
import Modal from "components-lib/Modal/Modal";
import { getApolloClient } from "configFiles/apollo";
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';

import MEMBER_LICENSE_CARD_REQUEST_OPTIONS from "queries/MembersQueries/memberLicenseCardRequestOptions";
import { OTHERS_PRICING } from 'queries/PricingQueries/pricing';

const useStyles = makeStyles((theme) => styles(theme))


const LicenseNewCardPage = (props) => {
  const { intl, history, role, changeLicensesNumberAction } = props;
  const classes = useStyles();
  let location = useLocation();
  let membersIds = location.state?.membersIds ? location?.state?.membersIds : null
  const search = useLocation().search;

  const currentYear = new Date().getFullYear();

  const [clubId, setClubId] = useState(undefined)
  const [year, setYear] = useState(undefined)

  useEffect(() => {
    new URLSearchParams(search).get('clubId') && localStorage.setItem("clubId", new URLSearchParams(search).get('clubId'))
    new URLSearchParams(search).get('year') && localStorage.setItem("year", new URLSearchParams(search).get('year'))
  }, [])

  useEffect(() => {
    setClubId(localStorage.getItem("clubId"))
    setYear(localStorage.getItem("year"))
  }, [])

  const { data: dataClub } = useQuery(CLUB_NAME, { variables: { id: new URLSearchParams(search).get('clubId') ? new URLSearchParams(search).get('clubId') : localStorage.getItem("clubId") } });

  const [disableButton, setDisableButton] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([{}]);

  const { data: clubMembersByClubLicense } = useQuery(MEMBER_LICENSE_CARD_REQUEST_OPTIONS, { variables: {input: { clubId: clubId }}, skip: !clubId });

  const numberOfCard = rows.filter(row => Object.keys(row).length > 0).length;

  const [memberLicenseCardRequestCreate] = useMutation(MEMBER_LICENSE_CARD_REQUEST_CREATE)
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const [functions, setFunctions] = useState([]);
  const [initialFunctions, setInitialFunctions] = useState([]);
  const [clubMembers, setClubMembers] = useState([]);
  const [cardSummary, setCardSummary] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const getDisciplines = (disciplines) => {
    let arr = []
    let arrPara = []
    arr = (disciplines).filter(d => d?.id !== '1')?.map(disc => {
      return {
        value: disc.id,
        label: disc?.name,
        categories: (disc?.categories)?.map(cat => {
          return {
            value: cat.id,
            label: cat?.name,
            disabled: cat?.missingRequirements,
          }
        })
      }
    })
    arrPara = (disciplines).find(d => d?.id !== '1') && [(disciplines).filter(d => d?.id === '1')?.map(disc => {
      return {
        value: disc.id,
        label: disc?.name,
        categories: (disc?.categories)?.map(cat => {
          return {
            value: cat.id,
            label: cat?.name,
            disabled: cat?.missingRequirements,
          }
        })
      }
    })]
    return (disciplines?.length > 0) ? [...arr, ...arrPara?.[0]] : [];
  }

  useEffect(() => {
    let clubMembersByClubSelectItems = pathOr([], ["memberLicenseCardRequestOptions"], clubMembersByClubLicense).map(
      (item) => {
        return {
          value: item.id,
          label: `${item.member.person.profile.firstName} ${item.member.person.profile.lastName} : ${item.function}  (${(item.member?.person?.profile?.birth) ? moment(item?.member.person?.profile?.birth).format("DD.MM.YYYY") : 'Neuvedené'}) ${item.disciplines?.length > 0 ? ` - ${item.disciplines.map(d => d.name).join(", ")}` : ''}`,
          birth: (item?.member.person?.profile?.birth) ? moment(item?.person?.profile?.birth).format("DD.MM.YYYY") : undefined,
          uciId: item?.member.uciId,
          function: item?.function,
          discipline: item.disciplines.map(d => d.name)
        };
      });

    setClubMembers(clubMembersByClubSelectItems);

  }, [clubMembersByClubLicense]);

  const renderModalButtons = () => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => handleMemberLicenseRequestCreate()}
          color="primary"
          round
          table
          size="sm">
          <CheckIcon /> Odoslať žiadosť
        </Button>
      </>
    );
  };

  const handleMemberLicenseRequestCreate = (values) => {

    const newCards = rows.map((row) => row.clubMember?.value).filter(Boolean);

    if (newCards.length > 0) {
      setDisableButton(true);
      memberLicenseCardRequestCreate({
        variables: {
          input: {
            clubId: clubId,
            licenseIds: newCards,
          }
        }
      }).then((response) => {
        enqueueSnackbar("Žiadosť o karty členov bola odoslaná", { variant: 'success' });
        // getLicensesNumber();
        // history.push(`/admin${generatePath(paths.licenses.list)}`,);
        history.push({
          pathname: `/admin${generatePath(paths.licenses.list)}`,
          table: 'cardTable'
        });
        setDisableButton(false);
      }).catch((err) => {
        console.log('[Error]: ', err);
        setDisableButton(false);
      });
    } else {
      enqueueSnackbar("Žiadosť neobsahuje žiadne záznamy", { variant: 'warning' });
    }
  }

  const getLicenseCreateProblem = () => {
    let secProblem = [];
    let problem = [];
    let thirdProblem = [];
    problem = rows?.map(item => {
      if (item?.clubMember?.disabled) return problem.push("ClubMemberProblem");
      secProblem = item?.functions?.map(func => {
        if (func?.disabled?.length > 0) return problem.push("FunctionsProblem");
        if (func?.value === '') return problem.push("ClubMemberProblem");
      })
    })
    if (problem.find(item => item) || secProblem?.find(item => item))
      return true
    else
      return false
  }

  const handleBackAction = () => {
    history.push({
      pathname: `/admin${generatePath(paths.licenses.list)}`,
      table: 'cardTable'
    });
  };

  // if (clubMembersByClubLoading || clubMembersAutofillLoading) return <Loading />;
  return (<>
    <PageHeader
      title={`Nová žiadosť o karty`}
      withBackButton={true}
      handleBackAction={handleBackAction}
      //subTitle={`Rok: ${year}`}
      subTitle={`Klub: ${dataClub?.club?.profile?.name}, Rok: ${year}`
      }
    />
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      //validationSchema={getValidationSchema(intl)}
      onSubmit={(values) => handleMemberLicenseRequestCreate()}
      initialValues={{ country: "Slovensko" }}
    >
      {(formikProps) => (<>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={9}>
            <Grid item xs={12}>
              <IconCard inside>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    { location.pathname === `/admin${generatePath(paths.licenses.newCard)}` && 
                      <LicenseNewCardTable
                        callbackRows={(newValue) => setRows(newValue)}
                        rows={rows}
                        closed={false}
                        role={role}
                        requestStatus={"Approved"}
                        functions={functions}
                        clubMembers={clubMembers}
                        setClubMembers={(newValue) => setClubMembers(newValue)}
                        initialFunctions={initialFunctions}
                        clubId={clubId}
                        year={year}
                        setCardSummary={(newValue) => setCardSummary(newValue)}
                        cardSummary={cardSummary}
                        // setLicenseFinalRows={(newValue) => setLicenseFinalRows(newValue)}
                      />
                    }
                  </Grid>
                  {clubMembers.length > 0 && <>
                    <Grid item xs={12} align="right">
                      {rows?.length > 0 && <>
                        <br />
                        <Button
                          // disabled={getLicenseCreateProblem() || licenseFinalRows?.length < 1 || disableButton}
                          align="center"
                          color="primary"
                          size="sm"
                          round
                          onClick={() => { formikProps.handleSubmit() }}
                        >
                          <CheckIcon /> Odoslať žiadosť
                        </Button>
                      </>
                      }
                    </Grid>
                  </>
                  }
                  <Grid item xs={12} align="right">
                    {getLicenseCreateProblem() &&
                      <Typography variant="body2" style={{ color: colors.warning, fontSize: "0.8em" }}>
                        * Prosím odstránťe zo zoznamu osoby ktoré nespĺňajú podmienky na vystavenie licencie
                      </Typography>
                    }
                  </Grid>

                </Grid>
              </IconCard>

            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <IconCard title={"Poplatok za karty"} inside>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LicensePaymentCardTable
                    cardSummary={cardSummary}
                    numberOfCard={numberOfCard}
                  />
                </Grid>
              </Grid>
            </IconCard>
          </Grid>
        </Grid>
      </>)
      }
    </Formik >

    <Modal
      title={"Žiadosti o licencie"}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      actions={renderModalButtons()}
      fullWidth
    >
      Naozaj chcete odoslať žiadosť na vydanie licencií?
    </Modal>
  </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseNewCardPage));

//430