import gql from 'graphql-tag';

const CLUB_MEMBERS_SYNC_FAILED = gql`
mutation clubMembersSyncFailed{
  clubMembersSyncFailed 
}
`;

export default CLUB_MEMBERS_SYNC_FAILED;

