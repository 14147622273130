import gql from 'graphql-tag';

const CLUB_MEMBERS_TABLE = gql`
query clubMembersTable($offset: Int, $limit: Int, $sort: [ClubMembersTableSortInput!], $filter: [ClubMembersTableFilterInput]) {
  clubMembersTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      uciId
      club
      businessName
      firstName
      lastName
      birth
      gender
      functions
      personalId
      categories
      license
      issSyncedAt
      clubId
      isLicensePurchasable
    }
  }
}
`;

export default CLUB_MEMBERS_TABLE;
