import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import React, { } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { colors, } from "common/styles/configLayout.js";
import Loading from "components-lib/Loading/Loading";
import legalEntityFormEnum from "common/enums/LegalEntityFormEnum";


const ControlRequestDataCard = (props) => {
  const { 
    intl, 
    formikProps, 
    loading, 
    validateParam, 
    readMode = false, 
    closed = false, 
  } = props;

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormikField
            name="name"
            labelText={<>{intl.formatMessage({ id: 'club.clubCard.clubName' })} *</>}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            disabled={closed}
            readMode={readMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikField
            name="email"
            labelText={<>{"Klubový email"} *</>}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            disabled={closed}
            readMode={readMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'club.legalEntityCard.basicInformation' })}</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name="businessName"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.name' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                highlightChange={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="legalForm"
                type="autocomplete"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.legalForm' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                options={legalEntityFormEnum(intl)}
                highlightChange={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="ico"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.ico' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                type="number"
                highlightChange={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="dic"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.dic' })} *</>}
                readMode={readMode}
                type="number"
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <FormikField
                name="registrationNumber"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistration' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="registrationDate"
                labelText={<>{intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistrationDate' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                //disabled={true}
                type="datePicker"
                disabled={closed}
                readMode={readMode}
                highlightChange={false}
              />
          </Grid>*/}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >{intl.formatMessage({ id: 'club.legalEntityCard.officeAddress' })}</Typography></Grid>
            <Grid item xs={12} sm={8}>
              <FormikField
                name="address1"
                labelText={<>{intl.formatMessage({ id: 'address.street' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="address2"
                labelText={<>{intl.formatMessage({ id: 'address.streetNumber' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={8} >
              <FormikField
                name="city"
                labelText={<>{intl.formatMessage({ id: 'address.city' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="postal"
                labelText={<>{intl.formatMessage({ id: 'address.postal' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="country"
                labelText={<>{intl.formatMessage({ id: 'address.country' })} *</>}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >Korešpondenčná adresa</Typography></Grid>
            <Grid item xs={12} style={{ marginTop: !readMode && "-15px" }}>
              <FormikField
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.correspondenceAddress.checkbox' })}
                type="checkbox"
                name="useCorrespondenceAddress"
                formikProps={formikProps}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            {!formikProps.values.useCorrespondenceAddress &&
              <>
                <Grid item xs={12} sm={8}>
                  <FormikField
                    name="correspondenceAddress1"
                    labelText={intl.formatMessage({ id: 'address.street' })}
                    formikProps={formikProps}
                    highlightChange={false}
                    disabled={closed}
                    readMode={readMode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikField
                    name="correspondenceAddress2"
                    labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                    formikProps={formikProps}
                    highlightChange={false}
                    disabled={closed}
                    readMode={readMode}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormikField
                    name="correspondenceCity"
                    labelText={intl.formatMessage({ id: 'address.city' })}
                    formikProps={formikProps}
                    highlightChange={false}
                    disabled={closed}
                    readMode={readMode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikField
                    name="correspondencePostal"
                    labelText={intl.formatMessage({ id: 'address.postal' })}
                    formikProps={formikProps}
                    highlightChange={false}
                    disabled={closed}
                    readMode={readMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikField
                    name="correspondenceCountry"
                    labelText={intl.formatMessage({ id: 'address.country' })}
                    formikProps={formikProps}
                    highlightChange={false}
                    disabled={closed}
                    readMode={readMode}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </Grid >
    )
  }

  if (loading) return <Loading />

  return (
    <>
      {renderContent()}
    </>
  );
};

export default withRouter(injectIntl(ControlRequestDataCard));
