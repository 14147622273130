import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { changeLicensesNumberAction } from 'redux/actions';
import { getApolloClient } from "configFiles/apollo";
import StatusTextField from "components-lib/StatusTextField/StatusTextField";

const useStyles = makeStyles((theme) => styles(theme))

export const LicenseCardTable = (props) => {
    const { 
        history, 
        queryVariables, 
        role, 
        data, 
        loadData,
        intl, 
        defaultFilter = null, 
        numberOfRows, 
        queryDataPath,
        changeTab,
        setChangeTab } = props
    const classes = useStyles();

    const onCardDetail = (id) => {
        localStorage.setItem('activeTabLicense', 'cardTable');
        history.push(`/admin${generatePath(paths.licenses.detailCard, { licenseCardId: id })}`);
    }

    return (
        <QueryTableRefresh
            nameTable={paths.licenses.list}
            data={data}
            loadData={loadData}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            setChangeTab={setChangeTab}
            changeTab={changeTab}
            columnsWidth={['auto', 'auto', 'auto', 'auto', 'auto', 'auto' ]}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '165px'
                },
                {
                    Header: "Podané",
                    accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY") : "-",
                    sortKey: 'createdAt',
                    filterKey: 'createdAt',
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Klub",
                    accessor: "club",
                    sortKey: 'club',
                    filterKey: 'club',
                    filterOperator: 'contains',
                    // filterComponent: 'title',
                    minWidth: '110px',
                    maxWidth: '100%'
                },
                {
                    Header: "Žiadateľ",
                    accessor: "createdBy",
                    sortKey: "createdBy",
                    filterKey: 'createdBy',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Licencií",
                    accessor: "licenses",
                    sortKey: "licenses",
                    filterKey: 'licenses',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '100px',
                    maxWidth: '120px'
                },
                {
                    Header: 'Stav',
                    accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Pending",
                            label: "čaká na platbu"
                        },
                        {
                            value: "Ready",
                            label: "čaká na výrobu"
                        },
                        {
                            value: "Downloaded",
                            label: "stiahnuté PDF"
                        },
                        {
                            value: "Completed",
                            label: "dokončená"
                        },
                        {
                            value: "Canceled",
                            label: "zrušená"
                        }
                    ],
                    minWidth: '120px',
                    maxWidth: '168px'
                },
                {
                    Header: ' ',
                    filterKey: "actions",
                    accessor: (rowData) =>
                        <Box className={classes.floatRight}>
                            <Button round size="sm" color="info" table onClick={() => onCardDetail(rowData.id)}>Detail</Button>
                        </Box>,
                    minWidth: '77px',
                    maxWidth: '100px'
                }
            ]}
        />
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});


const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseCardTable));
