import React from "react";

import { pathOr } from 'rambda';

// components
import Grid from '@material-ui/core/Grid';
import { Divider, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import FormikField from "components-lib/FormikField/FormikField";

// css
import { colors } from "common/styles/configLayout.js";


const PriceListOthers = ({formikProps, bigScreen, readMode, width, editData}) => {

    return (
        <Box 
            display="flex" flexDirection="column" 
            width={width ? width :"100%"} 
            mt= "1rem"
        >
            {/* Poplatky za licencie klubu */}
            <Box p="0rem 0 1rem 1rem" style={{ visibility: bigScreen ? 'hidden' : 'visible' }}>
                <Typography 
                variant="body1" 
                style={{ color: colors.primary }}
                >
                Ostatné
                </Typography>
            </Box>

            {/* Obnova poplatok a Registrácia poplatok */}
            <Box display="flex" justifyContent="space-between" p="1rem 0 1rem 0">
                {(!bigScreen) && (
                <Box style={{ width: '100%' }}>
                    <Typography 
                    variant="body2" 
                    style={{ color: colors.primary, visibility: 'hidden' }}
                    >
                    hidden
                    </Typography>
                </Box>
                )}

                <Box 
                    style={{ 
                        width: '100%',
                        paddingRight: "1rem",
                        paddingLeft: bigScreen ? "0.5rem" : "0rem",
                    }}>
                    <Typography 
                        variant="body2" 
                        style={{ 
                            color: colors.primary,
                        }}
                    >
                        Poplatok
                    </Typography>
                </Box>
            </Box>

            <Box 
                display="flex" flexDirection='column'
                style={{ height: 'auto', }}
            >
                <Box display="flex" justifyContent="space-between" p="0.6rem 0 0.5rem 0rem" 
                    style={{ height: 'auto', }}
                >
                    {(!bigScreen) && (
                    <Box flex={1} style={{ width: bigScreen ? '33.33%' : '100%' }}>
                        <Typography 
                        variant="body1" 
                        style={{ color: colors.primary }}
                        >
                        Fyzická karta
                        </Typography>
                    </Box>
                    )}

                    <Box flex={1} 
                        style={{ 
                            width: bigScreen ? '33.33%' : '100%',
                            paddingRight: "1rem",
                            paddingLeft: bigScreen ? "0.5rem" : "0rem",
                        }}
                    >
                        <FormikField
                            name={editData ? `others.dataEdit.pricing.others.licenseCardIssueFee` : `others.dataOld.pricing.others.licenseCardIssueFee`}
                            labelText={''}
                            formikProps={formikProps}
                            readMode={readMode ? readMode : 'textFieldInput'}
                            inputHeight="1rem"
                        />
                    </Box>

                </Box>
            </Box>

        </Box>

    )
};

export default PriceListOthers;