import gql from 'graphql-tag';

const PRICING_UPDATE = gql`
mutation pricingUpdate($year: UnsignedInt!, $input: PricingUpdateInput!) {
    pricingUpdate(year: $year, input: $input) {
        year
        clubs {
            type
            license {
                fee
                renewFee
            }
        }
        functions {
            id
            license {
                fee
                expressFee
            }
        }
        categories {
            id
            license {
                standardFee
                uciFee
                transferFee
            }
        }
        others {
            licenseCardIssueFee
        }
    }
  }
`;

export default PRICING_UPDATE;