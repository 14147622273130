import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import { colors, } from 'common/styles/configLayout';
import MEMBER_LICENSE_REQUEST_CONFIRM from 'queries/LicensesQueries/memberLicenseRequestConfirm'
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import { getApolloClient } from "configFiles/apollo";
import Tooltip from '@material-ui/core/Tooltip';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import RoleEnum from "common/enums/RoleEnum";
import { pathOr } from 'rambda';

const useStyles = makeStyles((theme) => styles(theme))

export const LicenseTable = (props) => {
    const { 
        intl, 
        history, 
        changeLicensesNumberAction, 
        data, 
        loadData, 
        refetch, 
        numberOfRows, 
        queryDataPath, 
        defaultFilter = null, 
        queryVariables, 
        role, 
        currentYear,
        changeTab,
        setChangeTab } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [memberLicenseRequestConfirm] = useMutation(MEMBER_LICENSE_REQUEST_CONFIRM)
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const getLicensesNumber = async () => {
        try {
          const result = await client.query({
            query: MEMBER_LICENSE_REQUESTS_TABLE,
            variables: {
              "filter": [
                {
                  "status": {
                    "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
                  }
                }
              ]
            }
          });
          if (result.error) {
            throw result.error;
          }
          if (result.errors) {
            throw result.errors[0];
          }
          changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
        } catch (error) {
          throw error;
        }
    }

    const onLicenseDetail = (id) => {
        localStorage.setItem('activeTabLicense', 'licenseTable');
        history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: id })}`);
    }

    const handleConfirmLicense = (id) => {
        memberLicenseRequestConfirm({
            variables: {
                id: id
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola potvrdená", { variant: 'success' });
            refetch();
            getLicensesNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    return (
        <QueryTableRefresh
            nameTable={paths.licenses.list}
            data={data}
            loadData={loadData}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            setChangeTab={setChangeTab}
            changeTab={changeTab}
            columnsWidth={['fit-content', 'auto', 'auto', 'auto', '10%', '10%', '13%', 'auto']}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '165px',
                },
                {
                    Header: "Podané",
                    accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY") : "-",
                    sortKey: 'createdAt',
                    filterKey: 'createdAt',
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Klub",
                    accessor: "club",
                    sortKey: 'club',
                    filterKey: 'club',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Žiadateľ",
                    accessor: "createdBy",
                    sortKey: "createdBy",
                    filterKey: 'createdBy',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Licencií",
                    accessor: "licenses",
                    sortKey: "licenses",
                    filterKey: 'licenses',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '100px',
                    maxWidth: '120px'
                },
                {
                    Header: "Ročník",
                    accessor: (rowData) => (rowData.year)
                        ? <Box style={{ color: (rowData.year >= currentYear) ? colors.success : '' }} > {rowData.year} </Box>
                        : "-",
                    sortKey: "year",
                    filterKey: "year",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '96px',
                    maxWidth: '100%'
                },
                {
                    Header: 'Stav',
                    accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Pending",
                            label: "čaká na platbu"
                        },
                        {
                            value: "Ready",
                            label: "čaká na výrobu"
                        },
                        {
                            value: "Downloaded",
                            label: "stiahnuté PDF"
                        },
                        {
                            value: "Completed",
                            label: "dokončená"
                        },
                        {
                            value: "Canceled",
                            label: "zrušená"
                        }
                    ],
                    minWidth: '138px',
                    maxWidth: '168px'
                },
                {
                    Header: ' ',
                    filterKey: "actions",
                    accessor: (rowData) =>
                        <Box className={classes.floatRight}>
                            {role === RoleEnum.ADMIN && <>
                                {(rowData.status === "Downloaded") && <Tooltip title={"Označiť ako vytlačené"}><CheckIcon className={classes.circle} onClick={() => handleConfirmLicense(rowData.id)} /></Tooltip>}
                            </>}
                            <Button round size="sm" color="info" table onClick={() => onLicenseDetail(rowData.id)}>Detail</Button>
                        </Box>,
                    minWidth: '60px',
                    maxWidth: '150px'
                }
            ]}
        />
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});


const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseTable));
