import React, { useState, createRef } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";

export default function ImageUpload(props) {
  const { hideButtons = false, photo = false, callbackFile, refetch = false } = props;
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(photo ? photo : defaultImage);
  let fileInput = createRef();


  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      callbackFile(file);
    };
    if (file) {
      reader.readAsDataURL(file);
          }
    //callbackFile(file);
    //callbackFile(file);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
    //callbackFile([null]);
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail"}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      {hideButtons === false &&
        < div >
          {file === null ? (
            <Button color="primary"
              size="sm"
              round
              table
              onClick={() => handleClick()}>
              {props.addButton}
            </Button>
          ) : (
            <span>
              <Button color="primary"
                size="sm"
                table
                round onClick={() => handleClick()}>
                {/*<EditIcon />*/} {props.addButton}
              </Button>
              {/*<Button color="danger"
                size="sm"
                round onClick={() => handleRemove()}>
                <DeleteIcon />
          </Button>*/}
            </span>
          )}
        </div>
      }
    </div>
  );
}

ImageUpload.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
