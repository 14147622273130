import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import IconCard from 'components-lib/IconCard/IconCard';
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { colors, parameters } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import FormikField from "components-lib/FormikField/FormikField";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import moment from "moment";
import WarningIcon from '@material-ui/icons/Warning';
import { getValidationSchemaRequestReview } from "../../Requests/validationSchema";

const useStyles = makeStyles((theme) => styles(theme))


const CommentsCard = (props) => {
    const { formikProps, intl, view, comment, handleClubRegisterRequestReview, disableButton } = props;
    const classes = useStyles();
    const [validateParam, setValidateParam] = useState(false)


    const getCommentHistory = () => {
        return (
            <>
                {Object.values(comment)?.slice(0).reverse().map((item) =>
                    <Grid container>
                        <Grid item xs={2} sm={1} lg={2} >
                            <Avatar alt={item?.person?.profile?.photoUrl} src={item?.person?.profile?.photoUrl} fullWidth style={{ display: 'inline-block' }} />
                        </Grid>
                        <Grid item xs={10} sm={11} lg={10} style={{ backgroundColor: colors.backgroundReadInputs, padding: "8px", marginLeft: "-13px", borderRadius: parameters.readInputsRounded }}>
                            <Box className={classes.floatRight}>
                                <Typography variant="body1" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{moment(item.createdAt).format("DD.MM.YYYY HH:mm")}</Typography>
                            </Box>
                            <Typography variant="body1" style={{ color: colors.warning, fontSize: "0.95em" }}>{item?.person?.profile?.firstName} {item?.person?.profile?.lastName} </Typography>
                            <Typography variant="body2" style={{ color: colors.textReadInputs, fontSize: "0.9em" }}>
                                {item.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ margin: "-7px" }}>&nbsp;</Grid>
                    </Grid >

                )}

            </ >
        )
    }

    return (<>

        {(view === 2 || view === 1) && <>
            {(comment?.length > 0) &&
                <IconCard inside title={<span style={{ color: colors.warning }}>{intl.formatMessage({ id: 'club.commentsCard.title' })}</span>}>
                    <Typography style={{ top: 0, color: colors.labelAndBorderInputs, fontSize: "0.9em" }} variant="body2">
                        {intl.formatMessage({ id: 'club.commentsCard.body' })}
                    </Typography>
                    <br />
                    {getCommentHistory()}
                </IconCard>
            }
        </>
        }
        {view === 4 &&
            <>
                <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize
                    validationSchema={getValidationSchemaRequestReview(intl)}
                    onSubmit={(values) => { handleClubRegisterRequestReview(values, formikProps?.values) }}
                    initialValues={{}}
                >
                    {(fProps) => (<>
                        <IconCard inside title={<span style={{ color: '#FDA92D' }}>Vrátiť na doplnenie</span>}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormikField
                                        labelText="Popíšte konktrétne nedostatky žiadosti"
                                        name="description"
                                        formikProps={fProps}
                                        validateParam={validateParam}
                                        highlightChange={false}
                                        inputHeight= "2.2rem"
                                        // type= "textArea"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Box className={classes.floatRight}>
                                        <Button
                                            align="center"
                                            color="warning"
                                            disabled={disableButton}
                                            size="sm"
                                            table
                                            round
                                            onClick={() => { setValidateParam(true); (fProps?.isValid) && fProps.handleSubmit() }}
                                        >
                                            <WarningIcon /> Vrátiť na doplnenie
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </IconCard>
                    </>)
                    }
                </Formik >

                <Accordion style={{ backgroundColor: colors.backgroundWidgets, marginTop: "20px", borderRadius: "16px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ color: '#FDA92D' }}> História pripomienok</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {(comment?.length > 0)
                                    ? getCommentHistory()
                                    : <Typography style={{ top: 0, color: colors.labelAndBorderInputs, fontSize: "0.9em", paddingTop: "-20px", textAlign: "center" }} variant="body2">
                                        História pripomienok je prázdna
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </>
        }
    </>
    )
}

export default injectIntl(CommentsCard);