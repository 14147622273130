import gql from 'graphql-tag';

const CLUBS_TABLE= gql`
query clubsTable($offset: Int, $limit: Int, $sort: [ClubsTableSortInput!], $filter: [ClubsTableFilterInput]) {
  clubsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
       	id
        name
        type
        businessName
        ico
        statutory
        president
        hasAttendedConference
        license
        createdAt
        updatedAt
        deletedAt
      }
  }
}
`;

export default CLUBS_TABLE;