import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { colors } from 'common/styles/configLayout';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from 'components-lib/FormikField/styles'
import { getApolloClient } from "configFiles/apollo";
import CLUB_MEMBERS_PUBLIC from "queries/MembersQueries/clubMembersPublic";
import { useSnackbar } from 'notistack';
import _ from 'lodash'

// css
import '../../../assets/css/transfers.css'

const MemberIdCard = (props) => {
  const { intl, history, formikProps, type, validateParam, handleSetMember } = props;
  const _ = require('lodash');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);
  const [filterClubMembers, setFilterClubMembers] = useState([]);

  const [value, setValue] = useState(false);

  const handleChangeTo = (value) => {
    handleSetMember(value?.value)
  }

  const getClubMembers = async () => {
    if (value?.length > 3) {
      try {
        const result = await client.query({
          query: CLUB_MEMBERS_PUBLIC,
          variables: {
            "limit": 25,
            "filter": [
              {
                "fullName": {
                  "contains": value
                },
                "license": {
                  isNull: (type === 'hosting') ? false : undefined
                },
                "isFunctionary": {
                  eq: false
                }
              },
              {
                "uciId": {
                  "contains": value
                },
                "license": {
                  isNull: (type === 'hosting') ? false : undefined
                },
                "isFunctionary": {
                  eq: false
                }
              }
            ]
          }
        });
        if (result.error) {
          throw result.error;
        }
        if (result.errors) {
          throw result.errors[0];
        }

        let clubMembersSelectItems = (result?.data?.clubMembersPublic?.items)
          .filter(item => (item.functions)?.find(i => (i.function.id !== "2" && i.function.id !== "1")))
          .map(
            (item) => {
              return {
                value: item,
                label: `${item?.fullName} (UCI ID: ${(item?.uciId) ? item.uciId : "-"}, Osobné číslo: ${(item?.federationId) ? item?.federationId : "-"})`,
              };
            });
        setFilterClubMembers(clubMembersSelectItems);
      } catch (error) {
        throw error;
      }
    }
    else {
      setFilterClubMembers([])
    }
  };

  useEffect(() => {
    getClubMembers();
  }, [value]);


  const renderContent = () => {
    return (
      <Grid 
        // container 
        spacing={1}
        className="memberIdCardContainer"
      >
        <Grid item 
        // xs={12} md={6}
          className= "identifyMemberContainer"
        >
          <IconCard inside>
            <Grid container spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.idCard.identifyMember' })}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ borderRadius: '6px' }}
                  className={classes.root}
                  classes={{
                    paper: classes.selectAutocomplete,
                    clearIndicatorDirty: classes.selectIcon,
                    popupIndicator: classes.selectIcon
                  }}
                  disabled={false}
                  size="small"
                  id="combo-box-demo"
                  noOptionsText={intl.formatMessage({ id: 'select.dropdown.noOptions' })}
                  options={filterClubMembers}
                  disableClearable
                  filterSelectedOptions
                  fullWidth
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => <><TextField {...params} label={intl.formatMessage({ id: 'transfersAndHosting.idCard.selectMember' })} variant="outlined" />{setValue(params.inputProps.value)}</>}
                  onChange={(event, value) => { handleChangeTo(value) }}
                  variant="outlined"
                  defaultValue="Success"
                  renderOption={(option) => (<div><p>{option.label}</p></div>)}
                  inputProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  name={"clubMember"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
            </Grid>
          </IconCard>
        </Grid>
        <Grid 
          className= "dataCardContainer"
          // item xs={12} md={6}
        >
          <IconCard inside>
            <Grid container spacing={1}>
              <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>
                {intl.formatMessage({ id: 'transfersAndHosting.idCard.dateInfo' }, [(type === 'hosting') ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.hosting' }) : intl.formatMessage({ id: 'transfersAndHosting.dataCard.transfer' })])}
              </Typography>
              </Grid>
              <Grid item xs={12} lg={(type === 'hosting') ? 6 : 12}>
                <FormikField
                  name="from"
                  labelText={(type === 'transfer')
                    ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.dateInfo' }, [(type === 'hosting') ? intl.formatMessage({ id: 'transfersAndHosting.dataCard.hosting' }) : intl.formatMessage({ id: 'transfersAndHosting.dataCard.transfer' })])
                    : intl.formatMessage({ id: 'transfersAndHosting.dataCard.startDate' })
                  }
                  type="datePicker"
                  formikProps={formikProps}
                  validateParam={validateParam}
                  highlightChange={false}
                />
              </Grid>
              {(type === 'hosting') &&
                <Grid item xs={12} lg={6}>
                  <FormikField
                    name="to"
                    labelText={intl.formatMessage({ id: 'transfersAndHosting.dataCard.endDate' })}
                    type="datePicker"
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={false}
                  />
                </Grid>
              }
            </Grid>
          </IconCard>
        </Grid>
      </Grid >
    )
  }

  return (
    <>
      {renderContent()}
    </>
  );
};

export default withRouter(injectIntl(MemberIdCard));
