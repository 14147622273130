import gql from 'graphql-tag';

export const PRICING = gql`
query pricing($year: UnsignedInt!) {
    pricing(year: $year) {
        year
        clubs {
            type
            license {
            fee
            renewFee
            }
        }
        functions {
            id
            function
            license {
                fee
                expressFee
            }
        }
        categories {
            id
            category
            group
            availableForClubs
            license {
                standardFee
                uciFee
                transferFee
            }
        }
        others {
            licenseCardIssueFee
        }
    }
}
`;

export const CLUBS_PRICING = gql`
query clubsPricing($year: UnsignedInt!) {
    pricing(year: $year) {
        clubs {
            type
            license {
                fee
                renewFee
            }
        }
    }
}`;

export const FUNCTIONS_PRICING = gql`
query functionsPricing($year: UnsignedInt!) {
    pricing(year: $year) {
        functions {
            id
            function
            license {
                fee
                expressFee
            }
        }
    }
}`;

export const CATEGORIES_PRICING = gql`
query categoriesPricing($year: UnsignedInt!) {
    pricing(year: $year) {
        categories {
            id
            category
            group
            availableForClubs
            license {
                standardFee
                uciFee
                transferFee
            }
        }
    }
}`;

export const OTHERS_PRICING = gql`
query othersPricing($year: UnsignedInt!) {
    pricing(year: $year) {
        others {
            licenseCardIssueFee
        }
    }
}`;

