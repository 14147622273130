/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import { Grid } from "@material-ui/core";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import { map, find, pathOr } from "rambda";
import { injectIntl } from 'react-intl';
import TitleDatum from 'components/TitleDatum/TitleDatum';
import { DateRangePicker } from "components/DateRangePicker"

//For Modal
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import { colors, parameters } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import materialTheme from 'components-lib/FormikField/muiDatePicker'
import moment from 'moment';
import useStylesPicker from './styles'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles'
import LockIcon from '@material-ui/icons/Lock';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";

// css
import '../../assets/css/inputSelect.css'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: colors.primary,
    paddingTop: 0,
    marginTop: "-10px",
    paddingBottom: 0,
    borderBottomColor: colors.strokeColor,
  },
  body: {
    backgroundColor: 'transparent',
    color: colors.textBody,
    marginLeft: 0,
    borderBottomColor: colors.strokeColor,
    fontSize: 12,
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "1px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {

    '&:hover': {
      backgroundColor: colors.tableWidgetsHover,
    },
  },
}))(TableRow);




// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: filterValue || "",
        onChange: e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function Tables({ 
  columns, 
  data, 
  onPageChange, 
  onRowsCountChange, 
  currentPage, 
  rowsPerPage, 
  totalCount, 
  filters, 
  handleFilterChange, 
  intl, 
  sorts, 
  handleSortChange, 
  filterView, 
  paginateView, 
  columnsWidth}) {

  const [numberOfRows, setNumberOfRows] = React.useState(rowsPerPage);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const classes = useStyles();
  const classesPicker = useStylesPicker();
  //MILAN START
  const [startValue, setStartValue] = React.useState(null);
  const [endValue, setEndValue] = React.useState(null);
  const [columnFilter, setColumnFilter] = React.useState(" ");
  const [columnOperator, setColumnOperator] = React.useState(" ");
  //For modal
  const [assignModalOpen, setAssignModalOpen] = React.useState(false);
  const [style, setStyle] = React.useState({ backgroundColor: 'red', zIndex: 10000 });
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [value, setValue] = React.useState([null, null]);

  const [currentFilterKey, setCurrentFilterKey] = React.useState('');

  const toggle = () => setOpen(!open);


  const handleModalOpen = (filter, operator) => {
    setColumnFilter(filter);
    setColumnOperator(operator);
    setAssignModalOpen(true)
  };

  //MILAN END

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );




  const handlePageChange = (count) => {
    setPageNumber(pageNumber + count);
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: numberOfRows, pageIndex: 0 }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  // let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
  //   function () { }
  // );

  //let numberOfRowsData = (rowsPerPage === 25) ? [25, 50, 100] : [5, 10, 25]

  let numberOfRowsData = [25, 50, 100]; //TODO - just for 25 rows per page

  const handleParseStartValue = (value, column) => {
    setStartValue(value)
    let start = moment(value).format("DD.MM.YYYY")
    //handleFilterChange(columnFilter, start, moment(endValue).format("DD.MM.YYYY"), column.filterDataType, columnOperator)
  }

  const handleParseEndValue = (value, column) => {
    setEndValue(value)
    let end = moment(value).format("DD.MM.YYYY")
    //handleFilterChange(columnFilter, moment(startValue).format("DD.MM.YYYY"), end, column.filterDataType, columnOperator)
  }

  const handleOpenDatePicker = (filterKey) => {
    setCurrentFilterKey(filterKey);
    setOpen(true)
  }

  const renderModalButtons = (columnFilter, columnFilterDataType, columnOperator) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => {
            handleFilterChange(columnFilter, moment(dateRange.startDate).format("YYYY-MM-DD"), moment(dateRange.endDate).format("YYYY-MM-DD"), columnFilterDataType, columnOperator)
            setOpen(false)
          }}
          color="primary"
          round
          table
          size="sm">
          Zobraziť dáta
        </Button>
      </>
    );
  };

  return (<>
    <TableContainer>
      <Table aria-label="simple table" style={{ width: "100%" }}>
        <colgroup>
          {columnsWidth?.map(item =>
            <col style={{ width: item }} />
          )}
        </colgroup>
        <TableHead style={{ borderRadius: parameters.tablesRounded }}>
          {headerGroups.map((headerGroup, key) => (
            <TableRow style={{ borderRadius: parameters.tablesRounded }} key={key}>
              {headerGroup.headers.map((column, key) => {
                column.filterComponent = pathOr('input', ['filterComponent'], column);
                column.canFilter = !!pathOr(false, ['filterKey'], column);
                column.canSort = !!pathOr(false, ['sortKey'], column);
                const activeSortDirection = pathOr('', ['order'], find((sort) => sort.field === column.sortKey, sorts))
                column.activeSortDirection = activeSortDirection;
                column.canSort = !!pathOr(false, ['sortKey'], column);
                const cursorPointer = column.canSort ? '-cursor-pointer' : '';
                return (
                  <StyledTableCell
                    style={{
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      borderTopLeftRadius: (key === 0) && parameters.tablesRounded,
                      borderBottomLeftRadius: (key === 0) && parameters.tablesRounded,
                      borderTopRightRadius: (key === headerGroup.headers.length - 1) && parameters.tablesRounded,
                      borderBottomRightRadius: (key === headerGroup.headers.length - 1) && parameters.tablesRounded
                    }}
                    key={key}
                    className={classnames(`rt-th rt-resizable-header ${cursorPointer}`, {
                      "-cursor-pointer": column.canSort && !column.activeSortDirection,
                      "-sort-asc": activeSortDirection === 'ASC',
                      "-sort-desc": column.canSort && activeSortDirection === 'DESC'
                    })}
                  >
                    <div
                      style={{ margin: '4px 0px 12px 0px' }}
                    >
                      {filterView &&
                        (column.filterKey === "actions" || column.filterKey === "none") ?
                          <>
                            <div
                              style={{
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth
                              }}
                            >
                              <Typography 
                                style={{ color: colors.textBody }}></Typography>
                            </div>
                          </>
                        : <>
                          {column.canFilter && column.filterComponent === 'input' && (
                            <div className={classes.inputContainer}
                              style={{
                                minWidth: column.minWidth, 
                                maxWidth: column.maxWidth
                              }}
                            >
                              <span className={classes.input}
                                style={{ width:'100%' }}
                              >
                                <TextField
                                  className={classes.root}
                                  size="small"
                                  style={{ width:'100%' }}
                                  label={
                                    <span 
                                      style={{ 
                                        fontSize: "0.8em",
                                      }}
                                    >{column.render("Header")}</span>
                                  }
                                  variant="outlined"
                                  defaultValue="Success"
                                  id="validation-outlined-input"
                                  InputLabelProps={{
                                    style: {
                                      classes: {
                                        root: classes.inputLabel,
                                      },
                                    }
                                  }}

                                  inputProps={{
                                    value: pathOr(
                                      '',
                                      [column.filterKey, column.filterOperator],
                                      filters
                                    ),
                                    style: {
                                      fontSize: "0.8rem",
                                     },
                                    onChange: e => {
                                      handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator);
                                      onPageChange(0);
                                      handlePageChange(0);
                                    },
                                    placeholder: "Vyhľadať",
                                  }}
                                  name={name}
                                />
                              </span>
                              <div
                                className={classes.iconHover}
                                onClick={(e) => {
                                  handleSortChange(column.sortKey, column.canSort);
                                  onPageChange(0);
                                  handlePageChange(0);
                                }}
                              >
                                {/*<i style={{ fontSize: 20 }} className="fas fa-sort"></i>*/}
                                <div className={classes.icon}>
                                  <div>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                  </div>
                                  <div style={{ marginTop: '-20px' }}>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {column.canFilter && column.filterComponent === 'dateRange' && (
                            <div className={classes.inputContainer}
                              style={{
                                minWidth: column.minWidth, 
                                maxWidth: column.maxWidth
                              }}
                            >
                              <TitleDatum
                                inputLabel={column.Header}
                                filterKey={column.filterKey}
                                filterOperator={column.filterOperator}
                                filterDataType={column.filterDataType}
                                filters={filters}
                                onOpenDatePicker={handleOpenDatePicker}
                                handleFilterChange={handleFilterChange}
                              />
                              <div
                                className={classes.iconHover}
                                onClick={(e) => {
                                  handleSortChange(column.sortKey, column.canSort);
                                  onPageChange(0);
                                  handlePageChange(0);
                                }}
                                style={{
                                  display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}
                              >
                                <div className={classes.icon}>                                  
                                  <div>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                  </div>
                                  <div style={{ marginTop: '-20px' }}>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                  </div>
                                </div>
                              </div>

                              <Modal
                                title={`Zvoľte obdobie za ktoré chcete zobraziť dáta.`}
                                open={open}
                                onClose={() => setOpen(false)}
                                actions={renderModalButtons(currentFilterKey, column.filterDataType, column.filterOperator)}
                                fullWidth
                                maxWidth='md'
                              >
                                <div className={classes.root}>
                                  <DateRangePicker
                                    open={true}
                                    toggle={toggle}
                                    maxDate={new Date()}
                                    onChange={(range) => setDateRange(range)}
                                  />

                                </div>
                              </Modal>

                            </div>
                          )}
                          
                          {column.canFilter && column.filterComponent === 'title' && (
                            <div className={classes.inputContainer}
                              style={{
                                minWidth: column.minWidth, 
                                maxWidth: column.maxWidth, 
                                justifyContent: "space-between",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              <div
                                  className={column.render("Header")}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    right: "0",
                                    left: "unset",
                                    fontSize: "1.25rem",
                                    paddingRight: "0.5rem",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderBottom: "0",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      textAlign: "left",
                                      fontSize: "0.8rem",
                                      color: "#E1ECFA",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div className='titleTableText' >{column.render("Header")}</div>
                                  </div>
                                </div>
                              <div
                                className={classes.iconHover}
                                onClick={(e) => {
                                  handleSortChange(column.sortKey, column.canSort);
                                  onPageChange(0);
                                  handlePageChange(0);
                                }}
                              >
                                <div className={classes.icon}>
                                  <div>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                  </div>
                                  <div style={{ marginTop: '-20px' }}>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {column.canFilter && column.filterComponent === 'select' && (<>
                            <div className={classes.inputContainer} style={{ width: "100%", minWidth: column.minWidth, maxWidth: column.maxWidth }} >
                              <span className={classes.input} style={{ width: "100%" }}>
                                <FormControl size="small" className={classes.root} style={{ width: "100%" }} fullWidth variant="outlined" >
                                  <InputLabel htmlFor="outlined-age-native-simple" style={{ color: colors.textBody }}>{<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}</InputLabel>
                                  <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    style={{ fontSize: "0.8rem" }}
                                    label={<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}
                                    value={pathOr('', [column.filterKey, column.filterOperator], filters)}
                                    onChange={(e) => {
                                      handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator),
                                      onPageChange(0),
                                      handlePageChange(0)
                                    }
                                    }
                                    inputProps={{
                                      classes: { icon: classes.selectIcon }
                                    }}                                  >
                                    <MenuItem
                                      value={0}
                                      className="inputSelect"
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'queryTable.select.all' })}
                                    </MenuItem>
                                    {map((option, index) =>
                                      <MenuItem
                                        key={`filter.${column.filterKey}.${index}`}
                                        value={option.value}
                                        className="inputSelect"
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                      >
                                        {option.label}
                                      </MenuItem>
                                      , column.filterOptions || [])}
                                  </Select>
                                </FormControl>
                              </span>
                              <div
                                className={classes.iconHover}
                                onClick={(e) => {
                                  handleSortChange(column.sortKey, column.canSort);
                                  onPageChange(0);
                                  handlePageChange(0);
                                }}
                              >
                                {/*<i style={{ fontSize: 20 }} className="fas fa-sort"></i>*/}
                                <div className={classes.icon}>
                                  <div>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                  </div>
                                  <div style={{ marginTop: '-20px' }}>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          )}                          
                        </>
                      }
                    </div>
                  </StyledTableCell>
                )
              })}
            </TableRow >
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTableRow key={i}>
                {
                  row.cells.map((cell, key) => {
                    return (
                      <StyledTableCell
                        key={key}
                      >

                        <span style={(cell?.column?.Header === ' ') ? style : undefined}>
                          <span>
                            {cell.render("Cell")}
                          </span>
                        </span>
                      </StyledTableCell>
                    );
                  })
                }
              </StyledTableRow >
            );
          })}
        </TableBody >
      </Table >
    </TableContainer>
    {paginateView &&
      <div className={classes.root} style={{ marginBottom: '-11px', marginTop: '3px' }}>
        <TablePagination
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            MenuProps: { classes: { paper: classes.selectDropdown } }
          }}
          classes={{
            menuItem: classes.menuItem,
            select: classes.select,
            selectIcon: classes.selectIcon,
            caption: classes.caption,
          }}
          style={{ color: colors.textBody, fontSize: "12px" }}
          //classes={{ selectIcon: classes.select, paper: classes.selectDropdown }}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: { color: currentPage === 0 ? "#b5b8c4" : colors.textBody },
            autoid: "pagination-button-next-collector",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: { color: currentPage === Math.ceil(totalCount / rowsPerPage) - 1 ? "#b5b8c4" : colors.textBody },
            autoid: "pagination-button-previous-collector",
          }}
          autoid="invoice-table-pagination-collector"

          labelRowsPerPage={intl.formatMessage({ id: 'tableTexts.rowsPerPage' })}
          //labelRowsPerPage="Rows per page:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'tableTexts.of' })} ${count !== -1 ? count : `${intl.formatMessage({ id: 'tableTexts.moreThan' })} ${to}`}`}
          rowsPerPageOptions={numberOfRowsData}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          //className={classes.paginationBullet}
          page={currentPage}
          // ActionsComponent={CustomPaginationActions}
          onChangePage={(e, newPageNumber) => {
            onPageChange(newPageNumber);
            handlePageChange(newPageNumber);
          }}
          onChangeRowsPerPage={(e) => {
            onRowsCountChange(e.target.value);
            setNumberOfRows(e.target.value);
            setPageSize(e.target.value);
            onPageChange(0);
            handlePageChange(0);
          }}
        />
      </div>
    }
  </>
  );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";

export default injectIntl(Tables);

