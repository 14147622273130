import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import { colors } from "common/styles/configLayout.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import FormikField from "components-lib/FormikField/FormikField";
import { Formik } from "formik";
import FunctionsTable from 'pages/Profile/DetailCards/FunctionsTable'
import CLUB_FUNCTIONS from "queries/ClubsQueries/clubFunctions";
import DISCIPLINES from "queries/ClubsQueries/disciplines";
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/client';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import { generatePath } from 'react-router-dom';
import paths from 'paths';
import _ from 'lodash';
import Loading from "components-lib/Loading/Loading";
import { useSnackbar } from 'notistack';
import FunctionsEnum from 'common/enums/FunctionsEnum';

// css
import '../../../assets/css/others.css';

const useStyles = makeStyles((theme) => styles(theme))

const ClubCard = (props) => {
    const { intl, userData, history, hosting, disableButton, functionData, formikProps, membersCount, memberFunctions, setMemberFunctions, memberDisciplines, setMemberDisciplines, readMode, setReadMode, profile = false, isSuspended, federationId, refetchData } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { data: functionsData, loading: functionsLoading } = useQuery(CLUB_FUNCTIONS);
    const { data: disciplinesData } = useQuery(DISCIPLINES);

    const [functions, setFunctions] = useState([]);
    const [disciplines, setDisciplines] = useState([]);

    useEffect(() => {
        let functionsSelectItems = pathOr([], ["clubFunctions", "items"], functionsData).filter(i => (i.id !== "1" && i.id !== "2")).reverse().map(
            (f) => {
                return {
                    value: f.id,
                    label: f.name
                };
            }
        );
        setFunctions(functionsSelectItems);
    }, [functionsData]);

    useEffect(() => {
        let disciplinesSelectItems = pathOr([], ["disciplines", "items"], disciplinesData).map(
            (d) => {
                return {
                    value: d.id,
                    label: d.name
                };
            }
        );
        setDisciplines(disciplinesSelectItems);
    }, [disciplinesData]);

    useEffect(() => {
        let tempMemberFunctions = [];
        let tempMemberDisciplines = [];
        tempMemberFunctions = functionData.map((item) => {
            return {
                value: item?.function?.id,
                label: item?.function?.name,
            }
        });

        let disc = functionData?.find(item => item?.function?.id === FunctionsEnum.RACER.value || item?.function?.id === FunctionsEnum.RACER_WITH_CONTRACT.value)?.disciplines
        tempMemberDisciplines = disc?.map(item => {
            return {
                value: item?.id,
                label: item?.name,
            }
        })

        setMemberFunctions(tempMemberFunctions);
        setMemberDisciplines(tempMemberDisciplines);
    }, [functionData]);

    const getDisabledFunctions = () => {
        let disabledFunctions = [...memberFunctions]
        if (memberFunctions?.find(item => item.value === FunctionsEnum.RACER_WITH_CONTRACT.value))
            disabledFunctions.push(FunctionsEnum.RACER)
        if (memberFunctions?.find(item => item.value === FunctionsEnum.RACER.value))
            disabledFunctions.push(FunctionsEnum.RACER_WITH_CONTRACT)
        return disabledFunctions
    }

    if (functionsLoading) return <Loading />;
    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Formik
                    //onSubmit={(values) => handleMemberUpdate(values)}
                    initialValues={{ ...userData, clubMembers: membersCount, isSuspended: (isSuspended) ? intl.formatMessage({ id: 'member.clubCard.membership.stopped' }) : intl.formatMessage({ id: 'member.clubCard.membership.active' }), federationId: federationId }}
                    enableReinitialize
                >
                    {(formikProps) => (
                        <>
                            <IconCard title={userData?.profile?.name}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <div className="fileinput text-center">
                                                    <div className={"thumbnail"}>
                                                        <img src={(userData?.profile?.logoUrl) ? (userData?.profile?.logoUrl) : defaultAvatar} />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <FormikField
                                                    name="bid"
                                                    labelText={intl.formatMessage({ id: 'member.clubCard.bid' })}
                                                    formikProps={formikProps}
                                                    readMode={true}
                                                    highlightChange
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikField
                                                    name="type"
                                                    type="select"
                                                    labelText={intl.formatMessage({ id: 'member.clubCard.type' })}
                                                    formikProps={formikProps}
                                                    readMode={true}
                                                    highlightChange
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikField
                                                    name="clubMembers"
                                                    labelText={intl.formatMessage({ id: 'member.clubCard.clubMembers' })}
                                                    formikProps={formikProps}
                                                    readMode={true}
                                                    highlightChange
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={5}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <FormikField
                                                    name="isSuspended"
                                                    labelText={intl.formatMessage({ id: 'member.clubCard.membership' })}
                                                    formikProps={formikProps}
                                                    readMode={true}
                                                    highlightChange
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Box className={classes.readOnlyGrid}>
                                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'member.clubCard.hosting' })}</Typography>
                                                    <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                                                        {(hosting) ? <>{intl.formatMessage({ id: 'member.clubCard.hosting.active' })} {hosting}</> : intl.formatMessage({ id: 'member.clubCard.hosting.without' })}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Box className={classes.floatRight} >
                                            <Button
                                                color="primary"
                                                size="sm"
                                                table
                                                round
                                                onClick={() => history.push(`/admin${generatePath(paths.clubs.detail, { clubId: userData?.id })}`)}
                                            >
                                                <DirectionsBikeIcon /> {intl.formatMessage({ id: 'profile.clubCard.button.detail' })}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </IconCard>
                        </>
                    )}
                </Formik >
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <IconCard title={intl.formatMessage({ id: 'functions.title' })}
                    className='memberFunctionsTable'
                    margin= {'17px 0 -13px 0'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FunctionsTable
                                formikProps={formikProps}
                                functions={functions}
                                setFunctions={(newValue) => setFunctions(newValue)}
                                disciplines={(_.xor(disciplines, memberDisciplines))}
                                memberFunctions={memberFunctions}
                                defaultDisabledFunctions={getDisabledFunctions()}
                                memberDisciplines={memberDisciplines}
                                setMemberFunctions={(newValue) => setMemberFunctions(newValue)}
                                setMemberDisciplines={(newValue) => setMemberDisciplines(newValue)}
                                readMode={(profile) ? true : readMode}
                                memberDetail={true}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            {!profile &&
                                <Box className={classes.floatRight}>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        width='8.7rem'
                                        marginTop= '0.4rem'
                                        table
                                        round
                                        onClick={() => {
                                            (readMode)
                                                ? setReadMode(readMode ? false : true)
                                                : (formikProps?.isValid)
                                                    ? formikProps.handleSubmit()
                                                    : enqueueSnackbar(intl.formatMessage({ id: 'member.clubCard.snackbar.warning' }), { variant: 'warning' })
                                        }}
                                    >
                                        {(readMode)
                                            ? <><EditIcon /> {intl.formatMessage({ id: 'functions.button.change' })}</>
                                            : <><CheckIcon /> {intl.formatMessage({ id: 'button.saveChanges' })}</>
                                        }
                                    </Button>
                                    {!readMode &&
                                        < Button
                                            size="sm"
                                            table
                                            round
                                            width='8.7rem'
                                            marginTop= '0.4rem'
                                            disabled={disableButton}
                                            onClick={() => { formikProps.resetForm(); setReadMode(readMode ? false : true) }}
                                        >
                                            <BlockIcon /> {intl.formatMessage({ id: 'button.cancelChanges' })}
                                        </Button>
                                    }
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </IconCard>
            </Grid >
        </Grid >
    )
}

export default injectIntl(ClubCard);