import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import { colors, } from 'common/styles/configLayout';
import EditIcon from '@material-ui/icons/Edit';
import PROFILE_UPDATE from 'queries/ProfileQueries/profileUpdate'
import { useMutation } from '@apollo/client';
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import DOCUMENT_APPROVE from 'queries/DocumentsQueries/documentApprove';
import DOCUMENT_REJECT from 'queries/DocumentsQueries/documentReject';
import { connect } from 'react-redux';
import { changeDocumentsNumberAction } from 'redux/actions';
import DOCUMENTS_TABLE from 'queries/DocumentsQueries/documentsTable';
import { getApolloClient } from "configFiles/apollo";
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { pathOr } from 'rambda';
import { getValidationSchemaDocumentsDetail } from "pages/Documents/validationSchema";
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme))

const CompareClubDocumentsCard = (props) => {
    const { intl, history, role, changeDocumentsNumberAction, clubData, documentData, refetch, onClose, closed, documentId, documentStatus, textColor, type, callbackDocumentReject, callbackDocumentApprove } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [readMode, setReadMode] = useState(true);
    const [disableButton, setDisableButton] = useState(false);
    const [documentApprove] = useMutation(DOCUMENT_APPROVE);
    const [documentReject] = useMutation(DOCUMENT_REJECT);
    const [buttonStatus, setButtonStatus] = useState(null);

    const getDocumentsNumber = async () => {
        try {
            const result = await client.query({
                query: DOCUMENTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": "Awaiting"
                            },
                            "relation": {
                                "ne": "Request"
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
        } catch (error) {
            throw error;
        }
    }

    const handleDocumentApprove = (value) => {
        if (type === "request") {
            callbackDocumentApprove();
            onClose();
        } else {
            setDisableButton(true);
            documentApprove({
                variables: {
                    id: documentId,
                    input: {
                        validFrom: value?.validFrom,
                        validTo: value?.validTo
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.approve" }), { variant: 'success' });
                onClose();
                refetch();
                getDocumentsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
    }

    const handleDocumentReject = () => {
        if (type === "request") {
            callbackDocumentReject();
            onClose();
        } else {
            setDisableButton(true);
            documentReject({
                variables: {
                    id: documentId,
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.reject" }), { variant: 'success' });
                onClose();
                refetch();
                getDocumentsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
    }

    return (
        <Formik
            //onSubmit={(values) => handleProfileUpdate(values)}
            initialValues={{ ...clubData, ...documentData }}
            validationSchema={getValidationSchemaDocumentsDetail(intl)}
            enableReinitialize
            onSubmit={(value) => (buttonStatus === "Approve")
                ? handleDocumentApprove(value)
                : handleDocumentReject(value)
            }
        >
            {(formikProps) => (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {(type !== "request") && <>
                                    <Grid item xs={12} md={6}>
                                        <FormikField
                                            name="validFrom"
                                            labelText="Platnosť od"
                                            type="datePicker"
                                            formikProps={formikProps}
                                            readMode={(role === RoleEnum.ADMIN && documentStatus === "Awaiting") ? false : true}
                                            highlightChange
                                            validateParam={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormikField
                                            name="validTo"
                                            labelText="Platnosť do"
                                            type="datePicker"
                                            formikProps={formikProps}
                                            readMode={(role === RoleEnum.ADMIN && documentStatus === "Awaiting") ? false : true}
                                            highlightChange
                                            validateParam={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormikField
                                            name="createdAt"
                                            labelText="Nahrané"
                                            type="dateTimePicker"
                                            formikProps={formikProps}
                                            readMode={readMode}
                                            highlightChange
                                        />
                                    </Grid>
                                </>
                                }
                                <Grid item xs={12}><Typography variant="body1" style={{ color: colors.textBody }}>Klub</Typography></Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormikField
                                        name="name"
                                        labelText={intl.formatMessage({ id: 'club.clubCard.clubName' })}
                                        formikProps={formikProps}
                                        readMode={readMode}
                                        highlightChange
                                    />
                                </Grid>
                                <Grid item xs={12}><Typography variant="body1" style={{ color: colors.textBody }}>Právny subjekt</Typography></Grid>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="businessName"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.name' })}
                                        mandatory={true}
                                        formikProps={formikProps}
                                        readMode={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikField
                                        type="autocomplete"
                                        name="legalForm"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.legalForm' })}
                                        mandatory={true}
                                        formikProps={formikProps}
                                        readMode={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="ico"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.ico' })}
                                        mandatory={true}
                                        formikProps={formikProps}
                                        readMode={readMode}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="dic"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.dic' })}
                                        readMode={readMode}
                                        type="number"
                                        formikProps={formikProps}
                                    />
                                </Grid>
                                {/*<Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="registrationNumber"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistration' })} *</>}
                                        formikProps={formikProps}
                                        readMode={readMode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="registrationDate"
                                        labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistrationDate' })} *</>}
                                        formikProps={formikProps}
                                        type="datePicker"
                                        readMode={readMode}
                                    />
                            </Grid>*/}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} >
                            <Divider style={{ backgroundColor: 'rgba(60,72,88)' }} />
                        </Grid>
                        <br />
                    </Grid>


                    <Box style={{ textAlign: "center" }}>

                        {(documentStatus === "Awaiting") &&
                            <Grid item xs={12}>
                                <Box style={{ padding: "10px", backgroundColor: colors.warning }} className={classes.readOnlyGrid}>
                                    <Typography variant="body2" style={{ color: 'black' }}>Dokument čaká na schválenie</Typography>
                                </Box>
                            </Grid>
                        }
                        {(documentStatus === "Rejected") &&
                            <Grid item xs={12}>
                                <Box style={{ padding: "10px", backgroundColor: colors.error }} className={classes.readOnlyGrid}>
                                    <Typography variant="body2" style={{ color: 'black' }}>Dokument je zamietnutý</Typography>
                                </Box>
                            </Grid>
                        }
                        {(documentStatus === "Approved") &&
                            <Grid item xs={12}>
                                <Box style={{ padding: "10px", backgroundColor: colors.success }} className={classes.readOnlyGrid}>
                                    <Typography variant="body2" style={{ color: 'black' }}>Dokument je schválený</Typography>
                                </Box>
                            </Grid>
                        }
                    </Box>


                    {(documentStatus === "Awaiting") &&
                        <Box style={{
                            textAlign: "center",
                            // position: "absolute",
                            bottom: "10px"
                        }}>
                            {(role === RoleEnum.ADMIN && !closed) &&
                                <>
                                    <Box style={{ textAlign: "center" }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            marginTop= '0.6rem'
                                            width= '11.5rem'
                                            round
                                            onClick={() => { setButtonStatus("Approve"); formikProps.handleSubmit("Approve"); }}
                                        >
                                            <CheckIcon /> {intl.formatMessage({ id: "documents.button.approve" })}
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            marginTop= '0.6rem'
                                            width= '11.5rem'
                                            round
                                            onClick={() => { setButtonStatus("Reject"); formikProps.handleSubmit("Reject"); onClose() }}
                                        >
                                            <BlockIcon /> {intl.formatMessage({ id: "documents.button.reject" })}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
                    }
                </>
            )}
        </Formik >
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompareClubDocumentsCard));
